import { get, isEmpty } from 'lodash'

export const formatCSVData = async ({
  fileName,
  filter = null,
  dateRanges,
  keys,
  titles,
  infos,
  columnsArr,
  rowsArr
}) => {
  if (isEmpty(keys)) return ['error']
  if (isEmpty(titles)) return ['error']
  if (isEmpty(columnsArr)) return ['error']
  if (isEmpty(rowsArr)) return ['error']

  const header = [
    ['# ----------------------------------------'],
    [fileName],
    filter === null ? null : ['# ----------------------------------------'],
    filter === null
      ? null
      : [`# ${get(filter, 'filterName')} (${get(filter, 'filterId')})`],
    ['# ----------------------------------------']
  ].concat(
    dateRanges !== null
      ? [
          [`# Start Date：${get(dateRanges, 'startAt', null)}`],
          [`# End Date：${get(dateRanges, 'endAt', null)}`],
          ['# ----------------------------------------']
        ]
      : []
  )

  const csvData = keys.reduce((csvData, key, index) => {
    const title = get(titles, `[${index}]`, null)
    const info = get(infos, `[${index}]`, null)
    const columns = get(columnsArr, `[${index}]`, null)
    const rows = get(rowsArr, `[${index}]`, null)

    if (title !== null) {
      csvData.push(title)
    }
    if (info !== null) {
      csvData.push(info)
    }
    if (columns !== null) {
      csvData.push(columns)
    }
    if (rows !== null) {
      for (let row of rows) {
        csvData.push(Object.values(row))
      }
      csvData.push([])
    }
    return csvData
  }, header)

  return csvData.filter(data => data !== null)
}

export const getCSVFileNameFromLocation = (route, num) => {
  const breadcrumbs = get(route, 'breadcrumbs', [])

  const cutArr = [...breadcrumbs].splice(num - 1)
  const title = cutArr.map(item => `${item.title}`).join('_')
  return `${title}_`
}

function transformToCsvColumnString(value) {
  const valueToString = `${value}`.replace(/"/gi, "'").replace(/\n\n/gi, ' ')
  return `"${valueToString}"`
}

export const toCSV = data => {
  if (!data) return '\n'
  return `${data
    .map(column => {
      return column.length > 0
        ? column.map(transformToCsvColumnString).join(',')
        : ''
    })
    .join('\n')}`
}

export const downloadCSV = (fileName, data) => {
  const prefix = 'data:text/csv;charset=utf-8,'
  const encodedUri = prefix + encodeURIComponent(data)
  const downloadLink = document.createElement('a')
  downloadLink.setAttribute('href', encodedUri)
  downloadLink.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
