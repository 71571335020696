import { NOT_REQUIRED_KEYS } from 'config/constansts/SmallFilter'

const CheckDataAllFilled = data => {
  let isFilled = false
  if (data.length <= 0) return isFilled
  const skip = ['id', 'key', 'is_default', ...NOT_REQUIRED_KEYS]

  return data.every(filter => {
    for (const item in filter) {
      const field = filter[item]
      if (skip.includes(item)) continue
      isFilled = Array.isArray(field) ? field.length > 0 : field !== ''
      if (!isFilled) break
    }
    return isFilled
  })
}

export default CheckDataAllFilled
