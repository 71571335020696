import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import CustomInput from 'styles/input'

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    margin: theme.spacing(1),
    alignItems: 'baseline',
    '& .MuiInputBase-input': {
      height: ({ height }) => height
    },
    '& .Mui-disabled': {
      borderColor: '#e0e0e0'
    }
  },
  unit: {
    fontSize: theme.typography.fontSize,
    marginLeft: 8
  }
}))

export const Input = ({
  value,
  setValue,
  error = false,
  setEmptyName = null,
  setExcessLimit = null,
  unit = null,
  width = 240,
  height = 40,
  placeholder = '',
  style,
  disabled
}) => {
  const classes = useStyles({ height })

  return (
    <FormControl className={classes.root} style={{ ...style }}>
      <CustomInput
        style={{
          width: width
        }}
        error={error}
        onChange={e => {
          const { value } = e.target
          setValue(value)
          if (setEmptyName && error) {
            setEmptyName(false)
          }
          if (setExcessLimit && error && value.trim().length <= 50) {
            setExcessLimit(false)
          }
        }}
        value={value}
        placeholder={placeholder}
        helpertext='Incorrect entry.'
        disabled={disabled}
      />
      {unit ? <span className={classes.unit}>{unit}</span> : null}
    </FormControl>
  )
}

export default Input
