const adStatus = {
  1: 'Active',
  2: 'Schedule',
  3: 'Pause',
  4: 'Terminated',
  5: 'Completed',
  6: 'Pending',
  7: 'Rejected'
}

export const codeToAdStatus = code => {
  let result = ''
  if (!code) return result
  if (!adStatus[code]) return result
  result = adStatus[code]
  return result
}

export default adStatus