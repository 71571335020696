import React, { createContext, useReducer, useEffect } from 'react'

import { DropbackReducer } from './Reducer'

export const DropbackContext = createContext()
DropbackContext.displayName = 'Dropback'

export const DropbackContextProvider = props => {
  useEffect(() => {
    return () => {}
  }, [])

  const [dropback, dispatchDropback] = useReducer(DropbackReducer, {
    open: false
  })

  return (
    <DropbackContext.Provider value={{ dropback, dispatchDropback }}>
      {props.children}
    </DropbackContext.Provider>
  )
}
