import MODAL_TYPE from '../ModalType'
import { generateRandomString } from 'helpers/General'
import { Dashboard } from 'types/chartEnum'

const overview_small_filter = MODAL_TYPE.OVERVIEW.SMALL_FILTER
const { Overview } = Dashboard

export const POTENTIAL_PAYING_USER_ITEMS = {
  recent_open_app_frequency: 'recent_open_app_frequency',
  recent_good_video_frequency: 'recent_good_video_frequency',
  good_videos_lifetime_days: 'good_videos_lifetime_days',
  profile_completion_rate: 'profile_completion_rate',
  follower_distribution: 'follower_distribution',
  follower_growth_rate: 'follower_growth_rate',
  engagement_rate_receive: 'engagement_rate_receive',
  engagement_rate_give: 'engagement_rate_give'
}

export const SMALL_FILTER_EMPTY_VALUE = {
  [overview_small_filter[Overview.RecentOpenAppFrequency]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.RecentGoodVideoCount]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.RecentGoodVideoFrequency]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: '',
    goodPostCount: ''
  },
  [overview_small_filter[Overview.FollowerGrowthRate]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.EngagementRateReceive]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: '',
    engagementType: []
  },
  [overview_small_filter[Overview.EngagementRateGive]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: '',
    engagementType: []
  },
  [overview_small_filter[Overview.ProfileCompletionRate]]: {
    key: generateRandomString(),
    is_default: false,
    operator: '',
    profileType1: [],
    other_operator: '',
    profileType2: [],
    profileCount: ''
  },
  [overview_small_filter[Overview.RetentionRateNewUser]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.RetentionRateNewCreator]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.RetentionRateNewOa]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: ''
  },
  [overview_small_filter[Overview.DailyActiveViewer]]: {
    key: generateRandomString(),
    is_default: false,
    dayCount: '',
    operator: ''
  }
}

export const SMALL_FILTER_DEFAULT_VALUE = {
  [overview_small_filter[Overview.RecentOpenAppFrequency]]: {
    is_default: false,
    dayCount: 7
  },
  [overview_small_filter[Overview.RecentGoodVideoCount]]: {
    is_default: false,
    dayCount: 45
  },
  [overview_small_filter[Overview.RecentGoodVideoFrequency]]: {
    is_default: false,
    dayCount: 45,
    goodPostCount: 5
  },
  [overview_small_filter[Overview.FollowerGrowthRate]]: {
    is_default: false,
    dayCount: 45
  },
  [overview_small_filter[Overview.EngagementRateReceive]]: {
    is_default: false,
    dayCount: 45,
    engagementType: [1, 2, 4, 8, 16]
  },
  [overview_small_filter[Overview.EngagementRateGive]]: {
    is_default: false,
    dayCount: 45,
    engagementType: [1, 2, 4, 8, 16]
  },
  [overview_small_filter[Overview.ProfileCompletionRate]]: {
    is_default: false,
    operator: '$in',
    profileType1: [1, 2, 4, 8, 16]
  },
  [overview_small_filter[Overview.RetentionRateNewUser]]: {
    is_default: false,
    dayCount: 45
  },
  [overview_small_filter[Overview.RetentionRateNewCreator]]: {
    is_default: false,
    dayCount: 45
  },
  [overview_small_filter[Overview.RetentionRateNewOa]]: {
    is_default: false,
    dayCount: 45
  },
  [overview_small_filter[Overview.DailyActiveViewer]]: {
    is_default: false,
    dayCount: 45,
    operator: '$gte'
  },
  [overview_small_filter[Overview.PotentialPayingUser]]: {
    key: generateRandomString(),
    [POTENTIAL_PAYING_USER_ITEMS.recent_open_app_frequency]: {
      is_default: true,
      dayCount: 7,
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        count1: {
          field: 'count1',
          operator: '$gte',
          param: 1
        },
        count2: {
          field: 'count2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.recent_good_video_frequency]: {
      is_default: true,
      dayCount: 45,
      goodPostCount: 5,
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        count1: {
          field: 'count1',
          operator: '$gte',
          param: 1
        },
        count2: {
          field: 'count2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.good_videos_lifetime_days]: {
      is_default: true,
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        count1: {
          field: 'count1',
          operator: '$gte',
          param: 1
        },
        count2: {
          field: 'count2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.profile_completion_rate]: {
      is_default: true,
      operator: '$eq',
      profileType1: [1, 2, 4],
      other_operator: '$in',
      profileType2: [8, 16],
      profileCount: ''
    },
    [POTENTIAL_PAYING_USER_ITEMS.follower_distribution]: {
      is_default: true,
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        count1: {
          field: 'count1',
          operator: '$gte',
          param: 1
        },
        count2: {
          field: 'count2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.follower_growth_rate]: {
      is_default: true,
      dayCount: 45,
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        percent1: {
          field: 'percent1',
          operator: '$gte',
          param: 1
        },
        percent2: {
          field: 'percent2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.engagement_rate_receive]: {
      is_default: true,
      dayCount: 45,
      engagementType: [1, 2, 4, 8, 16],
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        percent1: {
          field: 'percent1',
          operator: '$gte',
          param: 1
        },
        percent2: {
          field: 'percent2',
          operator: '$lte',
          param: 10
        }
      }
    },
    [POTENTIAL_PAYING_USER_ITEMS.engagement_rate_give]: {
      is_default: true,
      dayCount: 45,
      engagementType: [1, 2, 4, 8, 16],
      query_type: {
        value: 'userRange',
        userRange: {
          field: 'userRange',
          param: {
            max: 10,
            min: 0
          }
        },
        percent1: {
          field: 'percent1',
          operator: '$gte',
          param: 1
        },
        percent2: {
          field: 'percent2',
          operator: '$lte',
          param: 10
        }
      }
    }
  }
}

export const SMALL_FILTER_DEFAULT_CONTEXT_VALUE = [
  overview_small_filter[Overview.RecentOpenAppFrequency],
  overview_small_filter[Overview.RecentGoodVideoCount],
  overview_small_filter[Overview.RecentGoodVideoFrequency],
  overview_small_filter[Overview.FollowerGrowthRate],
  overview_small_filter[Overview.EngagementRateReceive],
  overview_small_filter[Overview.EngagementRateGive],
  overview_small_filter[Overview.ProfileCompletionRate],
  overview_small_filter[Overview.PotentialPayingUser],
  overview_small_filter[Overview.RetentionRateNewUser],
  overview_small_filter[Overview.RetentionRateNewCreator],
  overview_small_filter[Overview.RetentionRateNewOa],
  overview_small_filter[Overview.DailyActiveViewer]
].reduce((cur, key) => {
  return { ...cur, [key]: { id: '', reload: false } }
}, {})

export const PROFILE_COMPLETION_RATE_OPTIONS = [
  { label: 'Profile Picture', value: 4 },
  { label: 'UID', value: 2 },
  { label: 'Bio', value: 1 },
  { label: 'Contact Email', value: 8 },
  { label: 'Profile Setting Email', value: 16 }
]

export const ENGAGEMENT_RATE_OPTIONS = [
  { label: 'Like', value: 1 },
  { label: 'Like comment', value: 2 },
  { label: 'Comment', value: 4 },
  { label: 'Repost', value: 8 },
  { label: 'Follow', value: 16 }
]

export const CONDITION_OPTIONS = [
  {
    label: 'is one of',
    value: '$in',
    adjacentOperator: '$or'
  },
  {
    label: 'is not any of',
    value: '$ne',
    adjacentOperator: '$and'
  },
  { label: 'must be', value: '$eq', adjacentOperator: '$and' }
]

export const COMPARISION_OPERATORS = [
  { label: '<', value: '$lt' },
  { label: '<=', value: '$lte' },
  { label: '>', value: '$gt' },
  { label: '>=', value: '$gte' },
  { label: '=', value: '$eq' },
  { label: '<>', value: '$ne' }
]

export const QUERY_TYPES = [
  { label: 'User Range', value: 'userRange' },
  { label: 'Count', value: 'count1' },
  { label: 'Percent', value: 'percent1' }
]

export const EXCEPTIONS_OPERATORS = {
  dayCount: '$lte',
  profileCount: '$gte',
  goodPostCount: '$get'
}

export const EXCEPTIONS_KEYS = [
  'key',
  'id',
  'operator',
  'is_default',
  'other_operator',
  'graph_type_id',
  'query_type'
]

export const EXCEPTIONS_KEYS_OPERATORS = {
  operator: ['profileType1'],
  other_operator: ['profileType2']
}

export const POTENTIAL_PAYING_USER_FORMATTED_KEY = [
  'userRange',
  'count1',
  'count2',
  'percent1',
  'percent2'
]

export const POTENTIAL_PAYING_USER_KEYS_EMPTY_DEFAULT = {
  [POTENTIAL_PAYING_USER_ITEMS.recent_open_app_frequency]: 'count',
  [POTENTIAL_PAYING_USER_ITEMS.recent_good_video_frequency]: 'count',
  [POTENTIAL_PAYING_USER_ITEMS.good_videos_lifetime_days]: 'count',
  [POTENTIAL_PAYING_USER_ITEMS.follower_distribution]: 'count',
  [POTENTIAL_PAYING_USER_ITEMS.follower_growth_rate]: 'percent',
  [POTENTIAL_PAYING_USER_ITEMS.engagement_rate_receive]: 'percent',
  [POTENTIAL_PAYING_USER_ITEMS.engagement_rate_give]: 'percent'
}

export const POTENTIAL_PAYING_USER_EMPTY_FIELD = {
  count: {
    value: '',
    userRange: {
      field: 'userRange',
      param: {
        max: '',
        min: ''
      }
    },
    count1: {
      field: 'count1',
      operator: '',
      param: ''
    },
    count2: {
      field: 'count2',
      operator: '',
      param: ''
    }
  },
  percent: {
    value: '',
    userRange: {
      field: 'userRange',
      param: {
        max: '',
        min: ''
      }
    },
    percent1: {
      field: 'percent1',
      operator: '',
      param: ''
    },
    percent2: {
      field: 'percent2',
      operator: '',
      param: ''
    }
  }
}

export const NUMBER_FORMAT_KEYS = [
  'dayCount',
  'goodPostCount',
  'profileCount',
  'percent1',
  'percent2',
  'count1',
  'count2'
]

export const NOT_REQUIRED_KEYS = [
  'profileCount',
  'other_operator',
  'profileType2'
]
