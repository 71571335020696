import { Machine, assign } from 'xstate'
import _ from 'lodash'
import dayjs from 'dayjs'

import { BI_IMAGE_STORAGE_URL } from 'config/Server'

export const authMachine = Machine(
  {
    id: 'bi-auth',
    initial: 'idle',
    context: {
      isInitialized: false,
      isRefreshingToken: false,
      accessToken: null,
      member: null
    },
    states: {
      idle: {
        invoke: {
          id: 'retrieveLocalToken',
          src: 'retrieveLocalToken',
          onDone: {
            target: 'verifyToken',
            actions: ['setAccessToken', 'fetchingStart']
          },
          onError: {
            target: 'unloggedIn',
            actions: ['initialized']
          }
        }
      },
      verifyToken: {
        always: [
          {
            target: 'unloggedIn',
            cond: 'noAccessToken',
            actions: ['logout', 'fetchingStop']
          }
        ],
        invoke: {
          id: 'verifyToken',
          src: 'verifyToken',
          onDone: {
            target: 'loggedIn',
            actions: [
              'setAccessToken',
              'setMember',
              'initialized',
              'fetchingStop'
            ]
          },
          onError: {
            target: 'unloggedIn',
            actions: ['logout', 'initialized', 'fetchingStop']
          }
        }
      },
      loggingIn: {
        invoke: {
          id: 'login',
          src: 'login',
          onDone: {
            target: 'loggedIn',
            actions: ['setAccessToken', 'setMember', 'fetchingStop']
          },
          onError: {
            target: 'unloggedIn',
            actions: ['logout', 'fetchingStop']
          }
        }
      },
      loggedIn: {
        always: [
          {
            target: 'loggingOut',
            cond: 'shouldLogout',
            actions: ['logout']
          }
        ],
        on: {
          LOGOUT: {
            target: 'loggingOut',
            actions: ['fetchingStart']
          }
        }
      },
      loggingOut: {
        invoke: {
          id: 'logout',
          src: 'logout',
          onDone: { target: 'unloggedIn', actions: ['logout', 'fetchingStop'] },
          onError: { target: 'loggedIn', actions: ['fetchingStop'] }
        }
      },
      unloggedIn: {
        on: {
          LOGIN: {
            target: 'loggingIn',
            actions: ['fetchingStart']
          }
        }
      }
    }
  },
  {
    guards: {
      noAccessToken: (context, event) =>
        _.get(context, 'accessToken', null) === null,
      shouldLogout: (context, event) =>
        _.get(context, 'accessToken', null) === null ||
        _.get(context, 'member', null) === null
    },
    actions: {
      initialized: assign({
        isInitialized: true
      }),
      fetchingStart: assign({
        isRefreshingToken: true
      }),
      fetchingStop: assign({
        isRefreshingToken: false
      }),
      logout: assign({
        accessToken: (context, event) => {
          localStorage.removeItem('Playsee_Internal_Auth')
          return null
        },
        member: null
      }),
      setAccessToken: assign({
        accessToken: (context, event) => {
          const accessToken = _.get(event, 'data.access_token', null)
          if (accessToken !== null) {
            localStorage.setItem(
              'Playsee_Internal_Auth',
              JSON.stringify({ access_token: accessToken })
            )
          } else {
            localStorage.removeItem('Playsee_Internal_Auth')
          }

          return accessToken
        }
      }),
      setMember: assign({
        member: (context, event) => {
          const member = _.get(event, 'data.member', null)
          const photo = _.get(member, 'account.photo', '')
          const user_id = _.get(member, 'account.id', null)

          if (member !== null) {
            return {
              name: _.get(member, 'account.name', null),
              profile_picture:
                photo === ''
                  ? null
                  : `${BI_IMAGE_STORAGE_URL}member/${user_id}/photo.jpg?${dayjs(
                      photo
                    ).valueOf()}`,
              uid: _.get(member, 'account.account', null),
              user_id,
              permissions: _.get(member, 'permissions', []),
              department: _.get(member, 'department', []),
              position: _.get(member, 'position', [])
            }
          }
          return member
        }
      })
    }
  }
)
