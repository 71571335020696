import axios, { AxiosRequestConfig } from 'axios'
import type { Subscription } from 'rxjs'
import {
  authTokenService,
  tokenRenewService,
  AuthTokenAction,
  TokenRenewAction
} from 'services/Auth'

import { generateRandomString } from 'helpers/General'
// import { PRIVATE_HOST_ENDPOINT } from 'config/Server'

export { BASE_URL } from 'config/Server'

export enum ApiErrorCode {
  InvalidArgument = 1,
  FilterDoesNotBelongToThisUser = 2,
  FilterDoesNotBelongToThisType = 3,
  UpdateDatabaseFail = 4,
  InvalidRequest = 5,
  InsertDatabaseFail = 6,
  NotEnoughFilters = 7,
  ApiError = 100
}

type ListenerType = {
  [key: string]: Subscription
}

const listeners: ListenerType = {}

export const renewToken = (id: string, currentAuth: string | null) =>
  new Promise<boolean>(resolved => {
    const setTimeoutId = setTimeout(() => {
      if (listeners.hasOwnProperty(id)) {
        listeners[id].unsubscribe()
      }
      resolved(false)
    }, 10000)
    listeners[id] = tokenRenewService.listener().subscribe(event => {
      const action = event.action ?? null
      if (action === TokenRenewAction.RECEIVED) {
        if (listeners.hasOwnProperty(id)) {
          listeners[id].unsubscribe()
        }
        clearTimeout(setTimeoutId)
        resolved(true)
      }
    })

    tokenRenewService.renewToken(currentAuth)
  })

export const getAccessToken = () =>
  new Promise<string | null>(resolved => {
    const id = generateRandomString()
    listeners[id] = authTokenService.listener().subscribe(event => {
      const action = event.action ?? null
      if (action === AuthTokenAction.GIVE) {
        if (listeners.hasOwnProperty(id)) {
          listeners[id].unsubscribe()
        }
        const access_token = event.access_token ?? null
        resolved(access_token)
      }
    })
    setTimeout(() => {
      if (listeners.hasOwnProperty(id)) {
        listeners[id].unsubscribe()
      }
      resolved(null)
    }, 200)

    authTokenService.getAuthToken()
  })

export interface ApiResponse<T = any> {
  status: number
  data?: T
  headers?: any
}

export interface ApiArgType {
  params?: any
  data?: any
  path?: any
}

export type ApiType<Request extends ApiArgType = ApiArgType, Response = any> = (
  arg: Request
) => Promise<ApiResponse<Response>>

export type ApiResponseData<T extends ApiType<any>> = T extends ApiType<
  any,
  infer U
>
  ? U
  : T

const request = async (
  method: string,
  url: string,
  options: AxiosRequestConfig
): Promise<ApiResponse> => {
  const currentAuth: string | null = options?.headers?.Authorization ?? null
  return axios({
    method,
    url,
    ...{
      ...options
      // headers: {
      //   'X-Host': PRIVATE_HOST_ENDPOINT,
      //   ...options.headers
      // }
    }
  } as AxiosRequestConfig)
    .then(response => ({
      status: response.status,
      data: response.data,
      headers: response.headers
    }))
    .catch(async error => {
      if (error?.response?.status === 401) {
        await renewToken(generateRandomString(), currentAuth)
      } else if (!error.response) {
        return {
          status: 500
        }
      }
      return {
        status: error.response.status
      }
    })
}

export default request
