import React, { Fragment, useContext } from 'react'

import { AuthContext } from 'contexts/Auth'

import Login from 'components/pages/Login'
import { DropbackTrigger } from 'components/layouts/Dropback'

const AuthWrapper = ({ children }) => {
  const { auth } = useContext(AuthContext)

  if (!auth.isInitialized) {
    return <DropbackTrigger />
  }

  if (auth.isLoggedIn) {
    return (
      <Fragment>
        {children}
        {auth.isRefreshingToken && <DropbackTrigger />}
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Login />
      {auth.isRefreshingToken && <DropbackTrigger />}
    </Fragment>
  )
}

export default AuthWrapper
