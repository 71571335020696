import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import CustomInput from 'styles/input'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    margin: theme.spacing(1),
    alignItems: 'baseline',
    '& .Mui-disabled': {
      borderColor: '#e0e0e0'
    }
  },
  input: {
    width: props => `${props.width}px`
  }
}))

export const Input = ({
  value,
  setValue,
  width = 100,
  disabled = false,
  typeLimit = null,
  optionalOnBlur = null,
  name = '',
  inputProps = {}
}) => {
  const classes = useStyles({ width })
  const [inputValue, setInputValue] = useState(value)

  const handleOnChange = e => {
    const { value: currValue } = e.target

    if (typeLimit === 'number') {
      const newValue = currValue.replace(/\.|-/g, '').trim()
      //make sure only set number into inputValue
      if (!isNaN(newValue)) {
        setInputValue(Number(newValue))
      }
    } else {
      setInputValue(currValue)
    }
  }

  const handleOnBlur = () => {
    if (typeLimit === 'number' && inputValue === '') {
      setInputValue(Number(0))
      setValue(Number(0))
      return
    }
    if (optionalOnBlur !== null) optionalOnBlur(name, inputValue, setInputValue)
    else setValue(inputValue)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormControl className={classes.root}>
      <CustomInput
        className={classes.input}
        onChange={handleOnChange}
        value={inputValue}
        onBlur={handleOnBlur}
        disabled={disabled}
        inputProps={{
          'data-testid': inputProps['data-testid']
        }}
        aria-label={inputProps['data-testid']}
      />
    </FormControl>
  )
}

export default Input

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  typeLimit: PropTypes.oneOf(['number', null]),
  optionalOnBlur: PropTypes.func,
  name: PropTypes.string,
  inputProps: PropTypes.object
}

Input.defaultProps = {
  value: 0,
  setValue: () => {},
  width: 100,
  disabled: false,
  typeLimit: null,
  optionalOnBlur: null,
  name: '',
  inputProps: {
    'data-testid': 'typeIn'
  }
}
