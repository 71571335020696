import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  memo
} from 'react'
import cx from 'clsx'
import Button from 'components/blocks/Button'
import Input from 'components/blocks/TextInput'
import Dropdown from 'components/blocks/Dropdown'
import DropdownSelector from 'components/blocks/DropdownSelector'
import AddDeleteButtonGroup from 'components/blocks/AddDeleteButtonGroup'
import SmallFilter from '../SmallFilter'
import useSmallFilterAction from '../../hooks/useSmallFilterAction'
import CheckDataAllFilled from '../../helper/checkDataAllFilled'
import MODAL_TYPE from 'config/constansts/ModalType'
import {
  PROFILE_COMPLETION_RATE_OPTIONS,
  CONDITION_OPTIONS
} from 'config/constansts'
import { Dashboard } from 'types/chartEnum'
import _ from 'lodash'

const { Overview } = Dashboard
const type = MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.ProfileCompletionRate]

const Filter = memo(
  ({ classes, state, setValue, setDefault, disabled }) => {
    const [openOptionalOptions, setOpenOptionalOptions] = useState(false)

    const triggerOptionalOptions = useCallback(() => {
      if (openOptionalOptions) {
        setValue(state.key, 'other_operator', '')
        setValue(state.key, 'profileType2', [])
        setValue(state.key, 'profileCount', '')
      }
      setOpenOptionalOptions(value => !value)
    }, [openOptionalOptions, setValue, setOpenOptionalOptions, state.key])

    useEffect(() => {
      if (
        state.other_operator ||
        state.profileType2.length > 0 ||
        state.profileCount
      ) {
        setOpenOptionalOptions(true)
      } else {
        setOpenOptionalOptions(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.key])

    return (
      <div className={classes.filter}>
        <span
          className={cx(
            'radioButton',
            state.is_default && 'selected',
            disabled && 'disabled'
          )}
          onClick={() => !disabled && setDefault(state.key)}
        >
          <span></span>
        </span>
        <div className='row-content'>
          <Dropdown
            key={`${state.key}-1`}
            options={CONDITION_OPTIONS}
            value={state.operator}
            setValue={value => setValue(state.key, 'operator', value)}
            width={150}
            disabled={disabled}
          />
          <DropdownSelector
            style={{
              display: 'inline-block',
              margin: '0px 8px',
              width: 200
            }}
            value={state.profileType1}
            defaultValue={[PROFILE_COMPLETION_RATE_OPTIONS[0]]}
            options={PROFILE_COMPLETION_RATE_OPTIONS}
            onChange={newValue => setValue(state.key, 'profileType1', newValue)}
            disabled={disabled}
            allowSelectAll={true}
          />
          <Button
            key='button_and'
            variant='outlined'
            color='primary'
            label='and'
            onClick={triggerOptionalOptions}
            disabled={disabled}
          />
          {openOptionalOptions && (
            <>
              <Dropdown
                key={`${state.key}-2`}
                options={CONDITION_OPTIONS}
                value={state.other_operator}
                setValue={value => setValue(state.key, 'other_operator', value)}
                width={150}
                disabled={disabled}
              />
              <DropdownSelector
                style={{
                  display: 'inline-block',
                  margin: '0px 8px',
                  width: 200
                }}
                value={state.profileType2}
                defaultValue={[PROFILE_COMPLETION_RATE_OPTIONS[0]]}
                options={PROFILE_COMPLETION_RATE_OPTIONS}
                onChange={newValue =>
                  setValue(state.key, 'profileType2', newValue)
                }
                disabled={disabled}
                allowSelectAll={true}
              />
              <span className={classes.text}>at least</span>
              <Input
                value={state.profileCount}
                setValue={value => setValue(state.key, 'profileCount', value)}
                width={57}
                style={{ margin: '0 8px' }}
                disabled={disabled}
              />
            </>
          )}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

const ProfileCompletionRateSmallFilter = forwardRef((props, ref) => {
  const {
    classes,
    emptyValue,
    defaultValue,
    setAllFilled,
    setLoading,
    fetchedData,
    disabled
  } = props

  const [states, setStates] = useState([
    { ...emptyValue, ...defaultValue, is_default: true }
  ])
  const {
    setValue,
    setDefault,
    handleAdd,
    handleDelete
  } = useSmallFilterAction({ setStates, emptyValue, setLoading, type })

  useEffect(() => setAllFilled(CheckDataAllFilled(states)), [
    setAllFilled,
    states
  ])

  useEffect(() => {
    if (fetchedData.length > 0) setStates(fetchedData)
  }, [fetchedData])

  useImperativeHandle(
    dom => (ref.current[type] = dom),
    () => states,
    [states]
  )

  return (
    <>
      <div className={classes.category}>Potential Paying User</div>
      <div className={classes.title}>Profile Completion Rate</div>
      <div className={classes.filters}>
        {states.length > 0 &&
          states.map((state, index) => (
            <div className={classes.row} key={state.key}>
              <Filter
                {...{
                  classes,
                  state,
                  setValue,
                  setDefault,
                  length: states.length,
                  disabled
                }}
              />
              <AddDeleteButtonGroup
                {...{
                  size: 'small',
                  index,
                  totalLength: states.length,
                  classes: classes.buttonGroup,
                  addCount: 5,
                  handleAdd,
                  handleDelete,
                  target: state.key,
                  id: state.id,
                  disabled
                }}
              />
            </div>
          ))}
      </div>
    </>
  )
})

const ProfileCompletionRate = (props, ref) => {
  const width = 1050
  const height = 500

  return (
    <SmallFilter {...{ ...props, width, height }}>
      <ProfileCompletionRateSmallFilter {...ref} />
    </SmallFilter>
  )
}

export default forwardRef(ProfileCompletionRate)
