import { useCallback } from 'react'
import _ from 'lodash'
import { generateRandomString } from 'helpers/General'
import { postSmallFilterDeleteApi } from 'apis/smallFilter'
import { checkInputNumber } from '../../helper/potentialPayingUserAction'

const useSmallFilterAction = ({ setStates, emptyValue, setLoading }) => {
  const setValue = useCallback(
    (key, name, value) => {
      setStates(prev =>
        prev.map(state => ({
          ...state,
          [name]:
            state.key === key
              ? checkInputNumber(name, value, state[name])
              : state[name]
        }))
      )
    },
    [setStates]
  )

  const setDefault = useCallback(
    key => {
      setStates(prev =>
        prev.map(state => ({
          ...state,
          is_default: state.key === key
        }))
      )
    },
    [setStates]
  )

  const handleAdd = useCallback(() => {
    setLoading(true)
    setStates(states => [
      ...states,
      {
        ..._.cloneDeep(emptyValue),
        key: generateRandomString()
      }
    ])
    setLoading(false)
  }, [emptyValue, setLoading, setStates])

  const triggerRemove = useCallback(
    (key, id) => {
      setStates(states => {
        const filtered = states.filter(
          item => item[id ? 'id' : 'key'] !== (id ?? key)
        )
        const hasDefault = _.find(filtered, { is_default: true })
        if (!hasDefault && filtered.length > 0) filtered[0].is_default = true
        return filtered
      })
      setLoading(false)
    },
    [setLoading, setStates]
  )

  const handleDelete = useCallback(
    (key, id) => {
      setLoading(true)

      if (id) {
        postSmallFilterDeleteApi({
          data: { small_filter_id: id }
        }).then(({ data }) => {
          if (data?.success) {
            triggerRemove(key, id)
          }
        })
      } else {
        triggerRemove(key, id)
      }
    },
    [triggerRemove, setLoading]
  )

  return {
    setValue,
    setDefault,
    handleAdd,
    handleDelete
  }
}

export default useSmallFilterAction
