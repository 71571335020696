import React from 'react'
import allRoutes from 'config/Routes'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import bizTheme from 'projects/BizReview/theme'
import globalTheme from 'components/App/theme/customThemeConfig'
import { PATH_BIZ_REVIEW } from 'constant'

function compareRouteToGetTheme(route) {
  switch (route?.key) {
    case PATH_BIZ_REVIEW:
      return bizTheme
    default:
      return globalTheme
  }
}

const ThemeWrapper = ({ children }) => {
  const location = useLocation()
  const currentRoute = allRoutes.find(route => route.path === location.pathname)
  const theme = compareRouteToGetTheme(currentRoute)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeWrapper
