export const DropbackReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_DROPBACK':
      return {
        open: true
      }
    case 'CLOSE_DROPBACK':
      return {
        open: false
      }
    default:
      return state
  }
}
