import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Progress from 'components/blocks/Progress'
import Input from '../Input'
import PropTypes from 'prop-types'

const useStyle = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  inputWrapper: {
    '&.loading': {
      pointerEvents: 'none'
    }
  },
  progress: {
    position: 'absolute',
    right: 0
  }
}))

const BetweenConditionInput = ({
  statement = [],
  firstInputData,
  secondInputData,
  loading = false,
  width = 100,
  typeLimit = null,
  testID = [],
  disabled
}) => {
  const classes = useStyle()
  const {
    value: firstValue,
    setValue: setFirstValue,
    min: firstValueMin,
    max: firstValueMax
  } = firstInputData
  const {
    value: secondValue,
    setValue: setSecondValue,
    min: secondValueMin,
    max: secondValueMax
  } = secondInputData

  const inputNumberMaxMinCheck = ({
    tmpFirstValue,
    tmpSecondValue,
    inputValue,
    name
  }) => {
    const min = name === 'first' ? firstValueMin : secondValueMin

    const max = name === 'first' ? firstValueMax : secondValueMax

    if (inputValue < min) {
      return {
        firstValue: name === 'first' ? min : tmpFirstValue,
        secondValue: name === 'second' ? min : tmpSecondValue
      }
    } else if (inputValue > max) {
      return {
        firstValue: name === 'first' ? max : tmpFirstValue,
        secondValue: name === 'second' ? max : tmpSecondValue
      }
    } else {
      return {
        firstValue: tmpFirstValue,
        secondValue: tmpSecondValue
      }
    }
  }

  const handleOptionalOnBlur = (name, inputValue, setInputValue) => {
    let tmpFirstValue = name === 'first' ? inputValue : firstValue
    let tmpSecondValue = name === 'second' ? inputValue : secondValue

    const newInputValue = inputNumberMaxMinCheck({
      tmpFirstValue,
      tmpSecondValue,
      inputValue,
      name
    })
    tmpFirstValue = newInputValue.firstValue
    tmpSecondValue = newInputValue.secondValue

    if (Number(tmpFirstValue) > Number(tmpSecondValue)) {
      //swap
      setFirstValue(Number(tmpSecondValue))
      setSecondValue(Number(tmpFirstValue))
      const forceUpdateInputValue =
        tmpFirstValue === secondValue || tmpSecondValue === firstValue
      if (forceUpdateInputValue) {
        const sameValue =
          tmpFirstValue === secondValue ? tmpFirstValue : tmpSecondValue

        setInputValue(sameValue)
      }
    } else {
      setFirstValue(Number(tmpFirstValue))
      setSecondValue(Number(tmpSecondValue))
    }
  }

  return (
    <div className={classes.container}>
      <div>{statement[0] ?? ''}</div>
      <div className={classes.inputWrapper}>
        <Input
          value={firstValue}
          setValue={setFirstValue}
          width={width}
          disabled={loading || disabled}
          optionalOnBlur={handleOptionalOnBlur}
          typeLimit='number'
          name='first'
          inputProps={{
            'data-testid': testID[0]
          }}
        />
      </div>
      <div>{statement[1] ?? ''}</div>
      <div className={classes.inputWrapper}>
        <Input
          value={secondValue}
          setValue={setSecondValue}
          width={width}
          disabled={loading || disabled}
          optionalOnBlur={handleOptionalOnBlur}
          typeLimit='number'
          name='second'
          inputProps={{
            'data-testid': testID[1]
          }}
        />
      </div>
      <div>{statement[2] ?? ''}</div>
      {loading && (
        <div className={classes.progress}>
          <Progress />
        </div>
      )}
    </div>
  )
}
export default BetweenConditionInput

BetweenConditionInput.propTypes = {
  statement: PropTypes.array,
  firstInputData: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setValue: PropTypes.func.isRequired,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  secondInputData: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setValue: PropTypes.func.isRequired,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  loading: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeLimit: PropTypes.oneOf(['number', null]),
  testID: PropTypes.array,
  disabled: PropTypes.bool
}

BetweenConditionInput.defaultProps = {
  statement: [],
  firstInputData: {
    value: 0,
    setValue: () => {},
    min: 0,
    max: 100
  },
  secondInputData: {
    value: 0,
    setValue: () => {},
    min: 0,
    max: 100
  },
  loading: false,
  width: 100,
  typeLimit: null,
  testID: [0, 1],
  disabled: false
}
