import React from 'react'

import { StatisticsIcon, PersonIcon, PeopleIcon } from 'helpers/Icons'

export const OverviewIcon = StatisticsIcon
export { VideoIcon, AdsIcon, BizIcon } from 'helpers/Icons'
export const CreatorIcon = PersonIcon
export const AudienceIcon = PeopleIcon

export const IdeaLinkedIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <g id='_24px' data-name='24px'>
        <rect width='24' height='24' fill='none' />
        <path
          d='M13.61,4.76a3.67,3.67,0,0,1,5.26,0,3.86,3.86,0,0,1,0,5.37l-3.39,3.45a3.66,3.66,0,0,1-1.72,1h0l-.15,0-.09,0-.18,0H13l-.16,0h-.2a2.71,2.71,0,0,1-.4,0c-.11,0-.21,0-.32-.06l-.15-.05-.17,0-.17-.07-.17-.07a3.67,3.67,0,0,1-1.06-.76,1.09,1.09,0,0,1-.31-.84,1,1,0,0,1,.31-.69,1.08,1.08,0,0,1,1.49,0,1.58,1.58,0,0,0,2.25,0l.87-.88,0,0,2.49-2.54a1.64,1.64,0,0,0,0-2.3,1.56,1.56,0,0,0-2.25,0l-2,2a.28.28,0,0,1-.29.06,4.34,4.34,0,0,0-1.7-.34H11a.3.3,0,0,1-.25-.17.28.28,0,0,1,.06-.3ZM11.05,8.61a3.61,3.61,0,0,1,2,.6,3,3,0,0,1,.62.51,1.07,1.07,0,0,1,.31.83,1.1,1.1,0,0,1-.31.69,1.06,1.06,0,0,1-1.49,0,1.58,1.58,0,0,0-2.25,0L6.54,14.7a1.67,1.67,0,0,0,0,2.31A1.59,1.59,0,0,0,8.8,17l2-2.05a.24.24,0,0,1,.29-.06,4.48,4.48,0,0,0,1.7.33h.1a.27.27,0,0,1,.27.27.32.32,0,0,1-.08.2L10.3,18.54a3.69,3.69,0,0,1-2.63,1.11A3.76,3.76,0,0,1,4,15.85,3.91,3.91,0,0,1,5,13.17L8.42,9.72A3.62,3.62,0,0,1,11.05,8.61Z'
          fill='#9e9e9e'
        />
      </g>
    </svg>
  )
}

export const IdeaReconsiderIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <g id='_24px' data-name='24px'>
        <rect width='24' height='24' fill='none' />
        <path
          d='M21,13.08a6.42,6.42,0,0,0-6.44-5.65H9.75V4.57a.55.55,0,0,0-.32-.51.53.53,0,0,0-.59.07L3.21,8.7a.58.58,0,0,0,0,.89l5.63,4.57a.56.56,0,0,0,.35.13.53.53,0,0,0,.24-.06.58.58,0,0,0,.32-.52V10.86h4.9a2.94,2.94,0,0,1,3,2.56,2.84,2.84,0,0,1-2.8,3.15h-4.5a.57.57,0,0,0-.56.57v2.29a.57.57,0,0,0,.56.57h4.5A6.26,6.26,0,0,0,21,13.08Z'
          fill='#9e9e9e'
        />
      </g>
    </svg>
  )
}
