import React from 'react'
import cx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStylesProgress = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-flex',
    '&.small': {},
    '&.large': {},
    '&:not(.small):not(.large)': {
      margin: '0 11px'
    }
  },
  top: {
    color: 'transparent'
  },
  bottom: {
    color: '#6a9eff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  contained: {
    color: '#fff'
  },
  outlined: {
    color: '#6a9eff'
  }
})

export default props => {
  const classes = useStylesProgress()
  return (
    <div className={classes.root}>
      <CircularProgress
        variant='determinate'
        value={100}
        className={classes.top}
        size={18}
        thickness={6}
        {...props}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        className={cx(classes.bottom, classes[props.colortype])}
        size={18}
        thickness={6}
        {...props}
      />
    </div>
  )
}
