import { Subject } from 'rxjs'

const subject = new Subject()

export const contentScrollService = {
  onScroll: (x = 0, y = 0) =>
    subject.next({
      action: 'onScroll',
      x,
      y,
    }),
  jumpTo: (x = 0) =>
    subject.next({
      action: 'scrollTo',
      value: x,
      isAnimated: false,
    }),
  scrollTo: (x = 0) =>
    subject.next({
      action: 'scrollTo',
      value: x,
      isAnimated: true,
    }),
  listener: () => subject.asObservable(),
}
