import React, { createContext, useEffect, useRef, useCallback } from 'react'
import { from } from 'rxjs'
import _ from 'lodash'
import { useMachine } from '@xstate/react'

import { authMachine } from './machine'
import { authTokenService, tokenRenewService } from 'services/Auth'
import {
  postAccountsLoginApi,
  getAccountsVerifyTokenApi,
  //postAccountsRefreshTokenApi,
  postAccountsLogoutApi
} from 'apis/auth'

import { useComponentWillUnmount } from 'helpers/HookLifeCycle'

export const AuthContext = createContext()
AuthContext.displayName = 'Auth'

const formatCheck = data => {
  return _.get(data, 'access_token', null) !== null
}

export const AuthContextProvider = props => {
  const authTokenSubscription = useRef(null)
  const tokenRenewSubscription = useRef(null)
  const apiFetcher = useRef()

  const [{ value, context }, send] = useMachine(authMachine, {
    services: {
      retrieveLocalToken: (context, event) => {
        return new Promise((resolve, reject) => {
          const localData = localStorage.getItem('Playsee_Internal_Auth')
          const json = JSON.parse(localData)
          if (localData && formatCheck(json)) {
            resolve(json)
          } else {
            reject()
          }
        })
      },
      verifyToken: (context, event) => {
        return new Promise((resolve, reject) => {
          const { accessToken } = context
          apiFetcher.current = from(
            getAccountsVerifyTokenApi({
              params: { access_token: accessToken }
            })
          ).subscribe(response => {
            const status = _.get(response, 'status', 401)
            if (status !== 200) {
              reject({})
            }

            const data = _.get(response, 'data', {})
            if (data.hasOwnProperty('error')) {
              reject({})
            } else {
              resolve(data)
            }
          })
        })
      },
      login: (context, event) => {
        const options = _.get(event, 'options', null)
        const success = _.get(event, 'success', () => {})
        const fail = _.get(event, 'fail', () => {})

        return new Promise((resolve, reject) => {
          fetch.current = from(postAccountsLoginApi(options)).subscribe(
            ({ data = {} }) => {
              if (data.hasOwnProperty('error')) {
                if (typeof fail === 'function') {
                  fail(data)
                }
                reject()
              } else {
                if (typeof success === 'function') {
                  success()
                }
                resolve(data)
              }
            }
          )
        })
      },
      logout: (context, event) => {
        return new Promise((resolve, reject) => {
          fetch.current = from(postAccountsLogoutApi()).subscribe(response => {
            const status = _.get(response, 'status', 401)
            if (status !== 200) {
              reject({})
            }

            const data = _.get(response, 'data', {})
            if (data.hasOwnProperty('error')) {
              reject({})
            }
            resolve({})
          })
        })
      }
    }
  })

  const { isInitialized, isRefreshingToken, accessToken, member } = context
  const status =
    typeof value === 'string' ? value : _.get(Object.keys(value), '[0]', null)
  // console.log('authMachine: ', status, context)

  const doRefreshAuth = useCallback(async () => {
    if (!isRefreshingToken) {
      send('LOGOUT')
    }
  }, [isRefreshingToken, send])

  const doLogin = useCallback(
    (options, success, fail) => {
      if (!isRefreshingToken) {
        send('LOGIN', { options, success, fail })
      }
    },
    [isRefreshingToken, send]
  )

  const doLogout = useCallback(() => {
    if (!isRefreshingToken) {
      send('LOGOUT')
    }
  }, [isRefreshingToken, send])

  useEffect(() => {
    tokenRenewSubscription.current = tokenRenewService
      .listener()
      .subscribe(async event => {
        if (isRefreshingToken) return
        if (event.action !== 'auth_token_renew') return
        if (event.expired_token === `Bearer ${accessToken}`) {
          await doRefreshAuth()
        }
        tokenRenewService.newTokenReceived()
      })
    return () => {
      if (tokenRenewSubscription.current) {
        tokenRenewSubscription.current.unsubscribe()
      }
    }
  }, [tokenRenewSubscription, isRefreshingToken, accessToken, doRefreshAuth])

  useEffect(() => {
    authTokenSubscription.current = authTokenService
      .listener()
      .subscribe(event => {
        if (event.action === 'auth_token_get') {
          authTokenService.giveAuthToken(accessToken)
        }
      })
    return () => {
      if (authTokenSubscription.current) {
        authTokenSubscription.current.unsubscribe()
      }
    }
  }, [authTokenSubscription, accessToken])

  useComponentWillUnmount(() => {
    if (apiFetcher.current) {
      apiFetcher.current.unsubscribe()
    }
  })

  return (
    <AuthContext.Provider
      value={{
        auth: {
          status: useCallback(() => {
            return status
          }, [status])(),
          isLoggedIn: useCallback(() => {
            return status.toLowerCase() === 'loggedin'
          }, [status])(),
          isRefreshingToken: useCallback(() => {
            return isRefreshingToken
          }, [isRefreshingToken])(),
          isInitialized: useCallback(() => {
            return isInitialized
          }, [isInitialized])(),
          member,
          Logout: doLogout,
          Login: doLogin,
          RefreshAuth: doRefreshAuth
        }
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
