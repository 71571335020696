import React from 'react'
import _ from 'lodash'
import tools from './Tools'
import control from './Control'
import analysis from './Analysis'


import {
  ToolsIcon,
  ApplicationFormIcon,
  ScheduleIcon
} from 'config/Routes/Icons'
import { RouteType } from 'types/route'

const ToolsTab = React.lazy(() => import(`components/pages/Tools`))

const ControlTab = React.lazy(() => import(`components/pages/Control`))
const AnalysisTab = React.lazy(() => import(`components/pages/Analysis`))



const makeRoutes = (routes: RouteType[]) => {
  const f = (root: RouteType | null, rs: RouteType[], Rs: RouteType[]) => {

    return rs.reduce((Routes: RouteType[], route: RouteType) => {
      const r = {
        ...route
      }

      const logicPath = `${root === null ? '' : _.get(root, `logicPath`, root.path)
        }${_.get(r, `logicPath`, r.path)}`

      r.path = root === null ? r.path : `${root.path}${r.path}`
      r.logicPath = logicPath
      r.component = r.component ?? root?.component

      if (root !== null) {
        if (root.hasOwnProperty('permissions')) {
          if (r.hasOwnProperty('permissions')) {
            r.permissions = root.permissions?.concat(
              `${root.permissions[root.permissions.length - 1]}.${r.permissions?.[r.permissions.length - 1]
              }`
            )
          } else {
            r.permissions = root.permissions
          }
        } else {
          r.permissions = []
        }
      }

      r.breadcrumbs = root === null ? [r] : root.breadcrumbs?.concat([r])
      Routes.push(r)
      const subRoutes = _.get(r, 'subRoutes', null)
      if (subRoutes !== null) {
        Routes = f(r, subRoutes, Routes)
      }
      return Routes
    }, Rs)
  }
  return f(null, routes, [])
}



export const tabs = [
  {
    path: '/tools',
    title: 'Tools',
    permissions: ['tools'],
    icon: ToolsIcon,
    component: ToolsTab,
    exact: true,
    subRoutes: tools
  },

  {
    path: '/control',
    title: 'Control',
    permissions: ['control'],
    icon: ScheduleIcon,
    component: ControlTab,
    exact: true,
    subRoutes: control
  },
  {
    path: '/analysis',
    title: 'Analysis',
    permissions: ['analysis'],
    icon: ApplicationFormIcon,
    component: AnalysisTab,
    exact: true,
    subRoutes: analysis
  }
]

export default makeRoutes(tabs)
