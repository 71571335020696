import { RouteType } from 'types/route'
import React from 'react'
import {
    ToolsIcon,
} from 'config/Routes/Icons'

const AssignVideo = React.lazy(
    () => import('projects/Control/components/pages/AssignVideo/index')
)

const AssignCommunity = React.lazy(
    () => import('projects/Control/components/pages/AssignCommunity/index')
)

const AssignProfile = React.lazy(
    () => import('projects/Control/components/pages/AssignProfile/index')
)


const control: RouteType[] = [
    {
        key: 'assign-video',
        path: '/assign-video',
        title: 'Assign Video',
        permissions: ['assign-video'],
        icon: ToolsIcon,
        component: AssignVideo,
        exact: true,
        subRoutes: []
    },

    {
        key: 'assign-community',
        path: '/assign-community',
        title: 'Assign Community',
        permissions: ['assign-community'],
        icon: ToolsIcon,
        component: AssignCommunity,
        exact: true,
        subRoutes: []
    },
    {
        key: 'assign-profile',
        path: '/assign-profile',
        title: 'Assign Profile',
        permissions: ['assign-profile'],
        icon: ToolsIcon,
        component: AssignProfile,
        exact: true,
        subRoutes: []
    },



]


export default control