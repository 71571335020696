const adType = {
  '1': 'Promotion Location',
  '2': 'Awareness'
}

export const codeToAdType = code => {
  let result = ''
  if (!code) return result
  if (!adType[code]) return result
  result = adType[code]
  return result
}

export default adType
