export const ContentReducer = (state, action) => {
  if (action.type === 'SET_CONTENT_REF') {
    return {
      ...state,
      ref: action.data.ref,
    }
  }
  if (action.type === 'ADD_CONTENT_HEIGHT_FACTOR') {
    const heightFactors = state.heightFactors
    heightFactors[action.data.name] = action.data.value
    return {
      ...state,
      heightFactors,
    }
  }
  if (action.type === 'REMOVE_CONTENT_HEIGHT_FACTOR') {
    const heightFactors = state.heightFactors
    delete heightFactors[action.data.name]
    return {
      ...state,
      heightFactors,
    }
  }
  if (action.type === 'ADD_CONTENT_WIDTH_FACTOR') {
    const widthFactors = state.widthFactors
    widthFactors[action.data.name] = action.data.value
    return {
      ...state,
      widthFactors,
    }
  }
  if (action.type === 'REMOVE_CONTENT_WIDTH_FACTOR') {
    const widthFactors = state.widthFactors
    delete widthFactors[action.data.name]
    return {
      ...state,
      widthFactors,
    }
  }
  return state
}
