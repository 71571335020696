import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  memo
} from 'react'
import cx from 'clsx'
import _ from 'lodash'
import UserRangeWithCountOrPercent from '../blocks/UserRangeWithCountOrPercent'
import { checkInputNumber } from '../../../../helper/potentialPayingUserAction'

const optionsWithOut = 'percent'

const GoodVideosLifetimeDays = memo(
  forwardRef(({ classes, defaultValue, fetchedData, disabled }, ref) => {
    const [state, setState] = useState(defaultValue)
    const setValue = (name, value) =>
      setState(pre => ({
        ...pre,
        [name]: checkInputNumber(name, value, pre[name])
      }))

    useEffect(() => {
      if (fetchedData) setState(fetchedData)
    }, [fetchedData])

    useImperativeHandle(ref, () => state, [state])

    return (
      <div className={classes.row}>
        <div className={`${classes.filter} ${classes.flexStart}`}>
          <span
            className={cx(
              'checkbox',
              state.is_default && 'selected',
              disabled && 'disabled'
            )}
            onClick={() =>
              !disabled && setValue('is_default', !state.is_default)
            }
          >
            <span></span>
          </span>
          <div className='row-content row-next-line'>
            <span className={classes.text}>Good Video Lifetime Days:</span>
            <div className='row-content-center'>
              <span className={classes.text}>Day duration </span>
              <UserRangeWithCountOrPercent
                {...{ state, setValue, optionsWithOut, classes, disabled }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }),
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

export default GoodVideosLifetimeDays
