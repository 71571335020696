export const LayoutReducer = (state, action) => {
  if (action.type === 'SET_MENU_EXTENDED') {
    return {
      ...state,
      isMenuExtended: action.data.isMenuExtended,
    }
  }

  return state
}
