import { RouteType } from 'types/route'
import React from 'react'
import {
    ToolsIcon,
} from 'config/Routes/Icons'


const VideoPage = React.lazy(
    ()=>import('projects/Analysis/components/pages/Video/index')
)

const CommunityPage = React.lazy(
    ()=>import('projects/Analysis/components/pages/Community/index')
)

const ProfilePage = React.lazy(
    ()=>import('projects/Analysis/components/pages/Profile/index')
)

const analysis:RouteType[]=[
    {
        key: 'analysis-video',
        path: '/analysis-video',
        title: 'Analysis Video',
         permissions: ['video'],
        icon: ToolsIcon,
        component: VideoPage,
        exact: true,
        subRoutes: []
    },
    {
        key: 'analysis-community',
        path: '/analysis-community',
        title: 'Analysis Community',
         permissions: ['community'],
        icon: ToolsIcon,
        component: CommunityPage,
        exact: true,
        subRoutes: []
    },
    {
        key: 'analysis-profile',
        path: '/analysis-profile',
        title: 'Analysis profile',
         permissions: ['profile'],
        icon: ToolsIcon,
        component: ProfilePage,
        exact: true,
        subRoutes: []
    },
]

export default analysis