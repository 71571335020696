import { NUMBER_FORMAT_KEYS } from 'config/constansts'
import _ from 'lodash'

export const checkInputNumber = (name, value, pre) => {
  return Array.isArray(value)
    ? value.map(nv => _.get(nv, 'value', nv))
    : NUMBER_FORMAT_KEYS.includes(name)
    ? !isNaN(value.replace(/\.|-/g, '').trim())
      ? Number(value)
      : pre
    : value
}
