import React, { createContext, useReducer, useEffect } from 'react'

import { ModalReducer } from './Reducer'

/**
 * @type {MaskStateType}
 */
const initialModalValue = {
  modals: []
}

export const initialContextValue = {
  ...initialModalValue,
  /**
   * @type {React.Dispatch<ModalActionType>}
   */
  dispatchModal: () => {}
}

export const ModalContext = createContext(initialContextValue)
ModalContext.displayName = 'Modal'

export const ModalContextProvider = props => {
  useEffect(() => {
    return () => {}
  }, [])

  const [modal, dispatchModal] = useReducer(ModalReducer, initialModalValue)

  return (
    <ModalContext.Provider value={{ modal, dispatchModal }}>
      {props.children}
    </ModalContext.Provider>
  )
}
