import React from 'react'
import makeTabsHelper from 'helpers/Route/makeTabs'

import { OverviewIcon, BizIcon } from 'projects/Dashboard/config/Routes/Icons'
import { RouteType } from 'types/route'

export const Finance = React.lazy(() => import('projects/Finance'))

const Overview = React.lazy(
  () => import('projects/Finance/components/pages/Overview')
)
const Assumptions = React.lazy(
  () => import('projects/Finance/components/pages/Assumptions')
)
const Acquisition = React.lazy(
  () => import('projects/Finance/components/pages/Acquisition')
)
const SupplyAndDemand = React.lazy(
  () => import('projects/Finance/components/pages/SupplyAndDemand')
)

export const tabs: RouteType[] = [
  {
    path: '/overview',
    title: 'Overview',
    permissions: [],
    icon: OverviewIcon,
    key: 'financial',
    contentComponent: Overview,
    exact: true,
    subRoutes: []
    // subRoutes: subRoutesForBusiness
  },
  {
    path: '/assumptions',
    title: 'Assumptions',
    permissions: [],
    icon: BizIcon,
    key: 'financial',
    contentComponent: Assumptions,
    exact: true,
    subRoutes: []
  },
  {
    path: '/acquisition',
    title: 'Acquisition',
    permissions: [],
    icon: BizIcon,
    key: 'financial',
    contentComponent: Acquisition,
    exact: true,
    subRoutes: []
  },
  {
    path: '/supply-and-demand',
    title: 'Supply - And - Demand',
    permissions: [],
    icon: BizIcon,
    key: 'financial',
    contentComponent: SupplyAndDemand,
    exact: true,
    subRoutes: []
  }
]

export const makeTabs = makeTabsHelper(tabs)
