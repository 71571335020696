import React, {
  createContext,
  useReducer,
  useEffect,
  useRef,
  useCallback
} from 'react'
import get from 'lodash/get'

import { contentScrollService } from 'components/layouts/Content/Service'

import { ContentReducer } from './Reducer'

export const ContentContext = createContext()
ContentContext.displayName = 'Content'

export const ContentContextProvider = ({ contentRef, children }) => {
  const contentScrollSubscription = useRef(null)
  const [content, dispatchContent] = useReducer(ContentReducer, {
    ref: contentRef,
    heightFactors: {}
  })

  useEffect(() => {
    contentScrollSubscription.current = contentScrollService
      .listener()
      .subscribe(event => {
        if (event.action === 'scrollTo' || event.action === 'jumpTo') {
          if (content.ref.current !== null) {
            content.ref.current.scrollTop = event.value
          }
        }
      })
    return () => {
      if (contentScrollSubscription.current) {
        contentScrollSubscription.current.unsubscribe()
      }
    }
  }, [content.ref])

  useEffect(() => {
    if (content.ref.current !== null) {
      const heightFactors = get(content, 'heightFactors', {})
      const heightFactor = Object.values(heightFactors).reduce((hf, h) => {
        hf += h
        return hf
      }, 0)
      content.ref.current.style['height'] =
        heightFactor === 0 ? null : `calc(100% - ${heightFactor}px)`
    }
  }, [content])

  const getOffsetSuggestion = useCallback(() => {
    if (content.ref.current !== null) {
      const contentWidth = get(
        content,
        'ref.current.children[0].offsetWidth',
        0
      )

      const heightFactors = get(content, 'heightFactors', {})
      const heightFactor = Object.values(heightFactors).reduce((hf, h) => {
        hf += h
        return hf
      }, 0)

      const footerHeight = get(
        content,
        'ref.current.children[1].offsetHeight',
        0
      )
      return {
        width: contentWidth,
        height: window.innerHeight - footerHeight - heightFactor
      }
    }
    return {
      width: 0,
      height: 0
    }
  }, [content])

  return (
    <ContentContext.Provider
      value={{
        content: { ...content, offsetSuggestion: getOffsetSuggestion() },
        dispatchContent
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}
