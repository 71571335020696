import { useLayoutEffect, useEffect, useRef, useState } from 'react'

export const useComponentDidMount = fn => {
  const [mounted, setState] = useState(false)
  useLayoutEffect(() => {
    if (!mounted) {
      setState(true)
      if (typeof fn === 'function') {
        fn()
      }
    }
  }, [mounted, fn])
  return
}

export const useComponentWillUnmount = fn => {
  const callbackRef = useRef(fn)

  useEffect(() => {
    callbackRef.current = fn
  }, [fn])

  return useEffect(
    () => () => {
      if (typeof callbackRef.current === 'function') {
        callbackRef.current()
      }
    },
    []
  )
}
