const adCta= {
  '01': 'Learn More',
  '02': 'Shop Now',
  '03': 'Contact Us',
  '04': 'Watch More',
  '05': 'Book Now',
  '06': 'Sign Up',
  '07': 'Get Now',
  '08': 'Apply Now',
  '09': 'Call Now',
  '10': 'Send Message',
  '11': 'Send Email'
}

export const codeToAdCta = code => {
  let result = ''
  if (!code) return result
  if (!adCta[code]) return result
  result = adCta[code]
  return result
}

export default adCta
