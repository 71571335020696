import request, { BASE_URL, getAccessToken } from 'apis/request'
export { BASE_URL } from 'config/Server'

export const getBiConfigApi = async (
  { params = {}, data = {}, path = {} } = {
    params: {},
    data: {},
    path: {}
  }
) => {
  const access_token = await getAccessToken()
  if (access_token === null || access_token.length < 1) {
    // DEBUG: missing access token
    console.log(
      'missing access token',
      { params, data, path },
      JSON.stringify({ params, data, path })
    )
    return getBiConfigApi({ params, data, path })
  }
  return request('GET', `${BASE_URL}/v1/general/config`, {
    params,
    data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'content-type': 'application/json'
    }
  })
}
