export const customColors ={
    custom: {
        colors: {
          black: '#000',
          black1: '#212121',
          blue1: '#6a9eff', //themeColor
          blue2: '#225ece', //DarkThemeColor
          gray1: '#f2f2f2',
          gray2: '#9e9e9e', //Text
          gray3: '#e0e0e0',
          gray4: '#dbdbdb',
          gray5: '#9b9b9b',
          red1: '#ef3e35', //
          yellow1: '#f8d742', //starYellow
          green1: '#4caf50',
          green2: '#26cbc7',
          purple: '#8c5898',
          white: '#fff',
          orange: '#FF765C',
          lightOrange: 'rgba(255, 120, 10, 0.08)',
          label1: '#314146',
          label2: 'rgba(40, 70, 80, 0.6)',
          label3: 'rgba(40, 70, 80, 0.3)',
          background: '#F2F4F5',
          link: '#32A0A6',
    
        },
        layouts: {
          menu: {
            extended: {
              width: 254
            },
            narrow: {
              width: 48
            },
            width: 254,
            background: '#f2f2f2'
          },
          appbar: {
            height: 72
          }
        },
        zIndexes: {
          header: 1000,
          joblist: 1100
        }
      }
}