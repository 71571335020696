import _ from 'lodash'
import { generateRandomString } from 'helpers/General'
import { Dashboard } from 'types/chartEnum'

import {
  EXCEPTIONS_KEYS_OPERATORS,
  POTENTIAL_PAYING_USER_FORMATTED_KEY,
  POTENTIAL_PAYING_USER_EMPTY_FIELD,
  POTENTIAL_PAYING_USER_KEYS_EMPTY_DEFAULT
} from 'config/constansts/SmallFilter'
import MODAL_TYPE from 'config/constansts/ModalType'

const addFieldOperator = (fieldName, operator) => {
  const fieldKey = _.findKey(EXCEPTIONS_KEYS_OPERATORS, item =>
    item.includes(fieldName)
  )
  return fieldKey ? { [fieldKey]: operator } : {}
}

const potentialPayingUserConvertCondition = (state, condition, key) => {
  const option_type = POTENTIAL_PAYING_USER_KEYS_EMPTY_DEFAULT[key]
  const default_empty_object = option_type
    ? POTENTIAL_PAYING_USER_EMPTY_FIELD[option_type]
    : {}
  if (!condition.field) {
    return {
      ...state,
      query_type: {
        ...default_empty_object,
        ...state.query_type
      }
    }
  }
  if (POTENTIAL_PAYING_USER_FORMATTED_KEY.includes(condition.field)) {
    return {
      ...state,
      query_type: {
        ...default_empty_object,
        ...state.query_type,
        [condition.field]: condition
      }
    }
  } else {
    return {
      ...state,
      [condition.field]: condition.param,
      ...addFieldOperator(condition.field, condition.operator ?? ''),
      ...(option_type
        ? {
            query_type: {
              ...default_empty_object,
              ...state.query_type
            }
          }
        : {})
    }
  }
}

const convertGroup = (group, type, reduce_store, key) => {
  const orGroup = _.get(group, '[$or]', [])
  const andGroup = _.get(group, '[$and]', [])
  const { Overview } = Dashboard
  const potential_paying_user =
    MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.PotentialPayingUser]
  const isPotentialPayingUser = type === potential_paying_user

  if (orGroup.length > 0) {
    return convertGroup(orGroup, type, reduce_store, key)
  } else if (andGroup.length > 0) {
    return convertGroup(andGroup, type, reduce_store, key)
  } else {
    return group.reduce((state, condition) => {
      const orGroup = _.get(condition, '[$or]', [])
      const andGroup = _.get(condition, '[$and]', [])
      if (orGroup.length > 0) {
        return { ...state, ...convertGroup(orGroup, type, state, key) }
      } else if (andGroup.length > 0) {
        return { ...state, ...convertGroup(andGroup, type, state, key) }
      } else {
        if (isPotentialPayingUser) {
          return potentialPayingUserConvertCondition(state, condition, key)
        }
        return {
          ...state,
          [condition.field]: condition.param,
          ...addFieldOperator(condition.field, condition.operator ?? '')
        }
      }
    }, reduce_store ?? {})
  }
}

const checkPotentialPayingUserParamEmpty = (condition, type) => {
  const userRangeParam = condition.query_type.userRange.param
  const countOrPercentParam1 = condition.query_type[`${type}1`].param
  const countOrPercentParam2 = condition.query_type[`${type}2`].param
  const countOrPercentOperator1 = condition.query_type[`${type}1`].Operator
  const countOrPercentOperator2 = condition.query_type[`${type}2`].Operator
  return userRangeParam.max || userRangeParam.min
    ? 'userRange'
    : countOrPercentParam1 ||
      countOrPercentParam2 ||
      countOrPercentOperator1 ||
      countOrPercentOperator2
    ? `${type}1`
    : ''
}

const FormatFetchedData = (data, type) => {
  const { Overview } = Dashboard
  const potential_paying_user =
    MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.PotentialPayingUser]
  const isPotentialPayingUser = type === potential_paying_user

  if (isPotentialPayingUser) {
    return data.reduce((cur, filter) => {
      const key = filter.graph_type_id.replace(`${potential_paying_user}:`, '')
      const reduce_store = {}
      const converted = convertGroup(filter.items, type, reduce_store, key)
      const fields = {
        ...converted,
        ...(converted.hasOwnProperty('query_type')
          ? {
              query_type: {
                ...converted.query_type,
                value: checkPotentialPayingUserParamEmpty(
                  converted,
                  POTENTIAL_PAYING_USER_KEYS_EMPTY_DEFAULT[key]
                )
              }
            }
          : {})
      }

      return {
        ...cur,
        [key]: {
          ...fields,
          id: filter.small_filter_id ?? '',
          is_default: filter.is_default ?? false
        }
      }
    }, {})
  }

  return data.map(filter => {
    const fields = convertGroup(filter.items, type)
    return {
      ...fields,
      id: filter.small_filter_id ?? '',
      is_default: filter.is_default ?? false,
      key: generateRandomString()
    }
  })
}

export default FormatFetchedData
