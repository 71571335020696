import { Subject } from 'rxjs'
import {
  Filter,
  DateRangeType,
  MakeTableDataFunctionType,
  ExportFilterApiType
} from 'types/export'

export interface BatchJob {
  key: string
  makeTableDataFunc: MakeTableDataFunctionType
  apis?:
    | {
        [key: string]: ExportFilterApiType
      }
    | (() => {
        [key: string]: ExportFilterApiType
      })
  batchFileName: string
  isFetching: boolean
  isDownloaded: boolean
  isFailed?: boolean
  result: unknown
  filter: Filter | null
  dateRanges: DateRangeType | null
  rules: unknown
  filterRange?: Function
}

interface AddBatchJob {
  action: 'add_batch_job'
  new_batch_jobs: BatchJob[]
}

const addBatchJobSubject = new Subject<AddBatchJob>()

export const addBatchJobService = {
  addBatchJob: (new_batch_jobs: BatchJob[]) =>
    addBatchJobSubject.next({
      action: 'add_batch_job',
      new_batch_jobs
    }),
  listener: () => addBatchJobSubject.asObservable()
}
