import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
  memo,
  useRef
} from 'react'
import cx from 'clsx'
import _ from 'lodash'
import Input from 'components/blocks/TextInput'
import Button from 'components/blocks/Button'
import Dropdown from 'components/blocks/Dropdown'
import DropdownSelector from 'components/blocks/DropdownSelector'
import { checkInputNumber } from '../../../../helper/potentialPayingUserAction'
import {
  CONDITION_OPTIONS,
  PROFILE_COMPLETION_RATE_OPTIONS
} from 'config/constansts'

const ProfileCompletionRate = memo(
  forwardRef(({ classes, defaultValue, fetchedData, disabled }, ref) => {
    const isClickTriggerOptional = useRef(false)
    const [state, setState] = useState(defaultValue)
    const [openOptionalOptions, setOpenOptionalOptions] = useState(false)
    const setValue = useCallback(
      (name, value) =>
        setState(pre => ({
          ...pre,
          [name]: checkInputNumber(name, value, pre[name])
        })),
      []
    )

    const clickOptionalOptions = useCallback(() => {
      if (openOptionalOptions) {
        setState(pre => ({
          ...pre,
          profileType2: '',
          other_operator: '',
          profileCount: ''
        }))
      }
      setOpenOptionalOptions(value => !value)
      isClickTriggerOptional.current = true
    }, [openOptionalOptions])

    const triggerOpenOptionalOptions = useCallback(() => {
      if (
        state.other_operator ||
        state.profileType2?.length > 0 ||
        state.profileCount
      ) {
        setOpenOptionalOptions(true)
      } else {
        setOpenOptionalOptions(false)
      }
    }, [state.other_operator, state.profileCount, state.profileType2])

    useEffect(() => {
      if (fetchedData) setState(fetchedData)
    }, [fetchedData])

    useImperativeHandle(ref, () => state, [state])

    useEffect(() => {
      !isClickTriggerOptional.current && triggerOpenOptionalOptions()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.key, triggerOpenOptionalOptions])

    return (
      <div className={classes.row}>
        <div className={`${classes.filter} ${classes.flexStart}`}>
          <span
            className={cx(
              'checkbox',
              state.is_default && 'selected',
              disabled && 'disabled'
            )}
            onClick={() =>
              !disabled && setValue('is_default', !state.is_default)
            }
          >
            <span></span>
          </span>
          <div className='row-content row-next-line'>
            <span className={classes.text}>Profile Completion:</span>
            <div className='row-content-center'>
              <Dropdown
                options={CONDITION_OPTIONS}
                value={state.operator}
                setValue={value => setValue('operator', value)}
                width={150}
                wrapperStyle={{ marginLeft: '0' }}
                disabled={disabled}
              />
              <DropdownSelector
                style={{
                  display: 'inline-block',
                  margin: '0px 8px',
                  width: 200
                }}
                value={state.profileType1}
                onChange={value => {
                  setValue('profileType1', value)
                }}
                defaultValue={[PROFILE_COMPLETION_RATE_OPTIONS[0]]}
                options={PROFILE_COMPLETION_RATE_OPTIONS}
                allowSelectAll={true}
                disabled={disabled}
              />
              <Button
                key='button_and'
                variant='outlined'
                color='primary'
                label='and'
                onClick={clickOptionalOptions}
                disabled={disabled}
              />
              {openOptionalOptions && (
                <>
                  <Dropdown
                    options={CONDITION_OPTIONS}
                    value={state.other_operator}
                    setValue={value => setValue('other_operator', value)}
                    width={150}
                    disabled={disabled}
                  />
                  <DropdownSelector
                    style={{
                      display: 'inline-block',
                      margin: '0px 8px',
                      width: 200
                    }}
                    value={state.profileType2}
                    onChange={value => setValue('profileType2', value)}
                    defaultValue={[PROFILE_COMPLETION_RATE_OPTIONS[0]]}
                    options={PROFILE_COMPLETION_RATE_OPTIONS}
                    allowSelectAll={true}
                    disabled={disabled}
                  />
                  <span className={classes.text}>at least</span>
                  <Input
                    value={state.profileCount ?? ''}
                    setValue={value => setValue('profileCount', value)}
                    width={57}
                    style={{ margin: '0 8px' }}
                    disabled={disabled}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }),
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

export default ProfileCompletionRate
