import React, { Fragment, useCallback, useContext, useEffect } from 'react'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip'

import { LayoutContext } from 'contexts/Layout'
import { useRoute } from 'contexts/Route'

import { LogoutIcon, PlayseeIcon } from 'helpers/Icons'
import { AuthContext } from 'contexts/Auth'

const a11yProps = index => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  }
}

const StyledTabs = withStyles({
  root: {},
  indicator: {
    display: 'none'
  }
})(props => <MuiTabs {...props} />)

const StyledTab = withStyles(theme => ({
  root: {
    height: '40px',
    minHeight: '40px',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 500,
    color: '#9e9e9e',
    transition:
      'padding 0.25s ease-out, margin 0.25s ease-out, width 0.25s ease-out, borderRadius 0.25s ease-out',
    'div.menu.extended &': {
      width: '222px',
      padding: '8px 12px',
      marginLeft: '32px',
      borderRadius: '4px 0 0 4px'
    },
    'div.menu:not(.extended) &': {
      width: '40px',
      minWidth: '40px',
      margin: '0 4px',
      borderRadius: '4px',
      padding: '8px',
      overflow: 'hidden'
    },
    '&$selected': {
      color: '#212121',
      opacity: 1,
      background: '#fff',
      '& svg': {
        color: '#6a9eff'
      }
    },
    '&:hover:not($selected)': {
      color: '#212121',
      opacity: 1,
      '& svg': {
        color: '#6a9eff'
      }
    }
  },
  wrapper: {
    height: '24px',
    whiteSpace: 'nowrap',
    alignItems: 'normal',
    flexDirection: 'inherit',
    justifyContent: 'normal',
    lineHeight: '24px'
  },
  selected: {},
  disabled: {}
}))(props => <MuiTab component={Link} disableRipple {...props} />)

const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    background: theme.custom.layouts.menu.background,
    transition: 'width 0.25s ease-out',
    '& .icon': {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      textAlign: 'center',
      verticalAlign: 'top',
      color: '#9e9e9e',
      transition: 'margin 0.25s ease-out, color 0.15s ease-out'
    },
    '& .title': {
      display: 'inline-block',
      height: '24px',
      lineHeight: '24px',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
      transition: 'opacity 0.25s ease-out'
    },
    '& ol': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        transition:
          'padding 0.25s ease-out, margin 0.25s ease-out, width 0.25s ease-out, borderRadius 0.25s ease-out',
        '&:not(:hover)': {
          opacity: 0.7
        },
        '&:not(.logout):hover .icon': {
          color: theme.custom.colors.blue1
        },
        '&.logout:hover .icon': {
          color: theme.custom.colors.red1
        }
      }
    },
    '&.extended': {
      width: `${theme.custom.layouts.menu.extended.width}px`,
      '& .icon': {
        margin: '0 12px 0 0 !important'
      },
      '& .title': {
        opacity: 1
      },
      '& ol': {
        '& li': {
          width: '222px',
          padding: '8px 12px',
          marginLeft: '32px',
          borderRadius: '4px 0 0 4px'
        }
      }
    },
    '&:not(.extended)': {
      width: `${theme.custom.layouts.menu.narrow.width}px`,
      '& .icon': {
        margin: '0 !important'
      },
      '& .title': {
        opacity: 0
      },
      '& ol': {
        '& li': {
          width: '40px',
          minWidth: '40px',
          margin: '0 4px',
          borderRadius: '4px',
          padding: '8px',
          overflow: 'hidden'
        }
      }
    }
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    padding: '31px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& span.name': {
      color: theme.custom.colors.black1,
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
      transition: 'opacity 0.25s ease-out'
    },
    '& span.department': {
      color: theme.custom.colors.gray2,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      transition: 'opacity 0.25s ease-out'
    },
    'div.menu.extended &': {
      '& span.department, & span.name': {
        opacity: 1
      }
    },
    'div.menu:not(.extended) &': {
      '& span.department, & span.name': {
        opacity: 0
      }
    }
  },
  profilePicture: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    transition: 'all 0.25s ease-out',
    '& img': {
      borderRadius: '50%',
      overflow: 'hidden',
      transition: 'all 0.25s ease-out'
    },
    'div.menu:not(.extended) &': {
      width: '38px',
      height: '38px',
      border: `2px solid ${theme.custom.colors.red1}`,
      margin: '22px 0 27px 0',
      '& img': {
        width: '32px',
        height: '32px'
      }
    },
    'div.menu.extended &': {
      width: '78px',
      height: '78px',
      border: `3px solid ${theme.custom.colors.red1}`,
      margin: '0 0 7px 0',
      '& img': {
        width: '70px',
        height: '70px'
      }
    }
  },
  tabs: {
    height: '100%',
    flex: '0 0 120px'
  },
  bottom: {
    margin: 'auto 0 0 0',
    padding: '0 0 74px 0',
    '& ol': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  }
}))

const Menu = ({ routes }) => {
  const classes = useStyle()
  const { route } = useRoute()
  const { layout } = useContext(LayoutContext)
  const { auth } = useContext(AuthContext)

  const getRouteIndex = useCallback(() => {
    for (let index in routes) {
      const hit = route.breadcrumbs.find(
        route => route.path === routes[index].path
      )
      if (typeof hit !== 'undefined') {
        return parseInt(index)
      }
    }
  }, [routes, route])

  useEffect(() => {}, [layout])

  const profilePicture = _.get(auth, 'member.profile_picture', null)
  const userName = _.get(auth, 'member.name', 'No Name')
  const departmentName = _.get(auth, 'member.department.name', 'No Department')

  return (
    <div
      className={cx(classes.root, 'menu', layout.isMenuExtended && 'extended')}
    >
      <div className={classes.user}>
        <Tooltip
          title={userName}
          placement='right'
          disableHoverListener={layout.isMenuExtended}
        >
          <div className={classes.profilePicture}>
            {profilePicture !== null ? (
              <img src={profilePicture} alt={userName}></img>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  background: 'white',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <PlayseeIcon style={{ width: '50%', height: 'auto' }} />
              </div>
            )}
          </div>
        </Tooltip>

        <span className='name'>{userName}</span>
        <span className='department'>{departmentName}</span>
      </div>
      <StyledTabs
        orientation='vertical'
        variant='scrollable'
        value={getRouteIndex()}
        aria-label='Vertical tabs'
        className={classes.tabs}
      >
        {routes.map((route, index) => (
          <StyledTab
            key={`menu_button_${index}`}
            icon={
              <Fragment>
                <span className='icon'>{React.createElement(route.icon)}</span>
                <span className='title'>{route.title}</span>
              </Fragment>
            }
            to={{ pathname: route.path }}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      <div className={classes.bottom}>
        <ol>
          <li className='logout' onClick={auth.Logout}>
            <span className='icon'>
              <LogoutIcon />
            </span>
            <span className='title'>Logout</span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Menu
