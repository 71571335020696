import { createMuiTheme } from '@material-ui/core/styles'
import GlobalTheme from 'components/App/theme/customThemeConfig'

export const extendTheme = {
  typography: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFamily: ['SF Pro Text', '-apple-system', 'BlinkMacSystemFont'].join(
      ', '
    )
  },
  palette: {
    bizPrimary: {
      light: {
        p1: '#FF765C',
        p2: 'rgba(255, 120, 10, 0.08)'
      }
    },
    bizLabel: {
      light: {
        l1: '#314146',
        l2: 'rgba(40, 70, 80, 0.6)',
        l3: 'rgba(40, 70, 80, 0.3)'
      }
    },
    bizBackground: {
      light: {
        l2: '#F2F4F5'
      }
    }
  }
}

export type CustomTheme = typeof GlobalTheme & typeof extendTheme
const bizTheme = createMuiTheme(GlobalTheme, extendTheme) as CustomTheme

export default bizTheme
