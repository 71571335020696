import React, { createContext, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

const ENGAGEMENT_ITEMS = [
  { label: 'Views', value: 'view' },
  { label: 'Likes', value: 'like' },
  { label: 'Comment', value: 'comment' }
]

const ENGAGEMENT_ITEMS_WITH_VIDEOS = ENGAGEMENT_ITEMS.concat([
  { label: 'Follow', value: 'follow' },
  { label: 'Videos', value: 'post' }
])

const defaultDateRanges = {
  startAt: `${dayjs().add(-28, 'day').format('YYYY-MM-DDT00:00:00')}Z`,
  endAt: `${dayjs().add(-1, 'day').format('YYYY-MM-DDT23:59:59')}Z`
}

const defaultVariables = {
  adsBudgetDistribution: 10,
  // adsBidByTime: 3,
  adsBidByTime: { placement: 1, unit: 'day' },
  adsCumulativeBidDistribution: { interval: 1 },
  adsCumulativeBudgetDistribution: { interval: 1 },
  overviewRetention: { time_period: 1 },
  adsBuilderRetention: { time_period: 1 },
  realTimeUids: {},
  businessCreateBusinessCount: { time_period: 1 },
  businessPayingBusinessCount: { time_period: 1 },
  businessDailyPrepayBreakdown: { time_period: 1 },
  businessCreateAdsRetention: { time_period: 1 },
  businessActiveAdsRetention: { time_period: 1 },
  businesstotalRevenue: { time_period: 1 },
  businessAvgPrepayBudgetPerBusiness: { time_period: 1 },
  creatorEngagementDistributionDateRange: {
    dateRanges: defaultDateRanges
  },
  creatorEngagementDistributionItems: ENGAGEMENT_ITEMS,
  creatorEngagementAndVideoGrowthDateRange: {
    dateRanges: defaultDateRanges
  },
  creatorEngagementAndVideoGrowthItems: ENGAGEMENT_ITEMS_WITH_VIDEOS
}

type GetterReturnType<T = any> = T
export type GetterType<T = any> = (key: string) => GetterReturnType<T>
type SetterDataType = { key: string; value: any; }[] | { key: string; value: any; }

interface LockerContextType {
  getter: GetterType
  setter: (data: SetterDataType ) => void
}

export const LockerContext = createContext<LockerContextType>({ getter: () => {}, setter: () => {} })
LockerContext.displayName = 'Locker'

type VariablesType = typeof defaultVariables & { [key: string]: GetterReturnType }

export const LockerContextProvider: React.FC = ({ children }) => {
  const [variables, setVariables] = useState<VariablesType>(defaultVariables)
  const getter = useCallback((key: keyof typeof defaultVariables | string) => _.get(variables, key, null), [variables])
  const setter = useCallback(
    (data: SetterDataType) => {
      if (Array.isArray(data)) {
        setVariables({
          ...variables,
          ...data.reduce((obj, cur) => {
            obj[cur.key] = cur.value
            return obj
          }, {} as VariablesType)
        })
      } else {
        const { key, value } = data
        const newVariables: VariablesType = {
          ...variables
        }
        newVariables[key] = value
        setVariables(newVariables)
      }
    },
    [variables]
  )
  return (
    <LockerContext.Provider
      value={{
        getter,
        setter
      }}
    >
      {children}
    </LockerContext.Provider>
  )
}
