import React, { useMemo, useContext, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import get from 'lodash/get'
import Dialog from '@material-ui/core/Dialog'

import { ModalContext } from './Context'

const useStyles = makeStyles(theme => ({
  noBackground: {
    background: 'none'
  },
  noOutlined: {
    border: 'none'
  }
}))

// TODO: disable click outside to close

const Modal = React.memo(props => {
  const { content, child, dispatchModal } = props
  const dialogConfig = get(props, 'dialogConfig', {})
  const maxWidth = get(dialogConfig, 'maxWidth', 'sm')
  const transparent = get(dialogConfig, 'transparent', false)
  const disableClose = get(dialogConfig, 'disableClose', false)
  const disableBackdropClick = get(dialogConfig, 'disableBackdropClick', false)

  const classes = useStyles()
  const handleClose = useCallback(() => {
    dispatchModal({
      type: 'CLOSE_MODAL'
    })
  }, [dispatchModal])

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth={maxWidth}
      classes={
        transparent
          ? {
              paper: classes.noBackground
            }
          : {}
      }
      disableEscapeKeyDown={disableClose}
      disableBackdropClick={disableBackdropClick}
      PaperProps={
        transparent
          ? {
              variant: 'outlined',
              square: true,
              classes: {
                outlined: classes.noOutlined
              }
            }
          : {}
      }
    >
      {content}
      {child}
    </Dialog>
  )
})

export const ModalWrapper = props => {
  const { dispatchModal } = useContext(ModalContext)
  return <Modal {...props} dispatchModal={dispatchModal} />
}

export default () => {
  const { modal } = useContext(ModalContext)

  const modals = useMemo(() => {
    const makeComponent = props => {
      return <ModalWrapper {...props} />
    }

    return [...get(modal, 'modals', [])].reverse().reduce((child, modal) => {
      return makeComponent({ ...modal, child })
    }, null)
  }, [modal])

  return <div>{modals}</div>
}
