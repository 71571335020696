import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  error: {
    borderRadius: 4,
    border: `solid 1px ${theme.custom?.colors?.red1}`,
    '& .MuiInputBase-input': {
      border: 'none !important'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.custom?.colors?.white,
    border: '1px solid #e0e0e0',
    fontSize: theme.typography.fontSize,
    padding: '10px 10px 10px 12px',
    boxSizing: 'border-box',
    height: 40,

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderRadius: 4,
      border: `solid 1px ${theme.custom?.colors?.gray3}`
    },
    '&:focus': {
      borderRadius: 4,
      border: `solid 1px ${theme.custom?.colors?.gray3}`,
      '&:not(:placeholder-shown)': {
        borderRadius: 4,
        border: `solid 1px ${theme.custom?.colors?.gray3}`
      }
    },
    '&:not(:placeholder-shown)': {
      borderRadius: 4,
      border: `solid 1px ${theme.custom?.colors?.blue1}`
    }
  }
}))(InputBase)
export default CustomInput
