import React, { forwardRef } from 'react'
import {
  RecentOpenAppFrequency,
  RecentGoodVideoFrequency,
  GoodVideosLifetimeDays,
  ProfileCompletionRate,
  FollowerDistribution,
  FollowerGrowthRate,
  EngagementRateReceive
} from './components'
import SmallFilter from '../SmallFilter'
import { POTENTIAL_PAYING_USER_ITEMS } from 'config/constansts'
import MODAL_TYPE from 'config/constansts/ModalType'
import { Dashboard } from 'types/chartEnum'

const PotentialPayingUserSmallFilter = forwardRef(
  ({ classes, defaultValue, fetchedData, disabled }, ref) => {
    const { Overview } = Dashboard
    const type = MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.PotentialPayingUser]
    const Components = [
      {
        component: RecentOpenAppFrequency,
        itemType: POTENTIAL_PAYING_USER_ITEMS.recent_open_app_frequency
      },
      {
        component: RecentGoodVideoFrequency,
        itemType: POTENTIAL_PAYING_USER_ITEMS.recent_good_video_frequency
      },
      {
        component: GoodVideosLifetimeDays,
        itemType: POTENTIAL_PAYING_USER_ITEMS.good_videos_lifetime_days
      },
      {
        component: ProfileCompletionRate,
        itemType: POTENTIAL_PAYING_USER_ITEMS.profile_completion_rate
      },
      {
        component: FollowerDistribution,
        itemType: POTENTIAL_PAYING_USER_ITEMS.follower_distribution
      },
      {
        component: FollowerGrowthRate,
        itemType: POTENTIAL_PAYING_USER_ITEMS.follower_growth_rate
      },
      {
        component: EngagementRateReceive,
        itemType: POTENTIAL_PAYING_USER_ITEMS.engagement_rate_receive
      }
      // {
      //   component: EngagementRateGive,
      //   itemType: POTENTIAL_PAYING_USER_ITEMS.engagement_rate_give
      // }
    ]

    return (
      <>
        <div className={classes.category}>Potential Paying User</div>
        <div className={classes.title}>Potential Paying User</div>
        <div className={classes.filters}>
          {Components.map(item => {
            const { component: Component, itemType } = item
            return (
              <Component
                {...{
                  classes,
                  ref: dom => (ref.current[type][itemType] = dom),
                  key: itemType,
                  defaultValue: defaultValue[itemType],
                  fetchedData: fetchedData[itemType],
                  disabled
                }}
              />
            )
          })}
        </div>
      </>
    )
  }
)

const PotentialPayingUser = (props, ref) => {
  const width = 1200
  const height = 600
  return (
    <SmallFilter {...{ ...props, width, height }}>
      <PotentialPayingUserSmallFilter {...ref} />
    </SmallFilter>
  )
}

export default forwardRef(PotentialPayingUser)
