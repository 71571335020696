import React, { useRef, useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import _ from 'lodash'
import md5 from 'md5'
import Alert from '@material-ui/lab/Alert'

import {
  makeStyles,
  Grid,
  // InputAdornment,
  // OutlinedInput,
  withStyles,
  FormControl
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import LockIcon from '@material-ui/icons/Lock'

import InputWithIcon from 'components/blocks/InputWithIcon'
import Button from 'components/blocks/Button'
import Footer from 'components/layouts/Footer'
import { AuthContext } from 'contexts/Auth'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%'
  },
  container: {
    height: '100%'
  },
  left: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.text.secondary,
    '& .box': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '258px',
      maxHeight: '311px',
      width: 'calc(100% - 64px)',
      height: 'calc(100% - 64px)'
    },
    '& svg': {
      objectFit: 'contain',
      width: '100%',
      height: '100%'
    }
  },
  right: {
    position: 'relative'
  },
  group: {
    maxWidth: '360px',
    padding: '16px'
  },
  input: {
    '& input': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px'
    }
  },
  btn: {
    textAlign: 'center'
  },
  footer: {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    width: '100%'
  }
}))

const StyledLock = withStyles(theme => ({
  root: {
    color: theme.custom.colors.purple
  }
}))(LockIcon)

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const redirectRef = useRef(location)
  const { auth } = useContext(AuthContext)
  const [values, setValues] = useState({ username: '', password: '' })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  useEffect(() => {
    history.replace({ pathname: '/login' })
  }, [history])

  const handleSubmit = async e => {
    e.preventDefault()
    setError(null)
    setLoading(true)
    auth.Login(
      {
        data: { account: values.username, password: md5(md5(values.password)) }
      },
      () => {
        history.push(redirectRef.current)
      },
      data => {
        const error = _.get(data, 'error.message', 'Unknown Error')
        setError(error)
        setLoading(false)
      }
    )
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} md={8}>
          <div className={classes.left}>
            <div className='box'>
              <svg viewBox='0 0 306 359'>
                <defs>
                  <path
                    id='a'
                    d='m0 0h158c55.228-1.0145e-14 100 44.772 100 100v211h-258v-311z'
                  />
                  <filter id='b' x='-15.9%' width='131.8%' height='126.4%'>
                    <feOffset
                      dx='0'
                      dy='10'
                      in='SourceAlpha'
                      result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                      in='shadowOffsetOuter1'
                      result='shadowBlurOuter1'
                      stdDeviation='12'
                    />
                    <feComposite
                      in='shadowBlurOuter1'
                      in2='SourceAlpha'
                      operator='out'
                      result='shadowBlurOuter1'
                    />
                    <feColorMatrix
                      in='shadowBlurOuter1'
                      values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0'
                    />
                  </filter>
                </defs>
                <g fill='none' fillRule='evenodd'>
                  <g transform='translate(-265 -280)'>
                    <g transform='translate(289 294)'>
                      <use fill='black' filter='url(#b)' xlinkHref='#a' />
                      <use
                        fill='#FFFFFF'
                        fillOpacity='.5'
                        fillRule='evenodd'
                        xlinkHref='#a'
                      />
                      <path
                        d='m214 121.85c0.11282-45.478-36.66-82.501-82.288-82.85-45.628-0.34854-82.966 36.109-83.552 81.583-0.46155 5.8033-0.49706 21.232 8.308 43.596 12.604 32.088 37.67 65.33 74.481 98.819l31.187-34.063c-7.6547-6.964-16.261-15.329-24.732-24.671 43.194-3.3491 76.546-39.234 76.597-82.414zm-82.938 36.54c-14.832 0-28.203-8.9051-33.878-22.562-5.6752-13.657-2.5367-29.377 7.952-39.829 10.489-10.452 26.262-13.577 39.964-7.9178 13.702 5.659 22.634 18.987 22.631 33.77-0.023486 20.173-16.428 36.52-36.669 36.54zm24.561-36.392c1.4055 0 2.4979 1.2324 2.3664 2.6518-1.3169 14.219-13.122 25.348-27.49 25.348-14.367 0-26.173-11.129-27.488-25.348-0.13297-1.4194 0.95941-2.6518 2.3649-2.6518h50.247z'
                        fill='#EF3E35'
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4}
          direction='column'
          justify='center'
          alignItems='center'
          className={classes.right}
        >
          <Grid
            container
            item
            spacing={3}
            direction='column'
            justify='center'
            className={classes.group}
          >
            {error !== null && (
              <Grid item>
                <Alert severity='error'>{error}</Alert>
              </Grid>
            )}
            <Grid item>
              <FormControl fullWidth={true} variant='outlined'>
                <InputWithIcon
                  placeholder='User ID...'
                  value={values.username}
                  type='text'
                  autoComplete='current-username'
                  Icon={<PersonIcon color='primary' />}
                  className={classes.input}
                  onChange={handleChange('username')}
                />
                {/* <OutlinedInput
                  placeholder='User ID...'
                  value={values.username}
                  type='text'
                  autoComplete='current-username'
                  fullWidth={true}
                  startAdornment={
                    <InputAdornment position='start'>
                      <PersonIcon color='primary' />
                    </InputAdornment>
                  }
                  required={true}
                  className={classes.input}
                  onChange={handleChange('username')}
                /> */}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth={true} variant='outlined'>
                <InputWithIcon
                  placeholder='Password...'
                  value={values.password}
                  type='password'
                  autoComplete='current-password'
                  Icon={<StyledLock />}
                  className={classes.input}
                  onChange={handleChange('password')}
                />
                {/* <OutlinedInput
                  placeholder='Password...'
                  value={values.password}
                  type='password'
                  autoComplete='current-password'
                  fullWidth={true}
                  startAdornment={
                    <InputAdornment position='start'>
                      <StyledLock />
                    </InputAdornment>
                  }
                  required={true}
                  className={classes.input}
                  onChange={handleChange('password')}
                /> */}
              </FormControl>
            </Grid>
            <Grid item className={classes.btn}>
              <Button
                variant='contained'
                color='secondary'
                label='Login'
                type='submit'
                loading={loading}
              />
            </Grid>
          </Grid>
          <div className={classes.footer}>
            <Footer isLoginPage={true} />
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default Login
