import React, { useContext, useEffect } from 'react'
import _ from 'lodash'
import { ModalContext } from 'components/layouts/Modal/Context'
import { NotificationContext } from 'contexts/Notification'
import Button from 'components/blocks/Button'
import Dialog from 'components/blocks/Dialog'

export default ({ user, account , id}) => {
  const { dispatchModal } = useContext(ModalContext)
  const { notification, dispatchNotification } = useContext(NotificationContext)
  const order = _.get(notification, 'order', null)

  useEffect(() => {
    // console.log('show')
    return () => {
      // console.log('close')
      dispatchNotification({
        type: 'SET_NOTIFICATION_ORDER',
        order: order + 1
      })
      dispatchNotification({
        type: 'SET_READ_IDS',
        id
      })
    }
  }, [dispatchNotification, order, id])

  return (
    <Dialog
      style={{
        minWidth: '450px'
      }}
      message={`${user} wants to use ${account} account`}
      actions={[
        <Button
          key='button_confirm'
          color='primary'
          label='Ok'
          onClick={() => {
            dispatchModal({
              type: 'CLOSE_MODAL'
            })
          }}
        />
      ]}
    />
  )
}
