import { useContext, useCallback, useMemo } from 'react'
import _ from 'lodash'

import { AuthContext } from 'contexts/Auth'

export const usePermission = () => {
  const { auth } = useContext(AuthContext)
  const permissionCodeMap = useMemo(() => {
    const permissions = _.get(auth, 'member.permissions', [])
    return permissions
      .map(_permission => _.get(_permission, 'code', null))
      .filter(_permissionCode => _permissionCode !== null)
      .reduce((result, currentPermissionCode) => {
        result.set(currentPermissionCode, true)
        return result
      }, new Map())
  }, [auth])
  const verifyPermissions = useCallback(
    targetPermissions => {
      return targetPermissions.every(_permissionCode =>
        permissionCodeMap.get(_permissionCode)
      )
    },
    [permissionCodeMap]
  )

  return {
    auth,
    verifyPermissions
  }
}
