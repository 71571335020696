import request, { BASE_URL } from './request'

export const postAccountsLoginApi = async ({ data = {} } = { data: {} }) => {
  return request('POST', `${BASE_URL}/accounts/login`, {
    data,
    withCredentials: true,
    crossDomain: true
  })
}

export const getAccountsVerifyTokenApi = async (
  { params = {} } = { params: {} }
) => {
  return request('GET', `${BASE_URL}/accounts/verify_token`, {
    params,
    withCredentials: true,
    crossDomain: true
  })
}

export const postAccountsRefreshTokenApi = async () => {
  return request('POST', `${BASE_URL}/accounts/refresh_token`, {
    withCredentials: true,
    crossDomain: true
  })
}

export const postAccountsLogoutApi = async () => {
  return request('POST', `${BASE_URL}/accounts/logout`, {
    withCredentials: true,
    crossDomain: true
  })
}
