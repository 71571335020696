/** @type {'develop' | 'stage' | 'beta' | 'master'} */
const env = process.env.REACT_APP_ENVIRONMENT

export const BASE_URL = process.env.STORYBOOK_ENVIRONMENT
  ? 'https://gae-playsee-go-client-bi.playsee.dev'
  : env === 'develop' || env === 'stage'
  ? 'https://gae-playsee-go-client-bi.playsee.dev'
  : env === 'beta'
  ? 'https://gae-playsee-go-client-bi.playsee.one'
  : 'https://gae-playsee-go-client-bi.playsee.org'

export const RESOURCE_URL = 'http://g-pst.framy.co'

export const BI_FIREBASE_PATH_PREFIX =
  env === 'develop' || env === 'stage'
    ? 'stage/'
    : env === 'beta'
    ? 'beta/'
    : 'production/'

export const BUCKET_PLAYSEE_IN_HOUSE = 'playsee-in-house'

export const BI_IMAGE_STORAGE_URL = `https://storage.googleapis.com/${BUCKET_PLAYSEE_IN_HOUSE}/${BI_FIREBASE_PATH_PREFIX}`

export const PRIVATE_HOST_ENDPOINT = 'bi-tool.default.example.com'
