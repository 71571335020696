import React, { createContext, useReducer, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { LayoutReducer } from './Reducer'

export const LayoutContext = createContext()
LayoutContext.displayName = 'Layout'

export const LayoutContextProvider = props => {
  const isMobile = useMediaQuery('(max-width: 799px)')

  useEffect(() => {
    return () => {}
  }, [])

  const [layout, dispatchLayout] = useReducer(
    LayoutReducer,
    {
      isMenuExtended: true
    },
    state => {
      return state
    }
  )

  return (
    <LayoutContext.Provider
      value={{
        layout: isMobile ? { isMenuExtended: false } : layout,
        dispatchLayout
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}
