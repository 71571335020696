import { useCallback } from 'react'
import { useLocker } from 'contexts/Locker'
import { toCSV, downloadCSV, formatCSVData } from 'helpers/CSVConvert'

const useCSVData = () => {
  const { getter } = useLocker()

  const makeTableData = useCallback(
    async (data, makeTableDataFunc) => {
      return await makeTableDataFunc(data, getter)
    },
    [getter]
  )

  const downloadCsvData = useCallback((fileName, csvData) => {
    downloadCSV(fileName, toCSV(csvData))
  }, [])

  return {
    makeTableData,
    convertTableDataToCsvData: formatCSVData,
    downloadCsvData
  }
}

export default useCSVData
