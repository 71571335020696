import React, { useState, useCallback, useEffect, memo } from 'react'
import Input from 'components/blocks/TextInput'
import Button from 'components/blocks/Button'
import Dropdown from 'components/blocks/Dropdown'
import BetweenConditionInput from 'components/blocks/BetweenConditionInput'
import { checkInputNumber } from '../../../../../helper/potentialPayingUserAction'
import { QUERY_TYPES, COMPARISION_OPERATORS } from 'config/constansts'
import cx from 'clsx'
import _ from 'lodash'

const UserRange = memo(
  ({ state, setValue, optionsWithOut, disabled }) => {
    const isPercent = !optionsWithOut === 'percent'
    const statement = ['', isPercent ? '' : '%　~　', isPercent ? '' : '%']
    const [range, setRange] = useState({
      max: state.query_type.userRange.param.max,
      min: state.query_type.userRange.param.min
    })

    const firstInputData = {
      value: state.query_type.userRange.param.min,
      setValue: value => setRange(range => ({ ...range, min: value })),
      min: 0,
      max: Infinity
    }

    const secondInputData = {
      value: state.query_type.userRange.param.max,
      setValue: value => setRange(range => ({ ...range, max: value })),
      min: 0,
      max: Infinity
    }

    const isLoaded = state.hasOwnProperty('id')

    useEffect(() => {
      if (isLoaded) {
        setRange(state.query_type.userRange.param)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded])

    useEffect(() => {
      if (!_.isEqual(state.query_type.userRange.param, range)) {
        setValue('query_type', {
          ...state.query_type,
          userRange: {
            ...state.query_type.userRange,
            param: range
          }
        })
      }
    }, [range, setValue, state.query_type])

    return (
      <BetweenConditionInput
        {...{ statement, firstInputData, secondInputData, width: 50, disabled }}
      />
    )
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

const CountOrPercent = memo(
  ({ state, setValue, classes, isFirstDropdownNoMargin, disabled }) => {
    const [openOptionalOptions, setOpenOptionalOptions] = useState(false)
    const type = state.query_type.value
    const isCountOrPercent = type.includes('count') ? 'count' : 'percent'

    const setInputData = (key, name, value, valueIsNotNumber) => {
      setValue('query_type', {
        ...state.query_type,
        [key]: {
          ...state.query_type[key],
          [name]: valueIsNotNumber
            ? value
            : checkInputNumber(key, value, state.query_type[key][name])
        }
      })
    }

    const triggerOptionalOptions = useCallback(() => {
      if (openOptionalOptions) {
        setValue('query_type', {
          ...state.query_type,
          [`${isCountOrPercent}2`]: {
            operator: '',
            param: ''
          }
        })
      }
      setOpenOptionalOptions(value => !value)
    }, [openOptionalOptions, setValue, isCountOrPercent, state.query_type])

    useEffect(() => {
      const type = state.query_type[`${isCountOrPercent}2`]
      if (type.operator || type.param) {
        setOpenOptionalOptions(true)
      }
    }, [isCountOrPercent, state.query_type])

    return (
      <>
        <Dropdown
          {...{
            options: COMPARISION_OPERATORS,
            value: state.query_type[`${isCountOrPercent}1`].operator,
            setValue: value =>
              setInputData(`${isCountOrPercent}1`, 'operator', value, true),
            width: 80,
            ...(isFirstDropdownNoMargin
              ? { wrapperStyle: { marginLeft: '0' } }
              : {}),
            disabled
          }}
        />
        <Input
          value={state.query_type[`${isCountOrPercent}1`].param}
          setValue={value =>
            setInputData(`${isCountOrPercent}1`, 'param', value)
          }
          width={57}
          style={{ margin: '0 8px' }}
          disabled={disabled}
        />
        {isCountOrPercent === 'percent' && (
          <span className={cx(`${classes.text} ${classes.marginR10}`)}>%</span>
        )}
        <Button
          key='button_and'
          variant='outlined'
          color='primary'
          label='and'
          onClick={triggerOptionalOptions}
          disabled={disabled}
        />
        {openOptionalOptions && (
          <>
            <Dropdown
              options={COMPARISION_OPERATORS}
              value={state.query_type[`${isCountOrPercent}2`].operator}
              setValue={value =>
                setInputData(`${isCountOrPercent}2`, 'operator', value, true)
              }
              width={80}
              disabled={disabled}
            />
            <Input
              value={state.query_type[`${isCountOrPercent}2`].param}
              setValue={value =>
                setInputData(`${isCountOrPercent}2`, 'param', value)
              }
              width={57}
              style={{ margin: '0 8px' }}
              disabled={disabled}
            />
            {isCountOrPercent === 'percent' && (
              <span className={classes.text}>%</span>
            )}
          </>
        )}
      </>
    )
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

const UserRangeWithCountOrPercent = memo(
  ({ state, setValue, optionsWithOut, classes, disabled }) => {
    const setter = useCallback(
      (name, value) => {
        const emptyCountOrPercent =
          optionsWithOut === 'count' ? 'percent' : 'count'
        const emptyUserRange = {
          userRange: {
            ...state.query_type.userRange,
            param: {
              max: '',
              min: ''
            }
          }
        }

        const emptyCountOrPercentGroup = {
          [`${emptyCountOrPercent}1`]: {
            ...state.query_type[`${emptyCountOrPercent}1`],
            operator: '',
            param: ''
          },
          [`${emptyCountOrPercent}2`]: {
            ...state.query_type[`${emptyCountOrPercent}2`],
            operator: '',
            param: ''
          }
        }

        if (value) {
          const isUserRange = value === 'userRange'
          setValue('query_type', {
            ...state.query_type,
            [name]: value,
            ...(isUserRange ? emptyCountOrPercentGroup : emptyUserRange)
          })
        } else {
          setValue('query_type', {
            ...state.query_type,
            value: '',
            ...emptyUserRange,
            ...emptyCountOrPercentGroup
          })
        }
      },
      [optionsWithOut, setValue, state.query_type]
    )
    return (
      <>
        <Dropdown
          options={QUERY_TYPES.filter(
            item => !item.value.includes(optionsWithOut)
          )}
          value={state.query_type.value}
          setValue={value => setter('value', value)}
          width={150}
          disabled={disabled}
        />
        {state.query_type.value === 'userRange' && (
          <UserRange {...{ state, setValue, optionsWithOut, disabled }} />
        )}
        {(state.query_type.value === 'percent1' ||
          state.query_type.value === 'count1') && (
          <CountOrPercent {...{ state, setValue, classes, disabled }} />
        )}
      </>
    )
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

export default UserRangeWithCountOrPercent
