
import React from 'react'

import { PATH_BIZ_REVIEW } from 'constant'

import { tabs as dmRoutes } from 'projects/DM/config/Routes'

import { tabs as accountMgtRoutes } from 'projects/AccountMgt/config/Routes'

import { Finance, tabs as financeRoutes } from 'projects/Finance/config/Routes'
import { RouteType } from 'types/route'
import {
    ToolsIcon,
} from 'config/Routes/Icons'


const DM = React.lazy(() => import('projects/DM'))

const GGPool = React.lazy(() => import('projects/GGPool'))
const AccountMgt = React.lazy(() => import('projects/AccountMgt'))

const BizReview = React.lazy(() => import('projects/BizReview'))

const ReviewVideoStage1 = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewVideoStage1')
)

const ReviewVideoCommentStage1 = React.lazy(
    () => import('projects/ReviewText/components/pages/ReviewVideoCommentStage1')
)

const ReviewCMimageStage1 = React.lazy(
    () => import('projects/ReviewImage/pages/CMstage1/index')
)

const ReviewCMimageStage2 = React.lazy(
    () => import('projects/ReviewImage/pages/CMstage2/index')
)
const ReviewVideoStage2 = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewVideoStage2')
)
const ReviewCMVideoStage1 = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewCMVideoStage1/index')
)

const ReviewCMVideoStage2 = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewCMVideoStage2/index')
)

const ReviewCMurlStage1 = React.lazy(
    () => import('projects/ReviewLink/pages/CMstage1/index')
)


const ReviewAds = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewAds')
)

const ReviewStaticAds = React.lazy(
  () => import('projects/ReviewVideo/components/pages/ReviewStaticAds')
)

const ReviewCommunityAds = React.lazy(
  () => import('projects/ReviewVideo/components/pages/ReviewCommunityAdsTextAndInterest')
)

const ReviewImageAds = React.lazy(
  () => import('projects/ReviewVideo/components/pages/ReviewImageAdsTextAndInterest')
)

// const ReviewImageAds = React.lazy(
//   () => import('projects/ReviewVideo/components/pages/ReviewImageAdsTextAndInterest')
// )

// Temporarily hide for review ads merge to review video
// const BAReviewVideoStage1 = React.lazy(
//   () => import('projects/ReviewVideo/components/pages/BAReviewVideoStage1')
// )

// const BAReviewVideoStage2 = React.lazy(
//   () => import('projects/ReviewVideo/components/pages/BAReviewVideoStage2')
// )



const ReviewAdsCover = React.lazy(
    () => import('projects/ReviewVideo/components/pages/ReviewAdsCover')
)

const ReviewQuestionStage1 = React.lazy(
    () => import('projects/ReviewText/components/pages/ReviewQuestionStage1')
)

const ReviewCommunityStage1 = React.lazy(
    () => import('projects/ReviewCommunity/components/pages/ReviewCommunityStage1')
)

// const ReviewCommentStage1 = React.lazy(
//   () => import('projects/ReviewText/components/pages/ReviewCommentStage1')
// )
// const ReviewCommentStage2 = React.lazy(
//   () => import('projects/ReviewText/components/pages/ReviewCommentStage2')
// )

const TermsEditor = React.lazy(
    () => import('projects/TermsEditor/components/pages/EditTerms')
)

const ReviewProfileHeadShot = React.lazy(
    () => import('projects/ReviewProfile/components/pages/ReviewProfileHeadshot')
)

const tools: RouteType[] = [

    {
        key: 'dm',
        path: '/playsee-messaging',
        title: 'Playsee Messaging',
        permissions: ['playsee-messaging'],
        icon: ToolsIcon,
        component: DM,
        exact: true,
        subRoutes: dmRoutes
    },

    {
        key: 'ggpool',
        path: '/gin-gin-pool',
        title: 'Gin Gin Pool',
        permissions: ['gin-gin-pool'],
        icon: ToolsIcon,
        component: GGPool,
        exact: true,
        subRoutes: []
    },
    {
        key: 'account-mgt',
        path: '/account-mgt',
        title: 'Account Management',
        permissions: ['account-management'],
        icon: ToolsIcon,
        component: AccountMgt,
        exact: true,
        subRoutes: accountMgtRoutes
    },
    // Temporarily hide for review ads merge to review video
    // {
    //   key: 'review-ads.stage-1',
    //   path: '/review-ads-stage-1',
    //   title: [
    //     {
    //       text: 'Review Ads'
    //     },
    //     {
    //       text: 'Stage 1',
    //       style: {
    //         fontWeight: 400,
    //         color: '#9e9e9e'
    //       }
    //     }
    //   ],
    //   permissions: ['review-ads.stage-1'],
    //   icon: ToolsIcon,
    //   component: BAReviewVideoStage1,
    //   exact: true,
    //   subRoutes: []
    // },
    // {
    //   key: 'review-ads.stage-2',
    //   path: '/review-ads-stage-2',
    //   title: [
    //     {
    //       text: 'Review Ads'
    //     },
    //     {
    //       text: 'Stage 2',
    //       style: {
    //         fontWeight: 400,
    //         color: '#9e9e9e'
    //       }
    //     }
    //   ],
    //   permissions: ['review-ads.stage-2'],
    //   icon: ToolsIcon,
    //   component: BAReviewVideoStage2,
    //   exact: true,
    //   subRoutes: []
    // },
    {
        key: 'review-video-comment-stage-1',
        path: '/review-video-comment-stage-1',
        title: [
            {
                text: 'Review Video Comment'
            },
            {
                text: 'Stage 1',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-video-comment.stage-1'],
        icon: ToolsIcon,
        component: ReviewVideoCommentStage1,
        exact: true,
        subRoutes: []
    },
    {
        key: 'review-video-stage-1',
        path: '/review-video-stage-1',
        title: [
            {
                text: 'Review Video'
            },
            {
                text: 'Stage 1',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-video.stage-1'],
        icon: ToolsIcon,
        component: ReviewVideoStage1,
        exact: true,
        subRoutes: []
    },
    {
        key: 'review-cm-image-stage-1',
        path: '/review-cm-image-stage-1',
        title: [
            {
                text: 'Review CM Image'
            },
            {
                text: 'Stage 1',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-comment-media-image.stage-1'],
        icon: ToolsIcon,
        component: ReviewCMimageStage1,
        exact: true,
        subRoutes: []
    },
    {
        key: 'review-cm-video-stage-1',
        path: '/review-cm-video-stage-1',
        title: [
            {
                text: 'Review CM Video'
            },
            {
                text: 'Stage 1',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-comment-media-video.stage-1'],
        icon: ToolsIcon,
        component: ReviewCMVideoStage1,
        exact: true,
        subRoutes: []
    },
    {
        key: 'review-cm-video-stage-2',
        path: '/review-cm-video-stage-2',
        title: [
            {
                text: 'Review CM Video'
            },
            {
                text: 'Stage 2',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-comment-media-video.stage-2'],
        icon: ToolsIcon,
        component: ReviewCMVideoStage2,
        exact: true,
        subRoutes: []
    },
    {
        key: 'review-cm-image-stage-2',
        path: '/review-cm-image-stage-2',
        title: [
            {
                text: 'Review CM Image'
            },
            {
                text: 'Stage 2',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-comment-media-image.stage-2'],
        icon: ToolsIcon,
        component: ReviewCMimageStage2,
        exact: true,
        subRoutes: []
    },


    {
        key: 'review-cm-link-stage-1',
        path: '/review-cm-link-stage-1',
        title: [
            {
                text: 'Review CM Link'
            },
            {
                text: 'Stage 1',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-comment-media-link.stage-1'],
        icon: ToolsIcon,
        component: ReviewCMurlStage1,
        exact: true,
        subRoutes: []
    },

    {
        key: 'review-video-stage-2',
        path: '/review-video-stage-2',
        title: [
            {
                text: 'Review Video'
            },
            {
                text: 'Stage 2',
                style: {
                    fontWeight: 400,
                    color: '#9e9e9e'
                }
            }
        ],
        permissions: ['review-video.stage-2'],
        icon: ToolsIcon,
        component: ReviewVideoStage2,
        exact: true,
        subRoutes: []
    },

    {
        key: 'review-ads-video',
        path: '/review-ads-video',
        title: 'Review Ads Video Text & Interest',
        permissions: ['review-ads.video'],
        icon: ToolsIcon,
        component: ReviewAds,
        exact: true,
        subRoutes: []
    },

    {
        key: 'review-ads-cover',
        path: '/review-ads-cover',
        title: 'Review Ads Cover',
        permissions: ['review-ads.cover'],
        icon: ToolsIcon,
        component: ReviewAdsCover,
        exact: true,
        subRoutes: []
    },
    //原本的Question
    {
        key: 'review-comment-stage-1',
        path: '/review-comment-stage-1',
        title: 'Review Comment Stage 1',
        permissions: ['review-comment.stage-1'],
        icon: ToolsIcon,
        component: ReviewQuestionStage1,
        exact: true,
        subRoutes: []
    },

    {
        key: 'review-community-stage-1',
        path: '/review-community-stage-1',
        title: 'Review community Stage 1',
        permissions: ['review-community.stage-1'],
        icon: ToolsIcon,
        component: ReviewCommunityStage1,
        exact: true,
        subRoutes: []
    },

    {
        key: 'terms_editor',
        path: '/terms_editor',
        title: 'Terms Editor',
        permissions: ['terms_editor'],
        icon: ToolsIcon,
        component: TermsEditor,
        exact: true,
        subRoutes: []
    },
    {
        key: 'financial',
        path: '/financial',
        title: 'Financial',
        permissions: [],
        icon: ToolsIcon,
        component: Finance,
        exact: true,
        subRoutes: financeRoutes
    },
    {
        key: PATH_BIZ_REVIEW,
        path: `/${PATH_BIZ_REVIEW}`,
        title: 'Biz Review',
        permissions: ['review-biz'],
        icon: ToolsIcon,
        component: BizReview,
        exact: true,
        subRoutes: []
    },
    {
        key: 'profile-headshot-review',
        path: '/profile-review',
        title: 'Profile Review',
        permissions: ['review-profile'],
        icon: ToolsIcon,
        component: ReviewProfileHeadShot,
        exact: true,
        subRoutes: []
    },// TDO 等新的ＡＰＩ來之後 permission要改
    {
      key: 'review-text-ads-text-and-interest',
      
      path: '/review-text-ads-text-and-interest',
      title: [
          {
              text: 'Review Text Ads Text & Interest'
          }
      ],
      permissions: ['review-comment-media-link.stage-1'],
      icon: ToolsIcon,
      component: ReviewStaticAds,
      exact: true,
      subRoutes: []
  },
  {
    key: 'review-community-ads-text-and-interest',
    
    path: '/review-community-ads-text-and-interest',
    title: [
        {
            text: 'Review Community Ads Text & Interest'
        }
    ],
    permissions: ['review-comment-media-link.stage-1'],
    icon: ToolsIcon,
    component: ReviewCommunityAds,
    exact: true,
    subRoutes: []
  },
  {
    key: 'review-image-ads-text-and-interest',
    
    path: '/review-image-ads-text-and-interest',
    title: [
        {
            text: 'Review Image Ads Text & Interest'
        }
    ],
    permissions: ['review-comment-media-link.stage-1'],
    icon: ToolsIcon,
    component: ReviewImageAds,
    exact: true,
    subRoutes: []
  }
]

export default tools