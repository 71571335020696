export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet'
}

type DeviceValue = {
  key: string
  name: string
}

const code: Record<DeviceType, DeviceValue> = {
  [DeviceType.Mobile]: {
    key: 'mobile',
    name: 'Mobile'
  },
  [DeviceType.Tablet]: {
    key: 'tablet',
    name: 'Tablet'
  }
}

export default code
