import get from 'lodash/get'
import React from 'react'
import { generateRandomString } from 'helpers/General'
import MODAL_TYPES_COMPONENTS from '../types'
import MODAL_TYPE from 'config/constansts/ModalType'

export const ModalReducer = (state, action) => {
  const type = get(action, 'type', null)
  const { modals } = state
  const dialogConfig = get(action, 'dialogConfig', null)
  const getUniqueKey = () => {
    const key = generateRandomString()
    const duplicate = modals.find(modal => modal.key === key)
    if (typeof duplicate === 'undefined') {
      return key
    }
    return getUniqueKey()
  }

  if (type === 'OPEN_MODAL') {
    const content = get(action, 'content', null)

    if (content !== null) {
      modals.push({
        content,
        key: getUniqueKey(),
        dialogConfig
      })
    }

    return {
      ...state,
      modals
    }
  }

  if (MODAL_TYPE.OVERVIEW.SMALL_FILTER[type]) {
    const key = getUniqueKey()
    modals.push({
      content: React.cloneElement(
        MODAL_TYPES_COMPONENTS.OVERVIEW.SMALL_FILTER[type],
        { uid: key }
      ),
      key,
      dialogConfig: {
        maxWidth: 'xl'
      },
      disable: action.disable ?? false
    })
    return {
      ...state,
      modals
    }
  }

  if (type === 'CLOSE_MODAL') {
    const key = get(action, 'key', null)
    const { modals } = state
    const targetModal =
      key !== null ? modals.find(modal => modal.key === key) : undefined

    if (typeof targetModal !== 'undefined') {
      //const index = modals.indexOf(targetModal)
    } else {
      modals.pop()
    }
    return {
      ...state,
      modals
    }
  }

  return state
}
