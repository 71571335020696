import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '220px'
  }
}))

export default props => {
  const { title = null, message = null, actions = null, style = null } = props

  const classes = useStyles()

  return (
    <div className={classes.root} style={style}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </div>
  )
}
