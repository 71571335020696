import React, { useContext, useEffect } from 'react'
import { NotificationContext } from 'contexts/Notification'
import { ModalContext } from 'components/layouts/Modal/Context'
import RequestAccountInUse from './Modals/RequestAccountInUse'
import { postNotificationReadApi } from 'apis/notification'

const GeneralNotification = () => {
  const { notification, dispatchNotification } = useContext(NotificationContext)
  const { dispatchModal } = useContext(ModalContext)
  const { data, order, readIds } = notification

  useEffect(() => {
    if (
      order !== null &&
      order < data.length
    ) {
      const user = data[order].data.params[0]
      const account = data[order].data.params[1]
      const id = data[order].notification_id
      dispatchModal({
        type: 'OPEN_MODAL',
        content: (
          <RequestAccountInUse {...{ user, account, id }} />
        )
      })
    } else if(order === data.length){
      const postRead = async () => {
        const response = await postNotificationReadApi({
          data: {
            data: {
              notification_ids: readIds
            }
          }
        })
        dispatchNotification({
          type: 'SET_NOTIFICATION_ORDER',
          order: null
        })
        console.log(response)
      }
      postRead()
    }
  }, [dispatchModal, notification, data, dispatchNotification, order, readIds])

  return null
}

export default GeneralNotification