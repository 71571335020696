import { Subject } from 'rxjs'
import get from 'lodash/get'
import { generateRandomString } from 'helpers/General'

const listeners = {}

const configDataSubject = new Subject()

export const configDataService = {
  giveConfigData: ({ value = null, key = null }) => {
    configDataSubject.next({
      action: 'config_data_give',
      value,
      key
    })
  },
  getConfigData: (key = null) => {
    configDataSubject.next({
      action: 'config_data_get',
      key
    })
  },
  listener: () => configDataSubject.asObservable()
}

export const getConfigData = setKey =>
  new Promise((resolved, rejected) => {
    const id = generateRandomString()
    listeners[id] = configDataService.listener().subscribe(event => {
      const action = get(event, 'action', null)
      if (action === 'config_data_give') {
        const key = get(event, 'key', null)
        if (key === setKey) {
          if (listeners.hasOwnProperty(id)) {
            listeners[id].unsubscribe()
          }
          resolved(get(event, 'value', null))
        }
      }
    })
    setTimeout(() => {
      if (listeners.hasOwnProperty(id)) {
        listeners[id].unsubscribe()
      }
      resolved(null)
    }, 200)

    configDataService.getConfigData(setKey)
  })
