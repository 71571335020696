import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { getConfigData } from 'services/Config'
import packageJson from '../../../../package.json'
const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '28px 32px',
    boxSizing: 'border-box',
    justifyContent: props => (props.isLoginPage ? 'center' : 'space-between'),
    alignItems: 'center'
  },
  copyRight: {
    fontSize: '12px',
    color: theme.custom.colors.gray2,
    fontWeight: 300
  },
  version: {
    fontSize: '12px',
    color: theme.custom.colors.black1,
    fontWeight: 300,
    marginLeft: '8px'
  }
}))

const Footer = React.forwardRef(
  ({ style = null, isLoginPage = false }, ref) => {
    const classes = useStyle({ isLoginPage })
    const [biServerVersion, setBiServerVersion] = useState(null)
    const { version: biFrontEndVersion } = packageJson
    const biFrontEndCommitHash = process.env.REACT_APP_COMMIT_HASH

    useEffect(() => {
      if (biServerVersion === null) {
        ;(async () => {
          const _biServerVersion = await getConfigData(
            'server_versions.bi_server'
          )
          setBiServerVersion(_biServerVersion)
        })()
      }
    }, [biServerVersion])

    return (
      <div className={classes.root} style={style} ref={ref}>
        <span className={classes.copyRight}>
          @{new Date().getFullYear()} playsee
        </span>
        <div>
          {biServerVersion !== null && (
            <div className={classes.version}>
              BI Server Ver: {biServerVersion}
            </div>
          )}
          {biFrontEndCommitHash && (
            <div className={classes.version}>
              BI Front-End Ver: {biFrontEndVersion} (#{biFrontEndCommitHash})
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default Footer
