import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  memo
} from 'react'
import cx from 'clsx'
import Input from 'components/blocks/TextInput'
import DropdownSelector from 'components/blocks/DropdownSelector'
import UserRangeWithCountOrPercent from '../blocks/UserRangeWithCountOrPercent'
import { checkInputNumber } from '../../../../helper/potentialPayingUserAction'
import _ from 'lodash'
import { ENGAGEMENT_RATE_OPTIONS } from 'config/constansts'

const optionsWithOut = 'count'

const EngagementRateGive = memo(
  forwardRef(({ classes, defaultValue, fetchedData, disabled }, ref) => {
    const [state, setState] = useState(defaultValue)
    const setValue = useCallback((name, value) => {
      setState(pre => ({
        ...pre,
        [name]: checkInputNumber(name, value, pre[name])
      }))
    }, [])

    useEffect(() => {
      if (fetchedData) setState(fetchedData)
    }, [fetchedData])

    useImperativeHandle(ref, () => state, [state])

    return (
      <div className={classes.row}>
        <div className={`${classes.filter} ${classes.flexStart}`}>
          <span
            className={cx(
              'checkbox',
              state.is_default && 'selected',
              disabled && 'disabled'
            )}
            onClick={() =>
              !disabled && setValue('is_default', !state.is_default)
            }
          >
            <span></span>
          </span>
          <div className='row-content row-next-line'>
            <span className={classes.text}>Engagement Rate (Give):</span>
            <div className='row-content-center'>
              <span className={classes.text}>Within</span>
              <Input
                value={state.dayCount}
                setValue={value => setValue('dayCount', value)}
                width={57}
                style={{ margin: '0 8px' }}
                disabled={disabled}
              />
              <span className={classes.text}>days,</span>
              <DropdownSelector
                style={{
                  display: 'inline-block',
                  margin: '0px 8px',
                  width: 200
                }}
                value={state.engagementType}
                onChange={value => {
                  setValue('engagementType', value)
                }}
                defaultValue={[ENGAGEMENT_RATE_OPTIONS[0]]}
                options={ENGAGEMENT_RATE_OPTIONS}
                allowSelectAll={true}
                disabled={disabled}
              />
              <span className={classes.text}>engagement rate</span>
              <UserRangeWithCountOrPercent
                {...{ state, setValue, optionsWithOut, classes, disabled }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }),
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

export default EngagementRateGive
