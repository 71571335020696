import React, {
  cloneElement,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import { ModalContext } from 'components/layouts/Modal/Context'
import { AuthContext } from 'contexts/Auth'
import { SmallFilterContext } from 'contexts/SmallFilter'
import Button from 'components/blocks/Button'
import LoadingContainer from 'components/blocks/LoadingContainer'
import {
  SMALL_FILTER_EMPTY_VALUE,
  SMALL_FILTER_DEFAULT_VALUE
} from 'config/constansts/SmallFilter'
import MODAL_TYPE from 'config/constansts/ModalType'
import {
  getSmallFilterDetailApi,
  postSmallFilterUpdateApi
} from 'apis/smallFilter'
import FormatSendingData, {
  insertGraphTypeToPotentialPayingUser
} from '../../helper/formatSendingData'
import FormatFetchedData from '../../helper/formatFetchedData'
import { Dashboard } from 'types/chartEnum'

const useStyle = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    width: ({ width }) => width,
    height: ({ height }) => height,
    padding: '16px 16px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .radioButton': {
      '& > span': {
        borderRadius: '50%'
      },
      '&.selected > span': {
        '&:after': {
          borderRadius: '50%'
        }
      }
    },
    '& .checkbox, & .radioButton': {
      marginRight: '8px',
      lineHeight: '13px',
      '&.loading': {
        opacity: 0.5,
        pointerEvents: 'none'
      },
      '& > span': {
        display: 'inline-block',
        width: '13px',
        height: '13px',
        margin: '3px',
        padding: '2px',
        borderRadius: '2px',
        cursor: 'pointer'
      },
      '&.selected > span': {
        position: 'relative',
        border: `1px solid ${theme.custom.colors.blue1}`,
        '&:after': {
          position: 'absolute',
          display: 'inline-block',
          content: '""',
          width: '13px',
          height: '13px',
          borderRadius: '1px',
          background: theme.custom.colors.blue1
        }
      },
      '&:not(.selected) > span': {
        border: `1px solid ${theme.custom.colors.gray2}`
      },
      '&.active.selected > span:hover:after': {
        background: theme.custom.colors.blue2
      },
      '&.active:not(.selected) > span:hover': {
        border: `1px solid ${theme.custom.colors.blue1}`
      },
      '&.disabled > span': {
        border: `1px solid #e0e0e0`,
        pointerEvents: 'none'
      },
      '&.selected.disabled > span': {
        '&:after': {
          background: '#e0e0e0'
        }
      }
    }
  },
  category: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px'
  },
  title: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    marginBottom: '8px'
  },
  filters: {
    flex: '1 0 auto',
    height: 'calc(100% - 24px - 24px - 56px - 32px)',
    overflowY: 'auto'
  },
  buttonGroup: {
    marginTop: '8px'
  },
  filter: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    lineHeight: '24px'
  },
  row: {
    margin: '15px 0px',
    '& .row-content': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    '& .row-next-line': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& .row-content-center': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  marginL10: {
    marginLeft: '10px'
  },
  marginR10: {
    marginRight: '10px'
  },
  marginL0: {
    marginLeft: '0px'
  },
  marginR0: {
    marginRight: '0px'
  },
  flexStart: {
    alignItems: 'flex-start'
  }
}))

const SmallFilter = ({ uid, children, type, width = 600, height = 400 }) => {
  const { Overview } = Dashboard
  const convertedType = MODAL_TYPE.OVERVIEW.SMALL_FILTER[type]
  const potential_paying_user =
    MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.PotentialPayingUser]
  const isPotentialPayingUser = convertedType === potential_paying_user
  const classes = useStyle({ width, height })
  const { auth } = useContext(AuthContext)
  const { modal, dispatchModal } = useContext(ModalContext)
  const { setSmallFilterIdsMap } = useContext(SmallFilterContext)
  const [loading, setLoading] = useState(true)
  const [isAllFilled, setAllFilled] = useState(isPotentialPayingUser)
  const [fetchedData, setFetchedData] = useState(
    isPotentialPayingUser ? {} : []
  )

  const childRef = useRef({ [convertedType]: isPotentialPayingUser ? {} : [] })
  const this_modal = modal.modals.find(item => item.key === uid)

  const getData = useCallback(async () => {
    if (auth.isLoggedIn) {
      setLoading(true)
      const { data } = await getSmallFilterDetailApi({
        params: { filter_type: 'overview', graph_type_id: convertedType }
      })
      setFetchedData(FormatFetchedData(data?.filters ?? [], convertedType))
      setLoading(false)
    }
  }, [auth, convertedType])

  const handleClose = () => {
    dispatchModal({ type: 'CLOSE_MODAL' })
  }

  const handleSubmit = async () => {
    setLoading(true)

    let originData = childRef.current

    if (isPotentialPayingUser) {
      originData = {
        [potential_paying_user]: insertGraphTypeToPotentialPayingUser(
          originData[potential_paying_user],
          convertedType
        )
      }
    }

    const { data } = await postSmallFilterUpdateApi({
      data: {
        filter_type: 'overview',
        filters: FormatSendingData(originData)
      }
    })

    if (data?.filters) {
      const filters = data.filters
      setSmallFilterIdsMap(item => ({
        ...item,
        [convertedType]: {
          ...item[convertedType],
          reload: true,
          ...(filters[convertedType] ? { id: filters[convertedType] } : {})
        }
      }))
    }

    setLoading(false)
    handleClose()
  }

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <LoadingContainer loading={loading}>
      <div className={classes.root}>
        {cloneElement(children, {
          ref: childRef,
          classes,
          setAllFilled,
          setLoading,
          fetchedData,
          emptyValue: _.cloneDeep(SMALL_FILTER_EMPTY_VALUE[convertedType]),
          defaultValue: _.cloneDeep(SMALL_FILTER_DEFAULT_VALUE[convertedType]),
          disabled: this_modal.disable
        })}
        <DialogActions>
          <Button key='button_cancel' label='Cancel' onClick={handleClose} />
          {!this_modal.disable && (
            <Button
              disabled={!isAllFilled}
              key='button_agree'
              color='primary'
              label='Apply'
              onClick={handleSubmit}
            />
          )}
        </DialogActions>
      </div>
    </LoadingContainer>
  )
}

export default SmallFilter
