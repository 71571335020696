import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useRef
} from 'react'
import { from } from 'rxjs'
import _ from 'lodash'
import { NotificationReducer } from './Reducer'
import { AuthContext } from 'contexts/Auth'
import { getNotificationListApi } from 'apis/notification'

export const NotificationContext = createContext()
NotificationContext.displayName = 'Notification'

export const NotificationContextProvider = props => {
  const { auth } = useContext(AuthContext)
  const [notification, dispatchNotification] = useReducer(
    NotificationReducer,
    {
      data: [],
      order: null,
      readIds: []
    },
    state => state
  )
  const fetcherRef = useRef(null)

  useEffect(() => {
    if (!auth.isLoggedIn && fetcherRef.current) {
      fetcherRef.current.unsubscribe()
      fetcherRef.current = null
    }
  }, [auth.isLoggedIn])

  useEffect(() => {
    if (
      auth.isLoggedIn &&
      notification.order === null &&
      fetcherRef.current === null
    ) {
      fetcherRef.current = from(
        getNotificationListApi({ params: { classify: 'unread' } })
      ).subscribe(response => {
        const data = _.get(response, 'data', null)
        const notifications = _.get(data, 'notifications', [])
        if (notifications.length > 0) {
          dispatchNotification({
            type: 'SET_NOTIFICATION',
            data: notifications
          })
          dispatchNotification({
            type: 'SET_NOTIFICATION_ORDER',
            order: 0
          })
        }
      })
    }
  }, [auth, notification])

  return (
    <NotificationContext.Provider
      value={{ notification, dispatchNotification }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}
