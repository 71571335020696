import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import allRoutes from 'config/Routes'

import { AuthContextProvider } from 'contexts/Auth'
import { ConfigContextProvider } from 'contexts/Config'
import { DropbackContextProvider } from 'components/layouts/Dropback/Context'
import { VideoPlayerContextProvider } from 'components/layouts/VideoPlayer/Context'
import { ModalContextProvider } from 'components/layouts/Modal/Context'
import { LayoutContextProvider } from 'contexts/Layout'
import { ExportDataCatcherContextProvider } from 'contexts/ExportData'
import { BatchExportContextProvider } from 'contexts/BatchExport'
import { NotificationContextProvider } from 'contexts/Notification'
import { RouteContextProvider } from 'contexts/Route'
import { LockerContextProvider } from 'contexts/Locker'
import { SmallFilterContextProvider } from 'contexts/SmallFilter'

import AuthWrapper from 'components/layouts/AuthWrapper'
import ConfigWrapper from 'components/layouts/ConfigWrapper'
import RouteWrapper from 'components/layouts/RouteWrapper'
import ThemeWrapper from 'components/layouts/ThemeWrapper'
import Menu from 'components/layouts/Menu'
import Content from 'components/layouts/Content'
import Dropback from 'components/layouts/Dropback'
import VideoPlayer from 'components/layouts/VideoPlayer'
import Modal from 'components/layouts/Modal'
import JobList from 'components/layouts/JobList'
import GeneralNotification from 'components/layouts/GeneralNotification/'

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}))

const App = () => {
  const classes = useStyle()
  return (
    <LockerContextProvider>
      <ExportDataCatcherContextProvider>
        <AuthContextProvider>
          <ConfigContextProvider>
            <NotificationContextProvider>
              <BatchExportContextProvider>
                <LayoutContextProvider>
                  <Router>
                    <SmallFilterContextProvider>
                      <ModalContextProvider>
                        <DropbackContextProvider>
                          <VideoPlayerContextProvider>
                            <ThemeWrapper>
                              <AuthWrapper>
                                <div classes={classes.root}>
                                  <ConfigWrapper>
                                    <RouteWrapper routes={allRoutes}>
                                      {({ routes, routeIndex, menuRoutes }) => (
                                        <RouteContextProvider
                                          route={routes[routeIndex]}
                                          routes={routes}
                                        >
                                          <Menu routes={menuRoutes} />
                                          <Content />
                                        </RouteContextProvider>
                                      )}
                                    </RouteWrapper>
                                  </ConfigWrapper>
                                </div>
                                <JobList />
                                <VideoPlayer />
                                <Dropback />
                                <Modal />
                                <GeneralNotification />
                              </AuthWrapper>
                            </ThemeWrapper>
                          </VideoPlayerContextProvider>
                        </DropbackContextProvider>
                      </ModalContextProvider>
                    </SmallFilterContextProvider>
                  </Router>
                </LayoutContextProvider>
              </BatchExportContextProvider>
            </NotificationContextProvider>
          </ConfigContextProvider>
        </AuthContextProvider>
      </ExportDataCatcherContextProvider>
    </LockerContextProvider>
  )
}

export default App
