import get from 'lodash/get'

export const VideoPlayerReducer = (state, action) => {
  const type = get(action, 'type', null)
  if (type === 'OPEN_VIDEO') {
    const url = get(action, 'url', null)
    const onClose = get(action, 'onClose', null)

    return {
      ...state,
      url,
      onClose: typeof onClose === 'function' ? onClose : null
    }
  }
  if (type === 'CLOSE_VIDEO') {
    return {
      ...state,
      url: null,
      onClose: null
    }
  }
  return state
}
