import React, {
  useEffect,
  useContext,
  createContext,
  useReducer,
  useRef,
  useState
} from 'react'
import { from, zip, Subscription, Observable } from 'rxjs'
import _ from 'lodash'
import { ExportDataReducer, ExportDataReducerAction } from './Reducer'

import { addBatchJobService, BatchJob } from 'contexts/BatchExport'
import { AuthContext } from 'contexts/Auth'
import { generateRandomString } from 'helpers/General'
import { ApiResponse } from 'apis/request'
import { Filter, MakeTableDataFunctionType, DateRangeType, ExportFilterApiType, ExportRuleType } from 'types/export'

interface ExportDataContextType {
  exportData: {
    fileName: string
    rules: ExportRuleType[]
    data: any
    shouldLockAllFilterOption: boolean
  }
  batchExport: (batchExportConfig: BatchExportConfigType) => void
  batchSingleExport: (batchExportConfig: SingleBatchExportConfigType) => void
  batchExportByArray: (
    arr: BatchExportConfigType[],
    filterRange?: Function
  ) => void
  dispatchExportData: React.Dispatch<ExportDataReducerAction>
}

export const ExportDataContext = createContext<ExportDataContextType>({
  exportData: {
    fileName: '',
    rules: [],
    data: {},
    shouldLockAllFilterOption: false
  },
  batchExport: () => {},
  batchSingleExport: () => {},
  batchExportByArray: () => {},
  dispatchExportData: () => {}
})
ExportDataContext.displayName = 'ExportData'

interface ExportDataContextProviderPropType {
  rules: ExportRuleType[];
  fileName: string;
}

interface SingleBatchExportConfigType {
  fileName: string
  filter?: Filter
  dateRanges?: DateRangeType
  result: any
  rules: ExportRuleType[]
  makeTableDataFunc: MakeTableDataFunctionType
}

export interface BatchExportConfigType {
  makeTableDataFunc: MakeTableDataFunctionType
  totalItems?: number
  numberOfEachBatch?: number
  apis:
    | { [key: string]: ExportFilterApiType }
    | (() => { [key: string]: ExportFilterApiType }) // TODO: define apis type?
  fileName: string
  filter: Filter
  dateRanges?: DateRangeType
  page?: number
  filterRange?: Function
  displayRangeChartKey?: string
}

export const ExportDataContextProvider: React.FC<ExportDataContextProviderPropType> = props => {
  const { rules, fileName = 'BI Report' } = props
  const { auth } = useContext(AuthContext)
  const [shouldLockAllFilterOption, setShouldLockAllFilterOption] = useState(
    false
  )
  const [exportData, dispatchExportData] = useReducer(ExportDataReducer, {
    fileName,
    rules,
    data: {}
  })
  const apiFetcher = useRef<Subscription | null>(null)

  const getApiOptions = (api: ExportFilterApiType, variables: { offset: number }) => {
    const apiOptionsShouldHave: string[] = _.get(api, 'apiOptionsShouldHave', [])
    const apiOptions = { ..._.get(api, 'apiOptions', {}) }

    if (apiOptionsShouldHave.indexOf('offset') >= 0) {
      apiOptions.params = {
        ..._.get(apiOptions, 'params', {}),
        offset: _.get(variables, 'offset', 0)
      }
    }

    if (apiOptionsShouldHave.indexOf('size') >= 0) {
      apiOptions.params = {
        ..._.get(apiOptions, 'params', {}),
        size: _.get(apiOptions, 'params.size', 2000)
      }
    }

    return apiOptions
  }

  const batchSingleExport = (batchExportConfig: SingleBatchExportConfigType) => {
    const {
      fileName,
      filter = null,
      dateRanges = null,
      result,
      rules,
      makeTableDataFunc
    } = batchExportConfig

    const jobList: BatchJob[] = [
      {
        key: generateRandomString(20),
        makeTableDataFunc,
        batchFileName: `${fileName}`,
        isFetching: false,
        isDownloaded: false,
        result,
        filter,
        dateRanges,
        rules
      }
    ]

    addBatchJobService.addBatchJob(jobList)
  }

  const batchExport = (
    batchExportConfig: BatchExportConfigType
  ) => {
    const {
      makeTableDataFunc,
      totalItems = null,
      numberOfEachBatch = null,
      apis,
      fileName,
      filter,
      dateRanges = null,
      filterRange,
      displayRangeChartKey
    } = batchExportConfig
    const jobList: BatchJob[] = []
    // NOTE: apis type includes function, force this variable's type to be object
    const apisAsObject = apis as {
      [key: string]: ExportFilterApiType
    }

    const filterRangeFunction = filterRange
      ? displayRangeChartKey
        ? (data: any) => filterRange(displayRangeChartKey, data)
        : filterRange
      : undefined


    if (totalItems !== null && numberOfEachBatch !== null) {
      const batchCount = Math.ceil(totalItems / numberOfEachBatch)
      const minCount = Math.floor(totalItems / numberOfEachBatch)

      for (let index = 0; index < batchCount; index++) {
        jobList.push({
          key: generateRandomString(20),
          makeTableDataFunc,
          apis: Object.keys(apisAsObject).reduce<typeof apisAsObject>(
            (newApis, key) => {
              const api = apisAsObject[key]
              newApis[key] = {
                ...api,
                apiOptions: getApiOptions(api, { offset: index })
              }
              return newApis
            },
            {}
          ),
          batchFileName:
            index < batchCount - 1
              ? `${fileName}_batch_${index * numberOfEachBatch + 1}_${
                  (index + 1) * numberOfEachBatch
                }`
              : `${fileName}_batch_${
                  minCount * numberOfEachBatch + 1
                }_${totalItems}`,
          isFetching: false,
          isDownloaded: false,
          result: null,
          filter,
          dateRanges,
          rules: Object.keys(apisAsObject).map(key => {
            return apisAsObject[key].rule
          }),
          filterRange: filterRangeFunction
        })
      }
    } else {
      jobList.push({
        key: generateRandomString(20),
        makeTableDataFunc,
        apis,
        batchFileName: `${fileName}`,
        isFetching: false,
        isDownloaded: false,
        result: null,
        filter,
        dateRanges,
        rules: Object.keys(apisAsObject).map(key => {
          return apisAsObject[key].rule
        }),
        filterRange: filterRangeFunction
      })
    }

    addBatchJobService.addBatchJob(jobList)
  }

  const batchExportByArray = (
    arr: BatchExportConfigType[],
    filterRange?: Function
  ) => {
    if (apiFetcher.current === null) {
      setShouldLockAllFilterOption(true)

      const apis = arr.reduce<Observable<ApiResponse>[]>((apisArr, apiObj) => {
        const a: ExportFilterApiType = _.get(
          Object.values(apiObj.apis),
          '[0]',
          null
        )
        if (a === null) {
          return apisArr
        } else if (!_.get(a, 'shouldCallPreFlight', true)) {
          return apisArr
        }
        const { api, apiOptions } = a

        apisArr.push(
          from(
            api({
              ...apiOptions,
              params: {
                ..._.get(apiOptions, 'params', {}),
                size: 1,
                offset: 0
              }
            })
          )
        )
        return apisArr
      }, [])

      if (apis.length === 0) {
        arr.forEach(batch => {
          setTimeout(() => {
            batchExport({
              ...batch,
              totalItems: 0
            })
          }, 1)
        })
        setShouldLockAllFilterOption(false)
      } else {
        apiFetcher.current = zip(...apis).subscribe(responses => {
          responses.forEach((response, index) => {
            const parser = _.get(
              Object.values(_.get(arr, `[${index}].apis`, {})),
              '[0].parser',
              null
            )
            const result = parser(response)
            const code = _.get(result, 'code', null)
            const data = _.get(result, 'data', null)
            const totalItems = _.get(result, 'totalItems', 0)

            if (code === 'success' && data !== null) {
              batchExport({
                ...arr[index],
                totalItems,
                filterRange
              })
            }
          })
          apiFetcher.current = null
          setShouldLockAllFilterOption(false)
        })
      }
    }
  }

  useEffect(() => {
    if (!auth.isLoggedIn) {
      if (apiFetcher.current) {
        apiFetcher.current.unsubscribe()
      }
    }
  }, [auth])

  return (
    <ExportDataContext.Provider
      value={{
        exportData: {
          ...exportData,
          shouldLockAllFilterOption
        },
        batchExport,
        batchSingleExport,
        batchExportByArray,
        dispatchExportData
      }}
    >
      {props.children}
    </ExportDataContext.Provider>
  )
}
