import React from 'react'
import makeTabsHelper from 'helpers/Route/makeTabs'
import { RouteType } from 'types/route'

export const Message = React.lazy(
  () => import('projects/DM/components/pages/Message')
)

export const History = React.lazy(
  () => import('projects/DM/components/pages/History')
)

export const tabs: RouteType[] = [
  {
    path: '/history',
    title: 'View History',
    key: 'dm',
    contentComponent: History,
    exact: true
  }
]

export const makeTabs = makeTabsHelper(tabs)
