import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

const ExpansionPanelDetails = withStyles({
  root: {
    display: 'flex',
    padding: '8px 0',
    background: '#f2f2f2',
    '& > div': {
      display: 'flex',
      '&.loading': {
        padding: '2px 0 0 0',
        flex: '1 0 56px',
        alignItems: 'start',
        justifyContent: 'center'
      },
      '&.action': {
        flex: '1 0 56px',
        alignItems: 'start',
        justifyContent: 'center',
        '& > span': {
          cursor: 'pointer'
        }
      },
      '&:not(.action):not(.loading)': {
        flex: '1 1 100%',
        flexDirection: 'column',
        maxWidth: 'calc(100% - 112px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    '& div.title': {
      fontSize: '18px',
      fontWeight: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& > span': {
        cursor: 'pointer'
      }
    },
    '& div.status': {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
      '&.complete': {
        color: '#6a9eff'
      },
      '&.pending': {
        color: '#000'
      },
      '&.preparing': {
        color: '#000',
        opacity: 0.6
      },
      '&.failed': {
        color: 'red',
        opacity: 0.6
      }
    }
  }
})(MuiExpansionPanelDetails)

export default ExpansionPanelDetails
