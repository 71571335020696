import React, { useState, useRef, useEffect, useCallback } from 'react'
import cx from 'clsx'
import _ from 'lodash'
import Popover from '@material-ui/core/Popover'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputBase from '@material-ui/core/InputBase'
import Measure from 'react-measure'
import { Tooltip } from '@material-ui/core'

import Button from 'components/blocks/Button'
import { ArrowUp, ArrowDown } from 'helpers/Icons'
import TextField from '@material-ui/core/TextField'
import LoadingContainer from 'components/blocks/LoadingContainer'
import { usePrevious } from 'helpers/General'

const useStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '40px',
    margin: 0,
    position: 'relative',
    borderRadius: 4,
    boxSizing: 'border-box',
    fontSize: theme.typography.fontSize,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `solid 1px ${theme.custom.colors.gray3}`,
    '& > svg': {
      position: 'absolute',
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none'
    },
    '&:not(.disabled):hover': {
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.12)',
      border: `solid 1px ${theme.custom.colors.gray3}`
    }
  },
  placeHolder: {
    position: 'absolute',
    top: '50%',
    left: '16px',
    transform: 'translateY(-50%)',
    // zIndex: 1,
    color: theme.custom.colors.black1,
    fontSize: '14px',
    pointerEvents: 'none',
    '&.disabled + svg': {
      color: theme.custom.colors.gray3
    }
  }
}))

const usePickerStyles = makeStyles(theme => ({
  paper: {
    boxShadow: 'none',
    margin: '8px',
    color: theme.custom.colors.black1,
    fontSize: 13,
    backgroundColor: theme.custom.colors.gray1
  },
  option: {
    height: '37px',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    '&[aria-selected="true"]': {
      backgroundColor: theme.custom.colors.gray1
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.custom.colors.gray1
    }
  },
  groupLabel: {
    backgroundColor: theme.custom.colors.gray1,
    lineHeight: '26px'
  },
  popperDisablePortal: {
    //width: '162px !important',
    position: 'relative'
  },
  listbox: {
    maxHeight: `${37 * 5}px`
  },
  clearButton: {
    marginLeft: 'auto',
    marginRight: '8px'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
    '& > span': {
      display: 'inline-block',
      width: '13px',
      height: '13px',
      margin: '3px 13px 3px 3px',
      padding: '2px',
      borderRadius: '2px',
      cursor: 'pointer'
    },
    '&.selected > span': {
      position: 'relative',
      border: `1px solid ${theme.custom.colors.blue1}`,
      '&:after': {
        position: 'absolute',
        display: 'inline-block',
        content: '""',
        width: '13px',
        height: '13px',
        borderRadius: '1px',
        background: theme.custom.colors.blue1
      }
    },
    '&:not(.selected) > span': {
      border: `1px solid ${theme.custom.colors.gray2}`
    },
    '&.active.selected > span:hover:after': {
      background: theme.custom.colors.blue2
    },
    '&.active:not(.selected) > span:hover': {
      border: `1px solid ${theme.custom.colors.blue1}`
    }
  },
  label: {
    margin: '0 4px',
    maxWidth: '100%',
    height: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  containerWithNoResult: {
    height: '0px'
  },
  buttonContainer: {
    display: 'flex',
    margin: '0px 8px',
    position: 'relative'
  },
  lineButton: {
    textDecoration: 'underline',
    fontSize: 13,
    color: theme.custom.colors.blue1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.colors.blue2
    },
    '&:not(:last-child)': {
      marginRight: '8px'
    }
  }
}))

const useInputStyles = makeStyles(theme => ({
  root: {
    width: props => `${props.width}px`,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& > svg': {
      position: 'absolute',
      right: 16,
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'all'
    }
  },
  input: {
    borderRadius: 4,
    boxSizing: 'border-box',
    textAlign: 'left',
    position: 'relative',
    backgroundColor: theme.custom.colors.white,
    fontSize: theme.typography.fontSize,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    width: props => 'calc(100% - 18px)',
    margin: '8px 8px 0 8px',
    height: '40px',
    border: `solid 1px ${theme.custom.colors.gray3}`,
    '&:hover': {
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.12)',
      border: `solid 1px ${theme.custom.colors.gray3}`
    },
    '&:focus': {
      borderRadius: 4,
      backgroundColor: theme.custom.colors.white,
      border: `solid 1px ${theme.custom.colors.blue1}`
    }
  }
}))

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 4px 8px 0 rgba(33, 79, 165, 0.12)',
    borderRadius: 4,
    //width: '168',
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    pointerEvents: props => (props.loading ? 'none' : 'auto'),
    filter: props => (props.loading ? `grayscale(0.7)` : 'none')
  }
}))

const HiddenTextField = withStyles({
  root: {
    visibility: 'hidden',
    height: '0px',
    '& svg': {
      display: 'none'
    }
  }
})(TextField)

const Picker = ({
  value,
  setValue,
  numberOfSelection = null,
  options,
  grouped,
  groupedBy,
  groupedBySort,
  loading = false,
  allowSelectAll,
  defaultValue
}) => {
  const classes = usePickerStyles()

  return (
    <>
      <Autocomplete
        open
        multiple
        limitTags={0}
        classes={{
          paper: classes.paper,
          option: classes.option,
          listbox: classes.listbox,
          groupLabel: classes.groupLabel,
          popperDisablePortal: classes.popperDisablePortal
        }}
        autoHighlight={false}
        value={value}
        onChange={(event, newValue) => {
          if (
            _.get(newValue, 'length', 0) <= numberOfSelection ||
            numberOfSelection === null
          ) {
            setValue(newValue)
          }
        }}
        getOptionSelected={(option, value) => {
          return _.get(option, 'value', false) === _.get(value, 'value', value)
        }}
        disableCloseOnSelect
        disablePortal
        renderTags={() => null}
        noOptionsText='No Result'
        renderOption={(option, { selected }) => {
          const EndAdornment = !loading ? (
            <div
              className={cx(classes.checkbox, selected && 'selected')}
              style={
                !selected &&
                _.get(value, 'length', 0) === numberOfSelection &&
                numberOfSelection !== null
                  ? {
                      opacity: 0.5
                    }
                  : null
              }
            >
              <span></span>
            </div>
          ) : null
          return (
            <>
              <div className={classes.label}>{option.label}</div>
              {EndAdornment}
            </>
          )
        }}
        options={
          grouped
            ? groupedBySort
              ? groupedBySort(options)
              : options.sort(
                  (a, b) => -b.label.charAt(0).localeCompare(a.label.charAt(0))
                )
            : options
        }
        groupBy={
          grouped
            ? groupedBy
              ? groupedBy
              : option => {
                  return option.label.charAt(0).toLowerCase()
                }
            : null
        }
        getOptionLabel={option => option.label}
        renderInput={params => (
          <div
            className={
              options.length === 0 ? classes.containerWithNoResult : null
            }
          >
            <HiddenTextField {...params} />
            {options.length !== 0 && (
              <div className={classes.buttonContainer}>
                {allowSelectAll && (
                  <span
                    className={classes.lineButton}
                    onClick={() => setValue(options)}
                  >
                    Select all
                  </span>
                )}
                <span
                  className={classes.lineButton}
                  onClick={() => setValue(defaultValue)}
                >
                  Clear
                </span>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

const DropdownSelector = React.memo(
  ({
    value = [],
    onChange = () => {},
    grouped = false,
    groupedBy = null,
    groupedBySort = null,
    options = [],
    loading = false,
    style = null,
    allowSelectAll = false,
    numberOfSelection = null,
    useSearch = false,
    disabled = false,
    defaultValue = []
  }) => {
    const classes = useStyle()
    const popoverClasses = usePopoverStyles({ loading })
    const [width, setWidth] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [_value, setValue] = useState(value)
    const [_options, setOptions] = useState(options)
    const open = !_.isEmpty(anchorEl)
    const inputRef = useRef(null)
    const inputClasses = useInputStyles({ width })
    const prevValue = usePrevious(value)

    useEffect(() => {
      setOptions(options)
    }, [options])

    useEffect(() => {
      if (Array.isArray(value)) {
        !_.isEqual(value, prevValue) && setValue(value)
      } else {
        onChange([])
      }
    }, [value, onChange, prevValue])

    const handleOpen = useCallback(
      event => {
        if (options.length !== _options.length) {
          setOptions(options)
        }
        setAnchorEl(event.currentTarget)
        setIsOpen(true)
      },
      [options, _options]
    )

    const handleClose = () => {
      setAnchorEl(null)
      setIsOpen(false)
    }

    return (
      <>
        <Measure
          offset
          onResize={contentRect => {
            setWidth(contentRect.offset.width)
          }}
        >
          {({ measureRef }) => (
            <Tooltip
              title={
                _value.length === 0 ? (
                  ''
                ) : (
                  <>
                    {Array.isArray(_value) &&
                      _value.map(v => (
                        <div
                          key={`option_tooltip_${_.get(v, 'value', v)}`}
                          style={{ padding: '6px 12px', fontSize: 14 }}
                        >
                          {_.get(
                            options.find(
                              option => option.value === _.get(v, 'value', v)
                            ),
                            'label',
                            ''
                          )}
                        </div>
                      ))}
                  </>
                )
              }
              placement='bottom-end'
            >
              <div
                className={cx(classes.root, disabled && 'disabled')}
                onClick={e => {
                  if (!disabled) {
                    handleOpen(e)
                  }
                }}
                ref={measureRef}
                style={style}
              >
                <div
                  className={cx(classes.placeHolder, disabled && 'disabled')}
                >
                  {(value =>
                    value.length === 0
                      ? numberOfSelection === null
                        ? ''
                        : `Select (Up to ${numberOfSelection} items)`
                      : numberOfSelection === null
                      ? `${value.length} ${
                          value.length === 1 ? 'item' : 'items'
                        } selected`
                      : `(${value.length}/${numberOfSelection}) ${
                          value.length === 1 ? 'item' : 'items'
                        } selected`)(open ? _value : value)}
                </div>
                {isOpen ? <ArrowDown /> : <ArrowUp />}
              </div>
            </Tooltip>
          )}
        </Measure>
        <Popover
          open={open}
          onClose={() => {
            setValue(value)
            handleClose()
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 44
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: popoverClasses.paper
          }}
          PaperProps={{
            style: {
              width
            }
          }}
        >
          <LoadingContainer loading={loading} iconSize='small'>
            {useSearch && (
              <InputBase
                placeholder={'Search'}
                classes={{
                  root: inputClasses.root,
                  input: inputClasses.input
                }}
                inputRef={inputRef}
                onChange={e => {
                  const value = e.target.value
                  setOptions(
                    options.filter(
                      option =>
                        option.label
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) >= 0
                    )
                  )
                }}
              />
            )}
            <Picker
              allowSelectAll={allowSelectAll}
              value={_value}
              setValue={setValue}
              options={_options}
              loading={loading}
              numberOfSelection={numberOfSelection}
              grouped={grouped}
              groupedBy={groupedBy}
              groupedBySort={groupedBySort}
              defaultValue={defaultValue}
            />
            {options.length !== 0 && (
              <div style={{ margin: '4px 8px 6px 8px', textAlign: 'right' }}>
                <Button
                  variant='outlined'
                  color={'primary'}
                  label='Cancel'
                  style={{ marginRight: 4 }}
                  onClick={() => {
                    setValue(value)
                    handleClose()
                  }}
                />
                <Button
                  variant='contained'
                  color={'primary'}
                  label='Ok'
                  onClick={() => {
                    onChange(_value)
                    handleClose()
                  }}
                />
              </div>
            )}
          </LoadingContainer>
        </Popover>
      </>
    )
  }
)

export default DropdownSelector
