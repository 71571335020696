import { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

export const generateRandomString = (length = 10) => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const isEqual = function (value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  var valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  var otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  return true
}

export const round10 = (input = 0, digits = 0) => {
  let value = input
  value = Math.round(input / Math.pow(10, digits)) * Math.pow(10, digits)
  let string = value
  if (digits < 0) string = value.toFixed(digits * -1)
  //console.log(string, value)
  return { string, value }
}

export const formatNumber = (
  number,
  {
    useCompact = false,
    locale = 'en',
    decimal = null,
    compactDisplay = 'short'
  } = {
    useCompact: false,
    locale: 'en',
    decimal: null,
    compactDisplay: 'short'
  }
) => {
  const option = useCompact
    ? {
        notation: 'compact',
        compactDisplay
      }
    : {
        notation: 'standard'
      }

  if (decimal !== null) {
    option.minimumFractionDigits = decimal
  }

  return new Intl.NumberFormat(locale, option).format(
    decimal !== null ? round10(number, decimal * -1).value : number
  )
}

export const formatDate = ({
  date,
  useUtcTimezone = false,
  formatType = 'YYYY-MM-DD'
}) => {
  if (date === null) return dayjs().format(formatType)
  return useUtcTimezone
    ? `${dayjs(date).utc().format(formatType)}`
    : `${dayjs(date).format(formatType)}`
}

export const secToMinSec = n => {
  const minFloat = round10(n / 60, -2).value
  const min = Math.floor(n / 60)
  const sec = Math.round(n % 60)
  return { minFloat, min, sec }
}

export const isFloat = value => {
  if (typeof value !== 'number') {
    console.log('You need input a number parameter')
    return null
  }
  return value % 1 !== 0
}

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useLengthControl = (ml, getFilteredLength = null) => {
  const [value, setValue] = useState('')
  const [maxLength] = useState(ml)

  return {
    value,
    setValue: newValue => {
      if (newValue === null) {
        setValue('')
      } else {
        setValue(newValue.substring(0, maxLength))
      }
    },
    length:
      getFilteredLength !== null ? getFilteredLength(value) : value.length,
    maxLength
  }
}

export const useSearchParam = queryString => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const searchParam = query.get(queryString)
  return searchParam
}

export const getRangeArray = (start, end) => {
  let array = []
  for (let i = start; i <= end; i++) {
    array.push(i)
  }
  return array
}

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export const sliceString = (string = '', limitNumber = 0) => {
  return string.length > limitNumber
    ? string.slice(0, limitNumber) + '...'
    : string
}

export const convertVideoOptionsWithMultipleKeyPerson = data =>
  data.map(item => {
    const key = item.key.split('@')
    return {
      ...item,
      key: key[0],
      ...(key[1] ? { key_person_number: Number(key[1]) } : {})
    }
  })

  export const removePrefixFromKeys=(obj, prefix) =>{
    const transformedObj = {};
  
    for (const key in obj) {
      if (key.startsWith(prefix)) {
        const transformedKey = key.substring(prefix.length + 1);
        transformedObj[transformedKey] = obj[key];
      } else {
        transformedObj[key] = obj[key];
      }
    }
  
    return transformedObj;
  }




export const convertFeatureStateToValuesArray = result => {
  return _.reduce(
    result,
    (result, value, key) => {
      return result.concat(value.map(item => item.value))
    },
    []
  )
}

export const verifyResult = result => {
  const video_options = _.get(result, 'video_options', null)
  const video_feature_options = _.get(result, 'video_feature_options', null)
  const cover_feature_options = _.get(result, 'cover_feature_options', null)
  const ads_options = _.get(result, 'ads_options', null)

  const verification = {}
  if (video_options !== null) {
    verification.video_options = Object.values(video_options).some(
      option => option
    )
  }
  if (video_feature_options !== null) {
    // TODO: new feature validation
    verification.video_feature_options = video_feature_options.length > 0
    // convertFeatureStateToValuesArray(video_feature_options).length > 0
  }
  if (cover_feature_options !== null) {
    verification.cover_feature_options = cover_feature_options.length > 0
      // convertFeatureStateToValuesArray(cover_feature_options).length > 0
  }
  if (ads_options !== null) {
    verification.ads_options = Object.values(ads_options).some(option => option)
  }
  return verification
}

export const useVerifyResult = result => {
  return verifyResult(result)
}

export const transformedQualityOptions = (quality_options)=>{
 return Object.keys(quality_options).reduce((acc, key) => {
    acc[key] = quality_options[key] === 1;
    return acc;
  }, {});
}

export const getReportedViolationRequiresReassessment = (isReported) => {
  return isReported ? '*被檢舉違規-需重新審核' : '';
};


export const isHttpOrHttpsUrl=(str)=> {
  return str.startsWith("http://") || str.startsWith("https://");
}

