// const backendVideoType = {
//   0: 'ProvidedByUpload',
//   1: 'ProvidedByMyVideos',
//   2: 'ProvidedByUserVideos'
// }

const videoType = {
  0: 'Uploaded',
  2: 'UGC'
}

export const codeToVideoType = code => {
  let result = ''
  if (!code) return result
  if (!videoType[code]) return result
  result = videoType[code]
  return result
}

export const dataToBackendVideoTypeCodeToVideoType = data =>
  data.reduce(
    (obj, cur, i) => {
      // backendVideoType 1 + 2 = videoType 2
      if (cur.vtype === 1 || cur.vtype === 2) {
        // if (!obj.transformedObj.hasOwnProperty('vtype')) obj.transformedObj.vtype = 2
        obj.transformedObj.count += cur.count
      } else obj.arr.push(cur)

      if (i === data.length - 1) {
        obj.arr.push(obj.transformedObj)
      }
      return obj
    },
    {
      arr: [],
      transformedObj: {
        count: 0,
        vtype: 2
      }
    }
  ).arr

export default videoType
