export default {
  aa: { label: 'Afar', code: 'aa' },
  ab: { label: 'Abkhazian', code: 'ab' },
  ae: { label: 'Avestan', code: 'ae' },
  af: { label: 'Afrikaans', code: 'af' },
  ak: { label: 'Akan', code: 'ak' },
  am: { label: 'Amharic', code: 'am' },
  an: { label: 'Aragonese', code: 'an' },
  ar: { label: 'Arabic', code: 'ar' },
  as: { label: 'Assamese', code: 'as' },
  av: { label: 'Avaric', code: 'av' },
  ay: { label: 'Aymara', code: 'ay' },
  az: { label: 'Azerbaijani', code: 'az' },
  ba: { label: 'Bashkir', code: 'ba' },
  be: { label: 'Belarusian', code: 'be' },
  bg: { label: 'Bulgarian', code: 'bg' },
  bh: { label: 'Bihari languages', code: 'bh' },
  bi: { label: 'Bislama', code: 'bi' },
  bm: { label: 'Bambara', code: 'bm' },
  bn: { label: 'Bengali', code: 'bn' },
  bo: { label: 'Tibetan', code: 'bo' },
  br: { label: 'Breton', code: 'br' },
  bs: { label: 'Bosnian', code: 'bs' },
  ca: { label: 'Catalan, Valencian', code: 'ca' },
  ce: { label: 'Chechen', code: 'ce' },
  ch: { label: 'Chamorro', code: 'ch' },
  co: { label: 'Corsican', code: 'co' },
  cr: { label: 'Cree', code: 'cr' },
  cs: { label: 'Czech', code: 'cs' },
  cu: {
    label:
      'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
    code: 'cu'
  },
  cv: { label: 'Chuvash', code: 'cv' },
  cy: { label: 'Welsh', code: 'cy' },
  da: { label: 'Danish', code: 'da' },
  de: { label: 'German', code: 'de' },
  dv: { label: 'Divehi, Dhivehi, Maldivian', code: 'dv' },
  dz: { label: 'Dzongkha', code: 'dz' },
  ee: { label: 'Ewe', code: 'ee' },
  el: { label: 'Greek, Modern (1453-)', code: 'el' },
  en: { label: 'English	', code: 'en' },
  eo: { label: 'Esperanto', code: 'eo' },
  es: { label: 'Spanish, Castilian', code: 'es' },
  et: { label: 'Estonian', code: 'et' },
  eu: { label: 'Basque', code: 'eu' },
  fa: { label: 'Persian', code: 'fa' },
  ff: { label: 'Fulah', code: 'ff' },
  fi: { label: 'Finnish', code: 'fi' },
  fj: { label: 'Fijian', code: 'fj' },
  fo: { label: 'Faroese', code: 'fo' },
  fr: { label: 'French', code: 'fr' },
  fy: { label: 'Western Frisian', code: 'fy' },
  ga: { label: 'Irish', code: 'ga' },
  gd: { label: 'Gaelic, Scottish Gaelic', code: 'gd' },
  gl: { label: 'Galician', code: 'gl' },
  gn: { label: 'Guarani', code: 'gn' },
  gu: { label: 'Gujarati', code: 'gu' },
  gv: { label: 'Manx', code: 'gv' },
  ha: { label: 'Hausa', code: 'ha' },
  he: { label: 'Hebrew', code: 'he' },
  hi: { label: 'Hindi', code: 'hi' },
  ho: { label: 'Hiri Motu', code: 'ho' },
  hr: { label: 'Croatian', code: 'hr' },
  ht: { label: 'Haitian, Haitian Creole', code: 'ht' },
  hu: { label: 'Hungarian', code: 'hu' },
  hy: { label: 'Armenian', code: 'hy' },
  hz: { label: 'Herero', code: 'hz' },
  ia: {
    label: 'Interlingua (International Auxiliary Language Association)',
    code: 'ia'
  },
  id: { label: 'Indonesian', code: 'id' },
  ie: { label: 'Interlingue, Occidental', code: 'ie' },
  ig: { label: 'Igbo', code: 'ig' },
  ii: { label: 'Sichuan Yi, Nuosu', code: 'ii' },
  ik: { label: 'Inupiaq', code: 'ik' },
  io: { label: 'Ido', code: 'io' },
  is: { label: 'Icelandic', code: 'is' },
  it: { label: 'Italian', code: 'it' },
  iu: { label: 'Inuktitut', code: 'iu' },
  ja: { label: 'Japanese', code: 'ja' },
  jv: { label: 'Javanese', code: 'jv' },
  ka: { label: 'Georgian', code: 'ka' },
  kg: { label: 'Kongo', code: 'kg' },
  ki: { label: 'Kikuyu, Gikuyu', code: 'ki' },
  kj: { label: 'Kuanyama, Kwanyama', code: 'kj' },
  kk: { label: 'Kazakh', code: 'kk' },
  kl: { label: 'Kalaallisut, Greenlandic', code: 'kl' },
  km: { label: 'Central Khmer', code: 'km' },
  kn: { label: 'Kannada', code: 'kn' },
  ko: { label: 'Korean', code: 'ko' },
  kr: { label: 'Kanuri', code: 'kr' },
  ks: { label: 'Kashmiri', code: 'ks' },
  ku: { label: 'Kurdish', code: 'ku' },
  kv: { label: 'Komi', code: 'kv' },
  kw: { label: 'Cornish', code: 'kw' },
  ky: { label: 'Kirghiz, Kyrgyz', code: 'ky' },
  la: { label: 'Latin', code: 'la' },
  lb: { label: 'Luxembourgish, Letzeburgesch', code: 'lb' },
  lg: { label: 'Ganda', code: 'lg' },
  li: { label: 'Limburgan, Limburger, Limburgish', code: 'li' },
  ln: { label: 'Lingala', code: 'ln' },
  lo: { label: 'Lao', code: 'lo' },
  lt: { label: 'Lithuanian', code: 'lt' },
  lu: { label: 'Luba-Katanga', code: 'lu' },
  lv: { label: 'Latvian', code: 'lv' },
  mg: { label: 'Malagasy', code: 'mg' },
  mh: { label: 'Marshallese', code: 'mh' },
  mi: { label: 'Maori', code: 'mi' },
  mk: { label: 'Macedonian', code: 'mk' },
  ml: { label: 'Malayalam', code: 'ml' },
  mn: { label: 'Mongolian', code: 'mn' },
  mr: { label: 'Marathi', code: 'mr' },
  ms: { label: 'Malay', code: 'ms' },
  mt: { label: 'Maltese', code: 'mt' },
  my: { label: 'Burmese', code: 'my' },
  na: { label: 'Nauru', code: 'na' },
  nb: { label: 'Norwegian Bokmål', code: 'nb' },
  nd: { label: 'Ndebele, North, North Ndebele', code: 'nd' },
  ne: { label: 'Nepali', code: 'ne' },
  ng: { label: 'Ndonga', code: 'ng' },
  nl: { label: 'Dutch, Flemish', code: 'nl' },
  nn: { label: 'Norwegian Nynorsk, Nynorsk, Norwegian', code: 'nn' },
  no: { label: 'Norwegian', code: 'no' },
  nr: { label: 'Ndebele, South, South Ndebele', code: 'nr' },
  nv: { label: 'Navajo, Navaho', code: 'nv' },
  ny: { label: 'Chichewa, Chewa, Nyanja', code: 'ny' },
  oc: { label: 'Occitan (post 1500)', code: 'oc' },
  oj: { label: 'Ojibwa', code: 'oj' },
  om: { label: 'Oromo', code: 'om' },
  or: { label: 'Oriya', code: 'or' },
  os: { label: 'Ossetian, Ossetic', code: 'os' },
  pa: { label: 'Panjabi, Punjabi', code: 'pa' },
  pi: { label: 'Pali', code: 'pi' },
  pl: { label: 'Polish', code: 'pl' },
  ps: { label: 'Pushto, Pashto', code: 'ps' },
  pt: { label: 'Portuguese', code: 'pt' },
  qu: { label: 'Quechua', code: 'qu' },
  rm: { label: 'Romansh', code: 'rm' },
  rn: { label: 'Rundi', code: 'rn' },
  ro: { label: 'Romanian, Moldavian, Moldovan', code: 'ro' },
  ru: { label: 'Russian', code: 'ru' },
  rw: { label: 'Kinyarwanda', code: 'rw' },
  sa: { label: 'Sanskrit', code: 'sa' },
  sc: { label: 'Sardinian', code: 'sc' },
  sd: { label: 'Sindhi', code: 'sd' },
  se: { label: 'Northern Sami', code: 'se' },
  sg: { label: 'Sango', code: 'sg' },
  si: { label: 'Sinhala, Sinhalese', code: 'si' },
  sk: { label: 'Slovak', code: 'sk' },
  sl: { label: 'Slovenian', code: 'sl' },
  sm: { label: 'Samoan', code: 'sm' },
  sn: { label: 'Shona', code: 'sn' },
  so: { label: 'Somali', code: 'so' },
  sq: { label: 'Albanian', code: 'sq' },
  sr: { label: 'Serbian', code: 'sr' },
  ss: { label: 'Swati', code: 'ss' },
  st: { label: 'Sotho, Southern', code: 'st' },
  su: { label: 'Sundanese', code: 'su' },
  sv: { label: 'Swedish', code: 'sv' },
  sw: { label: 'Swahili', code: 'sw' },
  ta: { label: 'Tamil', code: 'ta' },
  te: { label: 'Telugu', code: 'te' },
  tg: { label: 'Tajik', code: 'tg' },
  th: { label: 'Thai', code: 'th' },
  ti: { label: 'Tigrinya', code: 'ti' },
  tk: { label: 'Turkmen', code: 'tk' },
  tl: { label: 'Tagalog', code: 'tl' },
  tn: { label: 'Tswana', code: 'tn' },
  to: { label: 'Tonga (Tonga Islands)', code: 'to' },
  tr: { label: 'Turkish', code: 'tr' },
  ts: { label: 'Tsonga', code: 'ts' },
  tt: { label: 'Tatar', code: 'tt' },
  zh_tw: { label: 'Traditional Chinese', code: 'zh-tw' },
  ty: { label: 'Tahitian', code: 'ty' },
  ug: { label: 'Uighur, Uyghur', code: 'ug' },
  uk: { label: 'Ukrainian', code: 'uk' },
  ur: { label: 'Urdu', code: 'ur' },
  uz: { label: 'Uzbek', code: 'uz' },
  ve: { label: 'Venda', code: 've' },
  vi: { label: 'Vietnamese', code: 'vi' },
  vo: { label: 'Volapük', code: 'vo' },
  wa: { label: 'Walloon', code: 'wa' },
  wo: { label: 'Wolof', code: 'wo' },
  xh: { label: 'Xhosa', code: 'xh' },
  yi: { label: 'Yiddish', code: 'yi' },
  yo: { label: 'Yoruba', code: 'yo' },
  za: { label: 'Zhuang, Chuang', code: 'za' },
  zh_cn: { label: 'Simplified Chinese', code: 'zh-cn' },
  zu: { label: 'Zulu', code: 'zu' },
  undefined: {
    label: 'Unspecified',
    code: 'undefined'
  }
}
