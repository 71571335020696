import React from 'react'
import cx from 'clsx'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import Progress from 'components/blocks/Progress'

const StyledButton = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      textTransform: 'none'
    },
    '&.small': {
      height: '24px'
    },
    '&.large': {},
    '&:not(.small):not(.large)': {
      height: '40px'
    }
  },
  label: {
    whiteSpace: 'nowrap'
  },
  disabled: {
    color: '#fff'
  },
  contained: {
    padding: '0 12px'
  },
  outlined: {
    padding: '0 17px',
    color: '#000',
    '&:not(.loading):hover': {
      color: '#fff',
      background: 'rgba(106,158,255, 0.8)'
    },
    '&.loading:hover': {
      color: theme.custom.colors.blue1
    }
  }
}))(MuiButton)

export const ButtonWithRef = React.forwardRef((props, ref) => {
  if (get(props, 'loading', false)) {
    return (
      <StyledButton
        ref={ref}
        className={cx(props.className, 'loading')}
        variant={props.variant}
        color={props.color}
        style={props.style}
      >
        <Progress colortype={props.variant} />
      </StyledButton>
    )
  }

  return (
    <StyledButton
      ref={ref}
      className={props.className}
      variant={props.variant}
      color={props.color}
      disabled={get(props, 'disabled', false)}
      onClick={props.onClick}
      style={props.style}
    >
      {props.label}
    </StyledButton>
  )
})

export const Button = props => {
  if (get(props, 'loading', false)) {
    const { style } = props
    return (
      <StyledButton
        className={cx(props.className, 'loading')}
        variant={props.variant}
        color={props.color}
        style={style}
      >
        <Progress colortype={props.variant} />
      </StyledButton>
    )
  }

  return (
    <StyledButton
      {...props}
      disabled={get(props, 'disabled', false)}
      loading='false'
    >
      {props.label}
    </StyledButton>
  )
}

export const ButtonWithTooltip = props => {
  const { tooltipText = null, tooltipPlacement = 'bottom-start' } = props
  if (tooltipText === null) {
    return (
      <Button
        className={props.className}
        variant={props.variant}
        color={props.color}
        disabled={get(props, 'disabled', false)}
        loading={get(props, 'loading', false)}
        type={props.type}
        label={props.label}
        onClick={props.onClick}
        style={props.style}
      />
    )
  }
  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <span>
        <Button
          className={props.className}
          variant={props.variant}
          color={props.color}
          disabled={get(props, 'disabled', false)}
          loading={get(props, 'loading', false)}
          type={props.type}
          label={props.label}
          onClick={props.onClick}
          style={props.style}
        />
      </span>
    </Tooltip>
  )
}

export default Button
