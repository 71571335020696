export namespace Dashboard {
  export enum Overview {
    ActiveUsers = 'overviewActiveUsers',
    SessionDuration = 'overviewSessionDuration',
    Last30MinsUser = 'overviewLast30MinsUser',
    EventLogNames = 'overviewEventLogNames',
    CreatorRate = 'overviewCreatorRate',
    AdsSkipRate = 'overviewAdsSkipRate',
    Retention = 'overviewRetention',
    OperatingSystem = 'overviewOperatingSystem',
    Countries = 'overviewCountries',
    RecentOpenAppFrequency = 'overviewRecentOpenAppFrequency',
    RecentOpenAppFrequencySeeUser = 'overviewRecentOpenAppFrequencySeeUser',
    RecentGoodVideoCount = 'overviewRecentGoodVideoCount',
    RecentGoodVideoCountSeeUser = 'overviewRecentGoodVideoCountSeeUser',
    RecentGoodVideoFrequency = 'overviewRecentGoodVideoFrequency',
    RecentGoodVideoFrequencySeeUser = 'overviewRecentGoodVideoFrequencySeeUser',
    GoodVideosLifetimeDays = 'overviewGoodVideosLifetimeDays',
    GoodVideosLifetimeDaysSeeUser = 'overviewGoodVideosLifetimeDaysSeeUser',
    FollowerDistribution = 'overviewFollowerDistribution',
    FollowerDistributionSeeUser = 'overviewFollowerDistributionSeeUser',
    FollowerGrowthRate = 'overviewFollowerGrowthRate',
    FollowerGrowthRateSeeUser = 'overviewFollowerGrowthRateSeeUser',
    ProfileCompletionRate = 'overviewProfileCompletionRate',
    ProfileCompletionRateSeeUser = 'overviewProfileCompletionRateSeeUser',
    EngagementRateReceive = 'overviewEngagementRateReceive',
    EngagementRateReceiveSeeUser = 'overviewEngagementRateReceiveSeeUser',
    EngagementRateGive = 'overviewEngagementRateGive',
    EngagementRateGiveSeeUser = 'overviewEngagementRateGiveSeeUser',
    PotentialPayingUser = 'overviewPotentialPayingUser',
    PotentialPayingUserSeeUser = 'overviewPotentialPayingUserSeeUser',
    RetentionRateNewUser = 'overviewRetentionRateNewUser',
    RetentionRateNewCreator = 'overviewRetentionRateNewCreator',
    RetentionRateNewOa = 'overviewRetentionRateNewOa',
    DailyActiveViewer = 'overviewDailyActiveViewer',
    DailyUserTime = 'overviewDailyUserTime'
  }
}
