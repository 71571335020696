import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  memo
} from 'react'
import cx from 'clsx'
import Input from 'components/blocks/TextInput'
import AddDeleteButtonGroup from 'components/blocks/AddDeleteButtonGroup'
import SmallFilter from '../SmallFilter'
import useSmallFilterAction from '../../hooks/useSmallFilterAction'
import CheckDataAllFilled from '../../helper/checkDataAllFilled'
import MODAL_TYPE from 'config/constansts/ModalType'
import { Dashboard } from 'types/chartEnum'
import _ from 'lodash'

const { Overview } = Dashboard
const type = MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.RecentGoodVideoCount]

const Filter = memo(
  ({ classes, state, setValue, setDefault, disabled }) => (
    <div className={classes.filter}>
      <span
        className={cx(
          'radioButton',
          state.is_default && 'selected',
          disabled && 'disabled'
        )}
        onClick={() => !disabled && setDefault(state.key)}
      >
        <span></span>
      </span>
      <div className='row-content'>
        <span className={classes.text}>Within</span>
        <Input
          value={state.dayCount}
          setValue={value => setValue(state.key, 'dayCount', value)}
          width={57}
          style={{ margin: '0 8px' }}
          disabled={disabled}
        />
        <span className={classes.text}>days</span>
      </div>
    </div>
  ),
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

const RecentGoodVideoCountSmallFilter = forwardRef((props, ref) => {
  const {
    classes,
    emptyValue,
    defaultValue,
    setAllFilled,
    setLoading,
    fetchedData,
    disabled
  } = props

  const [states, setStates] = useState([
    { ...emptyValue, ...defaultValue, is_default: true }
  ])
  const {
    setValue,
    setDefault,
    handleAdd,
    handleDelete
  } = useSmallFilterAction({ setStates, emptyValue, setLoading, type })

  useEffect(() => setAllFilled(CheckDataAllFilled(states)), [
    setAllFilled,
    states
  ])

  useEffect(() => {
    if (fetchedData.length > 0) setStates(fetchedData)
  }, [fetchedData])

  useImperativeHandle(
    dom => (ref.current[type] = dom),
    () => states,
    [states]
  )

  return (
    <>
      <div className={classes.category}>Potential Paying User</div>
      <div className={classes.title}>Recent Good Video Count</div>
      <div className={classes.filters}>
        {states.length > 0 &&
          states.map((state, index) => (
            <div className={classes.row} key={state.key}>
              <Filter
                {...{
                  classes,
                  state,
                  setValue,
                  setDefault,
                  length: states.length,
                  disabled
                }}
              />
              <AddDeleteButtonGroup
                {...{
                  size: 'small',
                  index,
                  totalLength: states.length,
                  classes: classes.buttonGroup,
                  addCount: 5,
                  handleAdd,
                  handleDelete,
                  target: state.key,
                  id: state.id,
                  disabled
                }}
              />
            </div>
          ))}
      </div>
    </>
  )
})

const RecentGoodVideoCount = (props, ref) => {
  return (
    <SmallFilter {...props}>
      <RecentGoodVideoCountSmallFilter {...ref} />
    </SmallFilter>
  )
}

export default forwardRef(RecentGoodVideoCount)
