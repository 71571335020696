import React, { useEffect, useContext, useCallback, useRef } from 'react'
import cx from 'clsx'
import get from 'lodash/get'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import './video-react.css'

import { VideoPlayerContext } from './Context'
import Video from 'components/blocks/Video'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

const useStyles = makeStyles(theme => ({
  root: {
    width: '315px'
  },
  player: {
    background: 'transparent'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    '& video': {
      outline: 'none !important'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    cursor: 'pointer',
    '&:not(:hover)': {
      opacity: 0.6
    },
    '&:hover': {
      opacity: 1
    }
  }
}))

const VideoWithRef = React.forwardRef((props, ref) => (
  <Video {...props} outerRef={ref} />
))

export default () => {
  const classes = useStyles()
  const { videoPlayer, dispatchVideoPlayer } = useContext(VideoPlayerContext)
  const playerRef = useRef(null)
  const url = get(videoPlayer, 'url', null)

  const handleClose = useCallback(
    e => {
      const element = e.target
      if (element.classList.contains('close-trigger')) {
        const onClose = get(videoPlayer, 'onClose', null)
        if (typeof onClose === 'function') {
          onClose()
        }
        const pause = get(playerRef, 'current.video.pause', null)
        if (typeof pause === 'function') {
          pause()
        }
        dispatchVideoPlayer({ type: 'CLOSE_VIDEO' })
      }
    },
    [videoPlayer, dispatchVideoPlayer]
  )

  return (
    <Backdrop
      className={cx(classes.backdrop, 'close-trigger')}
      open={url !== null}
      onClick={handleClose}
    >
      <div className={classes.root}>
        <VideoWithRef videoUrl={url} ref={playerRef} />
      </div>
      <span className={cx(classes.closeButton, 'close-trigger')}>
        <CloseRoundedIcon className='close-trigger' />
      </span>
    </Backdrop>
  )
}

export const VideoPlayerTrigger = ({ url, onClose }) => {
  const { dispatchVideoPlayer } = useContext(VideoPlayerContext)
  useEffect(() => {
    dispatchVideoPlayer({ type: 'OPEN_VIDEO', url, onClose })
    return () => {
      dispatchVideoPlayer({ type: 'CLOSE_VIDEO' })
    }
  }, [dispatchVideoPlayer, url, onClose])
  return null
}
