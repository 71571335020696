import { RouteType } from 'types/route'

// REFACTOR: makeTabs
const makeTabs = (tabs: RouteType[]) => (root: RouteType | null = null) => {
  const result = tabs.map(tab => {
    return {
      ...tab,
      path: root === null ? tab.path : `${root.path}${tab.path}`
    }
  })
  return result
}

export default makeTabs
