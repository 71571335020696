import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: '40px',
    lineHeight: '24px',
    padding: '0 8px 0 16px',
    '&$expanded': {
      minHeight: '40px'
    },
    '& + div > div': {
      maxHeight: 'calc(100vh - 150px)',
      overflow: 'auto'
    }
  },
  content: {
    fontWeight: 'bold',
    margin: '0',
    '&$expanded': {
      margin: '0'
    }
  },
  expandIcon: {
    marginRight: 0,
    padding: '8px',
    color: '#000'
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export default ExpansionPanelSummary
