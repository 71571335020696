import React, { useContext } from 'react'

import { ConfigContext } from 'contexts/Config'

import { DropbackTrigger } from 'components/layouts/Dropback'

const ConfigWrapper = ({ children }) => {
  const { config } = useContext(ConfigContext)

  if (config.isConfigReady) {
    return children
  }
  return <DropbackTrigger />
}

export default ConfigWrapper
