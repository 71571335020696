import React from 'react'
import {
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core'

const InputWithIcon = ({
  placeholder,
  value,
  type,
  autoComplete,
  Icon,
  className,
  onChange
}) => {
  return (
    <OutlinedInput
      placeholder={placeholder}
      value={value}
      type={type}
      autoComplete={autoComplete}
      fullWidth={true}
      startAdornment={<InputAdornment position='start'>{Icon}</InputAdornment>}
      required={true}
      className={className}
      onChange={onChange}
    />
  )
}

export default InputWithIcon