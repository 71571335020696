import React from 'react'
import makeTabsHelper from 'helpers/Route/makeTabs'
import { RouteType } from 'types/route'

const AccountList = React.lazy(
  () => import('projects/AccountMgt/components/pages/AccountList')
)
const Department = React.lazy(
  () => import('projects/AccountMgt/components/pages/Department')
)
const Member = React.lazy(
  () => import('projects/AccountMgt/components/pages/Member')
)

export const tabs: RouteType[] = [
  {
    path: '/',
    title: 'Account Management',
    key: 'account-mgt',
    contentComponent: AccountList,
    exact: true,
    showInBreadcrumbs: false,
    subRoutes: []
  },
  {
    path: '/edit-department',
    title: 'Edit Department',
    key: 'account-mgt',
    contentComponent: Department,
    exact: true,
    showInTabs: false,
    subRoutes: []
  },
  {
    path: '/create-department',
    title: 'Create Department',
    key: 'account-mgt',
    contentComponent: Department,
    exact: true,
    subRoutes: []
  },
  {
    path: '/edit-member',
    title: 'Edit Member',
    key: 'account-mgt',
    contentComponent: Member,
    exact: true,
    showInTabs: false,
    subRoutes: []
  },
  {
    path: '/create-member',
    title: 'Create Member',
    key: 'account-mgt',
    contentComponent: Member,
    exact: true,
    subRoutes: []
  }
]

export const makeTabs = makeTabsHelper(tabs)
