import request, { BASE_URL, getAccessToken } from 'apis/request'

export const getNotificationListApi = async (
  { params = {}, data = {}, path = {} } = { params: {}, data: {}, path: {} }
) => {
  const access_token = await getAccessToken()
  if(access_token === null || access_token.length < 1){
    return getNotificationListApi({params, data, path})
  }
  return request('GET', `${BASE_URL}/v1/notification/list`, {
    params,
    data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'content-type': 'application/json'
    }
  })
}

export const postNotificationReadApi = async (
  { params = {}, data = {}, path = {} } = { params: {}, data: {}, path: {} }
) => {
  const access_token = await getAccessToken()
  return request('POST', `${BASE_URL}/v1/notification/read`, {
    params,
    data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'content-type': 'application/json'
    }
  })
}

export const postNotificationConfirmApi = async (
  { params = {}, data = {}, path = {} } = { params: {}, data: {}, path: {} }
) => {
  const access_token = await getAccessToken()
  return request('POST', `${BASE_URL}/v1/notification/confirm`, {
    params,
    data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'content-type': 'application/json'
    }
  })
}
