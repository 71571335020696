import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useRef
} from 'react'
import { from } from 'rxjs'
import _ from 'lodash'

import { ConfigReducer } from './Reducer'
import { AuthContext } from 'contexts/Auth'
import { getBiConfigApi } from 'apis/config'
import { configDataService } from 'services/Config'

export const ConfigContext = createContext()
ConfigContext.displayName = 'Config'

export const ConfigContextProvider = props => {
  const { auth } = useContext(AuthContext)
  const [config, dispatchConfig] = useReducer(
    ConfigReducer,
    {
      isConfigReady: false
    },
    state => {
      return state
    }
  )
  const fetcherRef = useRef(null)
  const videoConfigSubscription = useRef(null)

  useEffect(() => {
    if (
      auth.isLoggedIn &&
      !config.isConfigReady &&
      fetcherRef.current === null
    ) {
      fetcherRef.current = from(getBiConfigApi()).subscribe(response => {
        // DEBUG: key person video feature options missing "style" additional feature
        // try {
        //   const debugTarget =
        //     response.data.video_feature_options.features[0].children[0]
        //       .children[0]
        //   console.log('debug getBiConfigApi: ', response.data)
        //   console.log('debug getBiConfigApi: ', debugTarget)
        //   console.log('debug getBiConfigApi: ', JSON.stringify(debugTarget))
        // } catch {}
        const data = _.get(response, 'data', null)
        if (data !== null) {
          const data = _.get(response, 'data', {})
          const playseeTypes = _.get(data, 'playsee_types', {})
          const appEvents = _.get(data, 'app_events', {})
          const logoOptions = _.get(data, 'logo_options', [])
          const adsBuilderEvents = _.get(data, 'ads_builder_events', {})
          const text_emotion_options= _.get(data, 'text_emotion_options', {})
          const text_category_options= _.get(data, 'text_category_options', {})
          const review_community_options= _.get(data, 'review_community_options', {})
          const review_text_options= _.get(data, 'review_text_options', {})
          const review_profile_options= _.get(data, 'review_profile_options', {})

          const findParentPermission = (
            code,
            arr,
            index = 1,
            parentPermission = undefined
          ) => {
            const layers = code.split('.')
            const _code = layers.slice(0, index).join('.')

            const _parentPermission = arr.find(item => item.code === _code)
            if (typeof _parentPermission === 'undefined') {
              return parentPermission
            }

            return findParentPermission(
              code,
              _.get(_parentPermission, 'children', []),
              index + 1,
              _parentPermission
            )
          }

          const findParentFeature = (
            key,
            arr,
            index = 1,
            parentFeature = undefined
          ) => {
            const layers = key.split('.')
            const _key = layers.slice(0, index).join('.')

            const _parentFeature = arr.find(item => item.key === _key)
            if (typeof _parentFeature === 'undefined') {
              return parentFeature
            }

            return findParentFeature(
              key,
              _.get(_parentFeature, 'children', []),
              index + 1,
              _parentFeature
            )
          }

          const makeReviewVideoTreeOptions = array => {
            return array.reduce(
              (object, feature) => {
                const key = _.get(feature, 'key', null)
                // const label = _.get(feature, 'label', null)
                let treeOptions = [...object.treeOptions]
                let treeOptionsTable = {
                  ...object.treeOptionsTable
                }

                if (key !== null) {
                  const parentFeature = findParentFeature(key, treeOptions)
                  if (typeof parentFeature === 'undefined') {
                    treeOptionsTable[key] = {
                      ...feature
                    }

                    treeOptions = treeOptions.concat({
                      ...feature,
                      id: key,
                      children: []
                    })
                  } else {
                    treeOptionsTable[key] = {
                      ...feature
                    }

                    if (parentFeature.key !== feature.key) {
                      parentFeature.children = _.get(
                        parentFeature,
                        'children',
                        []
                      ).concat({
                        ...feature,
                        id: key,
                        children: []
                      })
                    }
                  }
                }
                return { ...object, treeOptions, treeOptionsTable }
              },
              { treeOptionsTable: {}, treeOptions: [] }
            )
          }

          const sortedPermissions = _.get(data, 'permissions', []).sort((a, b) => {
            const wordCountA = a.code.includes('.') ? (a.code.match(/\./g) || []).length : 1;
            const wordCountB = b.code.includes('.') ? (b.code.match(/\./g) || []).length : 1;
            return wordCountA - wordCountB;
          });
          
          const permissions = sortedPermissions.reduce(
            (arr, permission) => {
              const code = _.get(permission, 'code', null)
              if (code !== null) {
                const parentPermission = findParentPermission(code, arr)
                if (typeof parentPermission === 'undefined') {
                  arr = arr
                    .concat({
                      ...permission,
                      id: _.get(permission, 'permission_id', null),
                      children: []
                    })
                    .sort((a, b) =>
                      _.get(a, 'sort_number', 0) >= _.get(b, 'sort_number', 0)
                        ? 1
                        : -1
                    )
                } else {
                  parentPermission.children = _.get(
                    parentPermission,
                    'children',
                    []
                  )
                    .concat({
                      ...permission,
                      id: _.get(permission, 'permission_id', null),
                      children: []
                    })
                    .sort((a, b) =>
                      _.get(a, 'sort_number', 0) >= _.get(b, 'sort_number', 0)
                        ? 1
                        : -1
                    )
                }
              }
              return arr
            },
            []
          )
          
          const feature_options = _.get(data, 'feature_options', [])
          const old_feature_options = _.get(data, 'old_feature_options', [])

          const sortFeatureOptions = (_feature_options, levels) => {
            levels.reduce(
              (_pathKey, key) => {
                const sort = Object.keys(
                  _pathKey.path.length === 0
                    ? _feature_options
                    : _.get(_feature_options, `${_pathKey.path}`, {})
                ).length

                _pathKey.path =
                  _pathKey.path.length === 0 ? key : `${_pathKey.path}.${key}`
                _pathKey.key =
                  _pathKey.key.length === 0 ? key : `${_pathKey.key}.${key}`

                if (_.get(_feature_options, _pathKey.path, null) === null) {
                  _.set(_feature_options, _pathKey.path, {
                    name: key,
                    label: _.get(
                      feature_options.find(
                        option => option.key === _pathKey.key
                      ),
                      'label',
                      'Unknown'
                    ),
                    hover: {
                      zh: _.get(
                        feature_options.find(
                          option => option.key === `${_pathKey.key}.hover.zh`
                        ),
                        'label',
                        null
                      ),
                      en: _.get(
                        feature_options.find(
                          option => option.key === `${_pathKey.key}.hover.en`
                        ),
                        'label',
                        null
                      )
                    },
                    key: _pathKey.key,
                    children: {},
                    sort
                  })
                }
                _pathKey.path = `${_pathKey.path}.children`
                return _pathKey
              },
              { path: '', key: '' }
            )
            return _feature_options
          }
          const coverFeatureOptions = _.get(data, 'cover_feature_options', [])
          const adsTextFeatureOptions = _.get(data, 'ads_feature_options', [])
          const user_terms_of_service_subject = _.get(
            data,
            'user_terms_of_service_subject',
            []
          )
          const video_feature_options = makeReviewVideoTreeOptions(
            _.get(data, 'feature_options', [])
          )

          const ai_video_feature_options =
            _.get(data, 'video_feature_options', []) ?? []

          const cover_feature_options =
            makeReviewVideoTreeOptions(coverFeatureOptions)

          const ads_text_feature_options = makeReviewVideoTreeOptions(
            adsTextFeatureOptions
          )
          const ads_feature_options = makeReviewVideoTreeOptions(
            coverFeatureOptions.map(item => {
              return {
                key: _.get(item, 'key', null),
                label: _.get(item, 'label', null),
                isFeature: _.get(item, 'isFeature', false)
              }
            })
          )

          const review_video_options = {
            ..._.get(data, 'review_video_options', {}),
            // RV_stage_1_video_options: makeReviewVideoTreeOptions(
            //   _.get(data, 'review_video_options.RV_stage_1_video_options', [])
            // ),
            RV_stage_2_video_options: makeReviewVideoTreeOptions(
              _.get(data, 'review_video_options.RV_stage_2_video_options', [])
            ),
            BA_stage_2_video_options: makeReviewVideoTreeOptions(
              _.get(data, 'review_video_options.BA_stage_2_video_options', [])
            ),
            RC_stage_1_options: makeReviewVideoTreeOptions(
              _.get(data, 'review_video_options.RC_stage_1_options', [])
            )
          }

          const ads_industrial_category_options = makeReviewVideoTreeOptions(
            _.get(data, 'industrial_category_options', [])
          )

          const { old_video_feature_options, old_cover_feature_options } =
            old_feature_options
              .filter(
                option =>
                  option.key.indexOf('.hover.en') < 0 &&
                  option.key.indexOf('.hover.zh') < 0
              )
              .reduce(
                (options, option) => {
                  const levels = option.key.split('.')
                  if (option.key.indexOf('cover_photo') === 0) {
                    options.cover_feature_options = sortFeatureOptions(
                      options.cover_feature_options,
                      levels
                    )
                  } else {
                    options.video_feature_options = sortFeatureOptions(
                      options.video_feature_options,
                      levels
                    )
                  }
                  return options
                },
                { old_video_feature_options: {}, old_cover_feature_options: {} }
              )

          const currency_options = _.get(data, 'currency_options', []).reduce(
            (obj, item) => {
              const updatedObj = { ...obj }
              const key = _.get(item, 'key', '')
              const label = _.get(item, 'label', '')
              const symbol = _.get(item, 'currency_symbol', '')
              const combinedLabel = `${label}(${key}, ${symbol})`
              updatedObj.currencyOptions.push({
                value: key,
                label: combinedLabel
              })
              updatedObj.currencyOptionsTable[key] = {
                exchangeRate: _.get(item, 'exchange_rate', 0),
                label: combinedLabel
              }
              return updatedObj
            },
            { currencyOptions: [], currencyOptionsTable: {} }
          )

          const review_question_options = {
            RQ_stage_1_question_options: makeReviewVideoTreeOptions(
              _.get(
                data,
                'review_question_options.RQ_stage_1_question_options',
                []
              )
            ),
            RQ_stage_2_question_options: makeReviewVideoTreeOptions(
              _.get(
                data,
                'review_question_options.RQ_stage_2_question_options',
                []
              )
            ),
            RQ_stage_1_potential_customers_options: _.get(
              data,
              'review_question_options.RQ_stage_1_potential_customers_options',
              []
            )
          }

          const question_feature_options = makeReviewVideoTreeOptions(
            _.get(data, 'question_feature_options', []) ?? []
          )

          const question_intent_options = _.get(
            data,
            'question_intent_options',
            []
          )

          const question_stage1_entity = _.get(
            data,
            'question_stage1_entity',
            []
          )

          const review_comment_options = {
            RM_stage_1_comment_options: makeReviewVideoTreeOptions(
              _.get(
                data,
                'review_comment_options.RM_stage_1_comment_options',
                []
              )
            ),
            RM_stage_2_comment_options: makeReviewVideoTreeOptions(
              _.get(
                data,
                'review_comment_options.RM_stage_2_comment_options',
                []
              )
            )
          }

          // the key same as review question
          const comment_feature_options = makeReviewVideoTreeOptions(
            _.get(data, 'question_feature_options', []) ?? []
          )

          // the key same as review question
          const comment_intent_options = _.get(
            data,
            'question_intent_options',
            []
          )

          // the key same as review question
          const comment_stage1_entity = _.get(
            data,
            'question_stage1_entity',
            []
          )

          currency_options.priceRange = _.get(data, 'price_range_options', [])

          dispatchConfig({
            type: 'SET_DATA',
            data: {
              servers: {
                ..._.get(data, 'servers', {})
              },
              playseeTypes: Object.values(playseeTypes)
                .reduce((arr, ptypes) => {
                  return arr.concat(ptypes)
                }, [])
                .sort(),
              playseeTypesByGTypes: Object.keys(playseeTypes).reduce(
                (arr, gtype) => {
                  arr[gtype] = playseeTypes[gtype].sort()
                  return arr
                },
                {}
              ),
              appEvents,
              adsBuilderEvents,
              logo_options: logoOptions,
              permissions,
              video_feature_options,
              ai_video_feature_options,
              cover_feature_options,
              old_video_feature_options,
              old_cover_feature_options,
              ads_feature_options,
              ads_text_feature_options,
              review_video_options,
              ads_industrial_category_options,
              currency_options,
              review_question_options,
              question_intent_options,
              question_feature_options,
              question_stage1_entity,
              review_comment_options,
              comment_intent_options,
              comment_feature_options,
              comment_stage1_entity,
              text_emotion_options,
              text_category_options,
              review_community_options,
              review_text_options,
              review_profile_options,
              user_terms_of_service_subject,
              server_versions: _.get(data, 'server_versions', {}),
              review_profile_options: _.get(data, 'review_profile_options', []),
            }
          })
          dispatchConfig({
            type: 'SET_CONFIG_READY',
            isConfigReady: true
          })
        }
        fetcherRef.current = null
      })
    }
    return () => {}
  }, [auth, config, dispatchConfig])

  useEffect(() => {
    videoConfigSubscription.current = configDataService
      .listener()
      .subscribe(event => {
        if (event.action === 'config_data_get') {
          const key = _.get(event, 'key', null)
          if (key === null) {
            configDataService.giveConfigData({
              key,
              value: config
            })
          } else {
            configDataService.giveConfigData({
              key,
              value: _.get(config, `data.${key}`, null)
            })
          }
        }
      })
    return () => {
      if (videoConfigSubscription.current) {
        videoConfigSubscription.current.unsubscribe()
      }
    }
  }, [videoConfigSubscription, config])
  useEffect(() => {
    return () => {
      if (fetcherRef.current) {
        fetcherRef.current.unsubscribe()
      }
    }
  }, [])

  return (
    <ConfigContext.Provider value={{ config, dispatchConfig }}>
      {props.children}
    </ConfigContext.Provider>
  )
}
