import get from 'lodash/get'

export const ConfigReducer = (state, action) => {
  const type = get(action, 'type', null)
  if (type === null) {
    return state
  }

  if (type === 'SET_DATA') {
    const data = get(action, 'data', null)
    return {
      ...state,
      data
    }
  }

  if (type === 'SET_CONFIG_READY') {
    const isConfigReady = get(action, 'isConfigReady', false)
    return {
      ...state,
      isConfigReady
    }
  }
  return state
}
