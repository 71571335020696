import React, { createContext } from 'react'

export const RouteContext = createContext()
RouteContext.displayName = 'Route'

export const RouteContextProvider = ({ route, routes, children }) => {
  return (
    <RouteContext.Provider value={{ route, routes }}>
      {children}
    </RouteContext.Provider>
  )
}
