import get from 'lodash/get'

export const NotificationReducer = (state, action) => {
  const type = get(action, 'type', null)
  if(type === null) {
    return state
  }
  if(type === 'SET_NOTIFICATION'){
    const data = get(action, 'data', null)
    return {
      ...state,
      data
    }
  }
  if(type === 'SET_NOTIFICATION_ORDER'){
    const order = get(action, 'order', null)
    return {
      ...state,
      order
    }
  }
  if(type === 'SET_READ_IDS'){
    const id = get(action, 'id', [])
    const readIds = [...state.readIds, id]
    return {
      ...state,
      readIds
    }
  }

  return state
}