import React, { createContext, useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router'
import { AuthContext } from 'contexts/Auth'
import { getDefaultSmallFilterListApi } from 'apis/smallFilter'
import { SMALL_FILTER_DEFAULT_CONTEXT_VALUE } from 'config/constansts/SmallFilter'

const DASHBOARD = 'dashboard'

export const SmallFilterContext = createContext()
SmallFilterContext.displayName = 'SmallFilter'

export const SmallFilterContextProvider = ({ children }) => {
  const { auth } = useContext(AuthContext)
  const location = useLocation()
  const [smallFilterIdsMap, setSmallFilterIdsMap] = useState({})
  const dataFormat = data => {
    return Object.keys(data).reduce(
      (cur, key) => ({
        ...cur,
        [key]: { id: data[key], reload: false }
      }),
      {}
    )
  }

  const isInDashboard = location.pathname.includes(DASHBOARD)

  useEffect(() => {
    if (auth.isLoggedIn && isInDashboard) {
      getDefaultSmallFilterListApi({
        params: { filter_type: 'overview' }
      }).then(({ data }) => {
        if (data?.filters) {
          setSmallFilterIdsMap({
            ...SMALL_FILTER_DEFAULT_CONTEXT_VALUE,
            ...dataFormat(data.filters)
          })
        }
      })
    }
  }, [auth, isInDashboard])

  return (
    <SmallFilterContext.Provider
      value={{ smallFilterIdsMap, setSmallFilterIdsMap }}
    >
      {children}
    </SmallFilterContext.Provider>
  )
}
