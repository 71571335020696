import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'clsx'

const useStyle = makeStyles(theme => ({
  container: {

  },
  loadingIcon: {

  top:'50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1200
  }
}))

const ICON_SIZE_TABLE = {
  large: 60,
  medium: 40,
  small: 20
}

const LoadingContainer = ({
  iconSize = 'medium',
  children,
  style = {},
  colorMode = 'light',
  loading = false
}) => {
  const classes = useStyle()
  return (
    <div className={cx(classes.container, loading && 'loading')} style={style}>
      {loading ? (
        <div className={classes.loadingIcon}>
          <CircularProgress size={ICON_SIZE_TABLE[iconSize]} />
        </div>
      ) : null}
      {children}
    </div>
  )
}

export default LoadingContainer
