import { Dashboard } from 'types/chartEnum'

const { Overview } = Dashboard

const MODAL_TYPE = {
  OVERVIEW: {
    SMALL_FILTER: {
      [Overview.RecentGoodVideoCount]: 'recent_good_video_count',
      [Overview.RecentGoodVideoCountSeeUser]: 'recent_good_video_count',
      [Overview.RecentGoodVideoFrequency]: 'recent_good_video_freq',
      [Overview.RecentGoodVideoFrequencySeeUser]: 'recent_good_video_freq',
      [Overview.RecentOpenAppFrequency]: 'recent_open_app_freq',
      [Overview.RecentOpenAppFrequencySeeUser]: 'recent_open_app_freq',
      [Overview.FollowerGrowthRate]: 'follower_growth_rate',
      [Overview.FollowerGrowthRateSeeUser]: 'follower_growth_rate',
      [Overview.ProfileCompletionRate]: 'profile_completion_rate',
      [Overview.ProfileCompletionRateSeeUser]: 'profile_completion_rate',
      [Overview.EngagementRateReceive]: 'engagement_rate_receive',
      [Overview.EngagementRateReceiveSeeUser]: 'engagement_rate_receive',
      [Overview.EngagementRateGive]: 'engagement_rate_give',
      [Overview.EngagementRateGiveSeeUser]: 'engagement_rate_give',
      [Overview.PotentialPayingUser]: 'potential_paying_user',
      [Overview.PotentialPayingUserSeeUser]: 'potential_paying_user',
      [Overview.RetentionRateNewUser]: 'retention_rate_new_user',
      [Overview.RetentionRateNewCreator]: 'retention_rate_new_creator',
      [Overview.RetentionRateNewOa]: 'retention_rate_new_oa',
      [Overview.DailyActiveViewer]: 'daily_active_viewer'
    } as Record<Dashboard.Overview, string>
  }
}
export default MODAL_TYPE
