import React, { useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import _ from 'lodash'

import { tabs as _menuRoutes } from 'config/Routes'
import { usePermission } from 'contexts/Auth'

const useLinkStyle = makeStyles(theme => ({
  link: {
    textDecoration: 'underline',
    color: theme.custom.colors.blue1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.colors.blue2
    }
  }
}))

const RouteWrapper = React.memo(({ children, routes }) => {
  const [routeIndex, setRouteIndex] = React.useState(null)
  const location = useLocation()
  const history = useHistory()

  const menuRoutes = useMemo(() => {
    return routes.filter(route =>
      _menuRoutes.some(_route => route.path === _route.path)
    )
  }, [routes])

  useEffect(() => {
    for (let index in routes) {
      const route = routes[index]
      if (route.path === location.pathname) {
        setRouteIndex(parseInt(index))
        return () => {}
      }
    }

    // if no matched, redirect to default page
    history.replace({
      pathname: routes[0].path
    })
    return () => {}
  }, [location, history, routes])

  if (routeIndex === null) {
    return null
  }

  return typeof children === 'function'
    ? children({ routes, routeIndex, menuRoutes })
    : children
})

export default ({ children, routes }) => {
  const {
    auth: { Logout },
    verifyPermissions
  } = usePermission()

  const authenticatedRoutes = useMemo(() => {
    return routes.reduce((result, currentRouteItem) => {
      const targetPermissions = [..._.get(currentRouteItem, 'permissions', [])]
      // temp code for developing without permissions
      if (_.get(currentRouteItem, 'key', '') === 'financial')
        result.push(currentRouteItem)

      if (verifyPermissions(targetPermissions)) {
        result.push(currentRouteItem)
      }
      return result
    }, [])
  }, [verifyPermissions, routes])

  if (authenticatedRoutes.length === 0) {
    const classes = useLinkStyle()
    return (
      <div>
        401 - Permission Denied
        <br></br>
        <span className={classes.link} onClick={Logout}>
          Logout
        </span>
      </div>
    )
  }

  return <RouteWrapper children={children} routes={authenticatedRoutes} />
}
