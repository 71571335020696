import React, { createContext, useReducer, useEffect } from 'react'

import { VideoPlayerReducer } from './Reducer'

export const VideoPlayerContext = createContext()
VideoPlayerContext.displayName = 'VideoPlayer'

export const VideoPlayerContextProvider = props => {
  useEffect(() => {
    return () => {}
  }, [])

  const [videoPlayer, dispatchVideoPlayer] = useReducer(VideoPlayerReducer, {
    url: null
  })

  return (
    <VideoPlayerContext.Provider value={{ videoPlayer, dispatchVideoPlayer }}>
      {props.children}
    </VideoPlayerContext.Provider>
  )
}
