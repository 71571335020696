import React, { useEffect, useContext } from 'react'
import get from 'lodash/get'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { DropbackContext } from './Context'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

export default () => {
  const classes = useStyles()
  const { dropback } = useContext(DropbackContext)

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={get(dropback, 'open', false)}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

export const DropbackTrigger = () => {
  const { dispatchDropback } = useContext(DropbackContext)
  useEffect(() => {
    dispatchDropback({ type: 'OPEN_DROPBACK' })
    return () => {
      dispatchDropback({ type: 'CLOSE_DROPBACK' })
    }
  }, [dispatchDropback])
  return null
}
