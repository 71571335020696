import { Subject } from 'rxjs'

export enum AuthTokenAction {
  GIVE = 'auth_token_give',
  GET = 'auth_token_get'
}

interface AuthTokenType {
  action: AuthTokenAction
  access_token?: string | null
}

const authTokenSubject = new Subject<AuthTokenType>()

export const authTokenService = {
  giveAuthToken: (access_token: string | null = null) =>
    authTokenSubject.next({
      action: AuthTokenAction.GIVE,
      access_token
    }),
  getAuthToken: () =>
    authTokenSubject.next({
      action: AuthTokenAction.GET
    }),
  listener: () => authTokenSubject.asObservable()
}

export enum TokenRenewAction {
  RENEW = 'auth_token_renew',
  RECEIVED = 'auth_token_received'
}

interface TokenRenewType {
  action: TokenRenewAction
  expired_token?: string | null
}

const tokenRenewSubject = new Subject<TokenRenewType>()

export const tokenRenewService = {
  renewToken: (expired_token: string | null = null) =>
    tokenRenewSubject.next({
      action: TokenRenewAction.RENEW,
      expired_token
    }),
  newTokenReceived: () =>
    tokenRenewSubject.next({
      action: TokenRenewAction.RECEIVED
    }),
  listener: () => tokenRenewSubject.asObservable()
}
