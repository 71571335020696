import React, { useContext, useState } from 'react'
import _ from 'lodash'
import cx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorIcon from '@material-ui/icons/Error'
import CheckIcon from '@material-ui/icons/Check'
import Tooltip from '@material-ui/core/Tooltip'

import { BatchExportContext } from 'contexts/BatchExport'

import { CloseIcon } from 'helpers/Icons'
import Progress from 'components/blocks/Progress'

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from './layout'

const JobList = () => {
  const classes = useStyles()
  const { batchJobs, cancelBatchJob } = useContext(BatchExportContext)

  const [isExpanded, setIsExpanded] = useState(true)

  const batchJobsInProgress = batchJobs.filter(
    batchJob => _.get(batchJob, 'result', null) === null
  )

  const batchJobsCompleted = batchJobs.filter(
    batchJob => _.get(batchJob, 'result', null) !== null
  )

  if (batchJobs.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        elevation={3}
        expanded={isExpanded}
        onChange={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {batchJobsInProgress.length > 0 &&
            `${batchJobsInProgress.length} tasks in progress, `}
          {batchJobsCompleted.length > 0 &&
            `${batchJobsCompleted.length} tasks completed`}
        </ExpansionPanelSummary>
        {batchJobs &&
          batchJobs.map(({ key, batchFileName, isFetching, result, isFailed }) => {
            return (
              <ExpansionPanelDetails key={key}>
                <div className='loading'>
                  {isFetching && <Progress colortype='outlined' />}
                  {!isFailed && result !== null && (
                    <CheckIcon color='primary' />
                  )}
                  {isFailed && <ErrorIcon color='error' />}
                </div>
                <div>
                  <div className='title'>
                    <Tooltip
                      title={batchFileName}
                      placement='left'
                      TransitionProps={{ timeout: 1 }}
                    >
                      <span>{batchFileName}</span>
                    </Tooltip>
                  </div>
                  <div
                    className={cx(
                      'status',
                      'noSelect',
                      // REFACTOR: use xstate actor to refactor
                      {
                        preparing: isFetching,
                        complete: result !== null,
                        pending: !isFetching && result === null,
                        failed: isFailed
                      }
                    )}
                  >
                    {isFetching && 'preparing...'}
                    {!isFetching && result === null && 'pending'}
                    {!isFailed && result !== null && 'complete'}
                    {!isFetching && isFailed && 'failed'}
                  </div>
                </div>
                <div className='action'>
                  <span
                    onClick={() => {
                      cancelBatchJob(key)
                    }}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </ExpansionPanelDetails>
            )
          })}
      </ExpansionPanel>
    </div>
  )
}

export default JobList
