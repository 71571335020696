import React, { createContext } from 'react'

interface ExportDataCatcher {
  exportData: {}
  dispatchExportData: () => void
}

export const ExportDataCatcherContext = createContext<ExportDataCatcher>({
  exportData: {},
  dispatchExportData: () => {
    console.log('No ExportDataContextProvider Found')
  }
})
ExportDataCatcherContext.displayName = 'ExportDataCatcher'

export const ExportDataCatcherContextProvider: React.FC = props => {
  return (
    <ExportDataCatcherContext.Provider
      value={{
        exportData: {},
        dispatchExportData: () => {
          console.log('No ExportDataContextProvider Found')
        }
      }}
    >
      {props.children}
    </ExportDataCatcherContext.Provider>
  )
}
