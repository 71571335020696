import React from 'react'
import MUButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import PropTypes from 'prop-types'

/**
 * @param {number} size
 * @param {string} addLabel
 * @param {string} deleteLabel
 * @param {func} handleAdd
 * @param {func} handleDelete
 * @param {index of current row, type: number} index
 * @param {total length of rows, type: number} totalLength
 * @param {string} classes
 * @param {boolean} disabled
 * @param {limit count for add next row, type: number} addCount
 * @param {key of delete | string} key
 * @param {id of delete, for calling api | string} id
 */

const StyledButton = withStyles(() => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      textTransform: 'none'
    }
  }
}))(MuiButton)

const AddDeleteButtonGroup = props => {
  const {
    size,
    addLabel,
    deleteLabel,
    handleAdd,
    handleDelete,
    index,
    totalLength,
    classes,
    addCount,
    target,
    id,
    disabled
  } = props

  const isAddShow =
    totalLength > 0 &&
    index === totalLength - 1 &&
    (addCount ? index < addCount - 1 : true)
  const isDeleteShow = totalLength > 1 ? true : index !== 0

  if (disabled) return null

  return (
    <MUButtonGroup {...{ size, classes: { root: classes } }}>
      {isAddShow && <StyledButton onClick={handleAdd}>{addLabel}</StyledButton>}
      {isDeleteShow && (
        <StyledButton onClick={() => handleDelete(target, id)}>
          {deleteLabel}
        </StyledButton>
      )}
    </MUButtonGroup>
  )
}

AddDeleteButtonGroup.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  classes: PropTypes.string,
  addLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  totalLength: PropTypes.number.isRequired,
  addCount: PropTypes.number,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool.isRequired
}

AddDeleteButtonGroup.defaultProps = {
  size: 'default',
  addLabel: 'Add',
  deleteLabel: 'Delete',
  classes: '',
  handleAdd: () => {},
  handleDelete: () => {},
  disabled: false
}

export default AddDeleteButtonGroup
