import { ExportRuleType } from 'types/export'

interface ExportDataReducerState {
  fileName: string
  rules: ExportRuleType[]
  data: {
    [key: string]: unknown
  }
}

export enum ExportDataReducerActionType {
  CLEAR_DATA = 'CLEAR_DATA',
  SET_DATA = 'SET_DATA'
}

export type ExportDataReducerAction =
  | {
      type: ExportDataReducerActionType.CLEAR_DATA
    }
  | {
      type: ExportDataReducerActionType.SET_DATA
      key: string
      data: { [key: string]: unknown }
    }

export const ExportDataReducer = (
  state: ExportDataReducerState,
  action: ExportDataReducerAction
): ExportDataReducerState => {
  switch (action.type) {
    case ExportDataReducerActionType.CLEAR_DATA: {
      return {
        ...state,
        data: {}
      }
    }
    case ExportDataReducerActionType.SET_DATA: {
      const { rules, data } = state
      const { key, data: value } = action
      if (rules.find(rule => rule.key === key)) {
        data[key] = value
        return {
          ...state,
          data
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}
