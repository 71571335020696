import request, { BASE_URL, getAccessToken } from 'apis/request'

export const getDefaultSmallFilterListApi = async (
  { params = {}, data = {} } = { params: {}, data: {} }
) => {
  const access_token = await getAccessToken()
  return request('GET', `${BASE_URL}/v1/small_filter/default`, {
    params,
    data,
    headers:
      access_token === null
        ? {
            'content-type': 'application/json'
          }
        : {
            Authorization: `Bearer ${access_token}`,
            'content-type': 'application/json'
          }
  })
}

export const getSmallFilterDetailApi = async (
  { params = {}, data = {} } = { params: {}, data: {} }
) => {
  const access_token = await getAccessToken()
  return request('GET', `${BASE_URL}/v1/small_filter/list`, {
    params,
    data,
    headers:
      access_token === null
        ? {
            'content-type': 'application/json'
          }
        : {
            Authorization: `Bearer ${access_token}`,
            'content-type': 'application/json'
          }
  })
}

export const postSmallFilterUpdateApi = async (
  { params = {}, data = {} } = { params: {}, data: {} }
) => {
  const access_token = await getAccessToken()
  return request('POST', `${BASE_URL}/v1/small_filter/update`, {
    params,
    data,
    headers:
      access_token === null
        ? {
            'content-type': 'application/json'
          }
        : {
            Authorization: `Bearer ${access_token}`,
            'content-type': 'application/json'
          }
  })
}

export const postSmallFilterDeleteApi = async (
  { params = {}, data = {} } = { params: {}, data: {} }
) => {
  const access_token = await getAccessToken()
  return request('POST', `${BASE_URL}/v1/small_filter/delete`, {
    params,
    data,
    headers:
      access_token === null
        ? {
            'content-type': 'application/json'
          }
        : {
            Authorization: `Bearer ${access_token}`,
            'content-type': 'application/json'
          }
  })
}
