import {
  EXCEPTIONS_OPERATORS,
  EXCEPTIONS_KEYS,
  EXCEPTIONS_KEYS_OPERATORS,
  NUMBER_FORMAT_KEYS
} from 'config/constansts/SmallFilter'
import MODAL_TYPE from 'config/constansts/ModalType'
import { Dashboard } from 'types/chartEnum'

const isObject = obj => typeof obj === 'object' && !Array.isArray(obj)

const formatOperator = (item, key) => {
  if (EXCEPTIONS_OPERATORS.hasOwnProperty(key)) return EXCEPTIONS_OPERATORS[key]
  if (EXCEPTIONS_KEYS_OPERATORS['operator'].includes(key))
    return item['operator']
  if (EXCEPTIONS_KEYS_OPERATORS['other_operator'].includes(key))
    return item['other_operator']
  return '$eq'
}

const formatPotentialPayingUserCondition = ({
  isPotentialPayingUser,
  returnValue,
  item,
  key
}) => {
  if (isPotentialPayingUser && key === 'query_type') {
    if (isObject(item[key])) {
      Object.entries(item[key]).forEach(([, conditionValue]) => {
        const selected = {
          userRange: 'userRange',
          count1: 'count',
          percent1: 'percent'
        }[item[key].value]

        if (conditionValue.field && conditionValue.field.includes(selected)) {
          returnValue.push({
            $or: [conditionValue]
          })
        }
      })
    }
  }
}

export const insertGraphTypeToPotentialPayingUser = (data, graph_type_id) =>
  Object.keys(data).reduce((cur, key) => {
    cur.push({
      ...data[key],
      graph_type_id: `${graph_type_id}:${key}`
    })
    return cur
  }, [])

const FormatSendingData = data => {
  const { Overview } = Dashboard
  const graph_type_id = Object.keys(data)[0]
  const isPotentialPayingUser =
    graph_type_id ===
    MODAL_TYPE.OVERVIEW.SMALL_FILTER[Overview.PotentialPayingUser]

  const filters = data[graph_type_id].map(item => {
    const conditionGroup = Object.keys(item).reduce((cur, key) => {
      if (!EXCEPTIONS_KEYS.includes(key)) {
        const condition = {
          $or: [
            {
              field: key,
              param: NUMBER_FORMAT_KEYS.includes(key)
                ? isNaN(item[key])
                  ? 0
                  : parseFloat(item[key] * 1)
                : item[key],
              operator: formatOperator(item, key)
            }
          ]
        }
        cur.push(condition)
      }

      formatPotentialPayingUserCondition({
        isPotentialPayingUser,
        returnValue: cur,
        item,
        key
      })

      return cur
    }, [])

    return {
      graph_type_id: item.graph_type_id ?? graph_type_id,
      ...(item.id ? { small_filter_id: item.id } : {}),
      is_default: item.is_default,
      is_group: isPotentialPayingUser,
      items: {
        $or: [
          {
            $and: conditionGroup
          }
        ]
      }
    }
  })

  return filters
}

export default FormatSendingData
