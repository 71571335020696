import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useComponentDidMount } from 'helpers/HookLifeCycle'

import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle as DefautlPlayButton,
  VolumeMenuButton
} from 'video-react'

import _ from 'lodash'
import cx from 'clsx'

const SECOND_CONTROL_DURATION = 400
const REPLAY_SECONDS = 10

const useStyles = makeStyles(theme => ({
  wrapper: {
    // border: '1px solid red', //DEBUG
    height: 'auto',
    width: '100%',
    '& > div': {
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '& > video': {
      objectFit: 'scale-down'
    },
    '&:hover': {
      '& .video-react-icon': {
        opacity: 1
      }
    },
    '&::before': {
      content: ' ',
      display: 'block',
      paddingTop: 'calc((100% - 81px) * 16 / 9)'
    },
    '& .video-react': {
      outline: 'none !important',
      width: props => (props.height !== null ? '100% !important' : null)
    }
  },
  control: {
    opacity: 0,
    position: 'absolute !important',
    // border: '1px solid green !important', //DEBUG
    width: '18% !important',
    top: '42% !important',
    height: '30% !important',
    zIndex: 1,
    transition: `opacity ${SECOND_CONTROL_DURATION}ms !important`,
    '&::before': {
      height: '20% !important',
      fontSize: '56px !important'
    },
    '&.replay': {
      left: '30px'
    },
    '&.forward': {
      right: '30px'
    }
  },
  playEnd: {
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzEiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzMSAzMSI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iczBhc2dhc2VsYSIgZD0iTTE2LjEgNy44NFY0LjQ4YzAtLjU0Mi0uNjU4LS44MDgtMS4wMzQtLjQyM2wtNC42MjEgNC41NjZjLS4yNDIuMjQxLS4yNDIuNjE1IDAgLjg1Nmw0LjYwOCA0LjU2NmMuMzkuMzczIDEuMDQ3LjEwOSAxLjA0Ny0uNDM0di0zLjM2YzQuNTM2IDAgOC4xMjMgNC4xMiA3LjEyNiA4Ljc4My0uNTcyIDIuNzM0LTIuODEgNC45MzktNS41NTcgNS41MDUtNC4zNDEuOTA0LTguMjEtMi4wNDgtOC43OTItNi4wMzYtLjA4Ni0uNTc4LS41OTctMS4wMjUtMS4xOTItMS4wMjUtLjcyOSAwLTEuMzEzLjY0LTEuMjE2IDEuMzYzLjc1NCA1LjI4OCA1LjgzNiA5LjIwNSAxMS41ODkgOC4wOTUgMy43OTQtLjczNSA2Ljg0Ni0zLjc1OSA3LjU4OC03LjUxN0MyNi44NSAxMy4yMzggMjIuMTA2IDcuODQgMTYuMSA3Ljg0Ii8+CiAgICA8L2RlZnM+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnPgogICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODEgLTYzNSkgdHJhbnNsYXRlKDgxIDYzNSkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzMVYzMUgweiIvPgogICAgICAgICAgICAgICAgPG1hc2sgaWQ9ImE3d3ZhNmg1emIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3MwYXNnYXNlbGEiLz4KICAgICAgICAgICAgICAgIDwvbWFzaz4KICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI3MwYXNnYXNlbGEiLz4KICAgICAgICAgICAgICAgIDxnIGZpbGw9IiNGRkYiIG1hc2s9InVybCgjYTd3dmE2aDV6YikiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wIDBIMzFWMzFIMHoiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDxnIGZpbGw9IiNGRkYiIG1hc2s9InVybCgjYTd3dmE2aDV6YikiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wIDBIMzFWMzFIMHoiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==) !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    '&::before': {
      content: 'attr(g) !important'
    }
  }
}))

const ReplayControlButton = ({
  type = 'replay',
  seconds = 10,
  playerRef,
  hasStarted = false
}) => {
  const classes = useStyles()

  const handleOnClick = () => {
    if (playerRef.current) {
      if (type === 'replay') {
        playerRef.current.replay(seconds)
      } else {
        playerRef.current.forward(seconds)
      }
    }
  }

  return hasStarted ? (
    <button
      className={cx(
        classes.control,
        `video-react-control video-react-button video-react-icon video-react-icon-${type}-${seconds} video-react-${type}-control ${type}`
      )}
      type='button'
      onClick={handleOnClick}
    >
      <span className='video-react-control-text'>{`${type} ${seconds} seconds`}</span>
    </button>
  ) : (
    <></>
  )
}

const PlayButton = ({ status, playerRef, isEnded }) => {
  const classes = useStyles()

  const handleOnClick = () => {
    if (playerRef.current) {
      if (status === 'pause') {
        if (isEnded) {
          playerRef.current.seek(0)
        }
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }
  return (
    <button
      className={cx(
        `video-react-play-control video-react-control video-react-button video-react-${status}`,
        isEnded ? classes.playEnd : ''
      )}
      type='button'
      onClick={handleOnClick}
    >
      <span className='video-react-control-text'>Play</span>
    </button>
  )
}

const Video = ({ outerRef = null, videoUrl, posterUrl=undefined,height = null | undefined}) => {
  const [videoState, setVideoState] = useState({})
  const isEnded = videoState.player && videoState.player.ended

  const pause = videoState.player && videoState.player.paused
  const hasStarted = _.get(videoState, 'player.hasStarted', false)
  const componentRef = useRef(null)
  const playerRef = outerRef === null ? componentRef : outerRef
  const classes = useStyles({ height })

  useComponentDidMount(() => {
    playerRef.current.volume = 0.5
    playerRef.current.subscribeToStateChange((state, prevState) => {
      return setVideoState({
        player: state,
        currentTime: state.currentTime
      })
    })
  })

  useEffect(() => {}, [])

  // console.log(videoState)

  return (
    <div className={classes.wrapper}>
      <Player
        playsInline
        src={videoUrl}
        ref={playerRef}
        controls={false}
        poster={posterUrl}
        autoPlay
        aspectRatio={'9:16'}
        height={height}
        fluid={height !== null ? false : true}
      >
        <ReplayControlButton
          type='replay'
          seconds={REPLAY_SECONDS}
          pause={pause}
          playerRef={playerRef}
          hasStarted={hasStarted}
        />
        <ReplayControlButton
          type='forward'
          seconds={REPLAY_SECONDS}
          pause={pause}
          playerRef={playerRef}
          hasStarted={hasStarted}
        />
        <BigPlayButton position='center' />
        <ControlBar>
          <PlayButton
            status={pause ? 'pause' : 'playing'}
            isEnded={isEnded}
            playerRef={playerRef}
          />
          <DefautlPlayButton disabled={true} />
          <VolumeMenuButton vertical={true} order={7} />
        </ControlBar>
      </Player>
    </div>
  )
}

export default Video
