import React, {
  Suspense,
  useCallback,
  useRef,
  useState,
  useContext,
  useEffect
} from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Measure from 'react-measure'
import get from 'lodash/get'
import cx from 'clsx'

import { DropbackTrigger } from 'components/layouts/Dropback'
import { ContentContextProvider } from 'components/layouts/Content/Context'
import { contentScrollService } from 'components/layouts/Content/Service'
import { LayoutContext } from 'contexts/Layout'
import { useRoute } from 'contexts/Route'


import Footer from 'components/layouts/Footer'
import { SnackbarProvider } from 'notistack';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    overflow: 'auto',
    boxSizing: 'border-box',
    transition: 'width 0.25s ease-out',
    '&.extended': {
      width: `calc(100% - ${theme.custom.layouts.menu.narrow.width}px)`
    },
    '&:not(.extended)': {
      width: `calc(100% - ${theme.custom.layouts.menu.extended.width}px)`
    }
  }
}))

const Content = props => {
  const classes = useStyle()
  const { route, routes } = useRoute()
  const contentRef = useRef(null)
  const [footerOffset, setFooterOffset] = useState(null)
  const { layout } = useContext(LayoutContext)


  const makeRoutes = useCallback(() => {
    return routes.map((r, index) => {
      return (
        <Route
          key={r.hasOwnProperty('key') ? r.key : `route_${r.path}_${index}`}
          path={r.path}
          exact={r.exact}
          render={() => (
            <Suspense fallback={<DropbackTrigger />}>
              <r.component route={route} />
            </Suspense>
          )}
        />
      )
    })
  }, [routes, route])

  useEffect(() => {
    contentScrollService.jumpTo()
  }, [route])

  const handleOnScroll = e => {
    const element = e.target
    if (element === contentRef.current) {
      contentScrollService.onScroll(element.scrollLeft, element.scrollTop)
    }
  }

  return (
    <div
      className={cx(classes.root, !layout.isMenuExtended && 'extended')}
      ref={contentRef}
      onScroll={handleOnScroll}
    >
      <SnackbarProvider maxSnack={3}>
        <ContentContextProvider contentRef={contentRef}>

          <div
            style={{
              minHeight: `calc(100% - ${get(footerOffset, 'height', 0)}px)`
            }}
          >
            <Switch>
              {makeRoutes()}
              <Redirect to={{ pathname: routes[0].path }} />
            </Switch>
          </div>
          <Measure
            offset
            onResize={contentRect => {
              setFooterOffset(contentRect.offset)
            }}
          >
            {({ measureRef }) => <Footer ref={measureRef} />}
          </Measure>

        </ContentContextProvider>

      </SnackbarProvider>

    </div>
  )
}

export default Content
