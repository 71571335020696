import React, { useState } from 'react'
import get from 'lodash/get'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import { ArrowUp, ArrowDown } from 'helpers/Icons'
import ClearIcon from '@material-ui/icons/Clear'
import LoadingContainer from 'components/blocks/LoadingContainer'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& > svg': {
      position: 'absolute',
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none'
    }
  },
  input: {
    borderRadius: 4,
    boxSizing: 'border-box',
    textAlign: 'left',
    position: 'relative',
    backgroundColor: theme.custom.colors.white,
    fontSize: theme.typography.fontSize,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    height: '40px',
    border: `solid 1px ${theme.custom.colors.gray3}`,

    '&:hover': {
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.12)',
      border: `solid 1px ${theme.custom.colors.gray3}`
    },
    '&:focus': {
      borderRadius: 4,
      backgroundColor: theme.custom.colors.white
      // border: `solid 1px ${theme.custom.colors.blue1}`
    }
  }
}))(InputBase)

const CustomInputFrameless = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& > svg': {
      position: 'absolute',
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none'
    }
  },
  input: {
    borderRadius: 4,
    boxSizing: 'border-box',
    textAlign: 'left',
    position: 'relative',
    backgroundColor: theme.custom.colors.white,
    fontSize: theme.typography.fontSize,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    height: '40px',
    border: 'solid 1px transparant !important',
    '&:hover': {
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.12)',
      border: 'solid 1px transparant !important'
    },
    '&:focus': {
      borderRadius: 4,
      backgroundColor: theme.custom.colors.white,
      border: 'solid 1px transparant !important'
    }
  }
}))(InputBase)

const CustomMenuItem = withStyles(theme => ({
  root: {
    width: 'calc(100% + 8px) !important',
    fontSize: 14,
    padding: '8px 52px 8px 24px',
    position: 'relative',
    margin: '0 !important',
    '& > span': {
      transition: 'margin 200ms ease-in !important'
    },
    '& > svg': {
      position: 'absolute',
      left: 'calc(100% - 48px)',
      top: '50%',
      transform: 'translateY(-50%) scale(0.8)',
      cursor: 'pointer'
    },
    '&:not(:hover) span': {
      margin: '0 0 0 -8px'
    },
    '&:hover': {
      color: theme.custom.colors.black1,
      background: 'rgba(106, 158, 255, 0.2)',
      '& > span': {
        margin: '0 -8px 0 0'
      },
      '& > svg': {}
    }
  },
  selected: {
    '& > span': {
      margin: '0 -8px 0 0 !important'
    },
    backgroundColor: `${theme.custom.colors.blue1} !important`
  }
}))(MenuItem)

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    '& .MuiInputBase-input': {
      height: ({ height }) => height
    }
  },
  placeHolder: {
    position: 'absolute',
    top: '50%',
    left: '16px',
    transform: 'translateY(-50%)',
    zIndex: 1,
    color: '#9b9b9b',
    fontSize: '14px',
    pointerEvents: 'none'
  }
}))

const useMenuStyles = makeStyles(theme => ({
  list: {
    paddingRight: '0 !important'
  }
}))

export const Dropdown = ({
  value = '',
  options = [],
  setValue,
  hasChanged,
  setHasEdit = null,
  width = 210,
  height = 40,
  placeholder = null,
  frameless = false,
  reverse = false,
  handleDeleteOption = null,
  loading = false,
  maxHeight = 200,
  hideIcon = false,
  disabled = false,
  optionCancelable = true,
  wrapperStyle = {}
}) => {
  const classes = useStyles({ height })
  const menuClasses = useMenuStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <FormControl
      style={{
        ...wrapperStyle,
        width: width
      }}
      className={classes.root}
    >
      {placeholder && !value ? (
        <div className={classes.placeHolder}>{placeholder}</div>
      ) : null}
      <Select
        disabled={disabled}
        value={value === null ? '' : value}
        onChange={e => {
          if (!hasChanged && setHasEdit) {
            setHasEdit(true)
          }
          setValue(e.target.value)
        }}
        input={
          frameless ? (
            <CustomInputFrameless
              style={{
                width: width
              }}
              placeholder='Placeholder'
            />
          ) : (
            <CustomInput
              style={{
                width: width
              }}
              placeholder='Placeholder'
            />
          )
        }
        IconComponent={
          hideIcon
            ? () => <></>
            : !reverse
            ? isOpen
              ? ArrowDown
              : ArrowUp
            : isOpen
            ? ArrowUp
            : ArrowDown
        }
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: reverse
            ? {
                vertical: -4,
                horizontal: 'left'
              }
            : {
                vertical: 44,
                horizontal: 'left'
              },
          transformOrigin: reverse
            ? {
                vertical: 'bottom',
                horizontal: 'left'
              }
            : {
                vertical: 'top',
                horizontal: 'left'
              },
          MenuListProps: {
            component: React.forwardRef((props, ref) => {
              return (
                <div ref={ref} {...props}>
                  <LoadingContainer
                    loading={loading}
                    iconSize='small'
                    style={{ maxHeight: maxHeight }}
                  >
                    {props.children}
                  </LoadingContainer>
                </div>
              )
            })
          },
          PaperProps: {
            style: {
              width: 0,
              maxHeight: maxHeight,
              boxShadow: '0 4px 8px 0 rgba(33, 79, 165, 0.12) !important'
            }
          },
          classes: menuClasses
        }}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        {options.map((item, index) => {
          const notSelectable = item.value === undefined || loading === true
          return (
            <CustomMenuItem
              key={item.label + index}
              value={item.value}
              style={
                notSelectable
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.3
                    }
                  : {}
              }
              component={React.forwardRef((props, ref) => (
                <li
                  ref={ref}
                  {...props}
                  onClick={e => {
                    if (typeof props.onClick === 'function') {
                      props.onClick(e)
                    }
                    if (optionCancelable) {
                      const optionValue = get(props, 'data-value', null)
                      if (optionValue !== null) {
                        if (optionValue === value) {
                          setValue(null)
                        }
                      }
                    }
                  }}
                >
                  {props.children}
                </li>
              ))}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                title={item.label}
              >
                {item.label}
              </span>
              {handleDeleteOption !== null &&
              value !== item.value &&
              !notSelectable ? (
                <ClearIcon
                  onClick={event => {
                    event.stopPropagation()
                    handleDeleteOption(item.value)
                  }}
                />
              ) : null}
            </CustomMenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default Dropdown
