const genderCode = {
  '0': 'Female',
  '1': 'Male',
  '2': 'Unspecified'
}

export const codeToGender = code => {
  let result = ''
  if(!code) return result
  if(!genderCode[code]) return result
  result = genderCode[code]
  return result
}

export default genderCode
