import { createMuiTheme, Theme } from '@material-ui/core/styles'
import {customColors} from './custom'
export const blue = {
  50: '#e6f1ff',
  100: '#c3dcff',
  200: '#9ec6ff',
  300: '#7cb0ff',
  400: '#6a9eff',
  500: '#618dfc',
  600: '#5f7fec',
  700: '#5b6cd7',
  800: '#565ac4',
  900: '#4e3aa1'
}

export const extendTheme = {
  typography: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontFamily: "'sofia-pro', 'Noto Sans TC', 'san-serif'"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: '#fff',
        borderRadius: '4px !important',
        color: '#000 !important',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16) !important'
      },
      arrow: {
        color: '#fff'
      }
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16) !important'
      }
    },
    MuiMenu: {
      list: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiMenuItem: {
      root: {
        height: '40px',
        minHeight: '40px',
        fontSize: '14px',
        padding: '8px 24px 8px 24px',
        transition: 'margin 200ms ease-in !important',
        '&:hover': {
          background: 'rgba(106, 158, 255, 0.2) !important'
        },
        '&:focus': {
          background: 'transparent'
        },
        '& .MuiTouchRipple-root': {
          color: 'white'
        },
        '&$selected': {
          backgroundColor: '#6a9eff !important'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#6a9eff',
      dark: '#225ece',
      light: 'rgba(106, 158, 255, 0.6)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ef3e35',
      dark: '#bf3129',
      light: '#f2645d',
      contrastText: '#fff'
    },
    contrastThreshold: 3
  },
...customColors
}

export type CustomTheme = Theme & typeof extendTheme

const theme = createMuiTheme(extendTheme) as CustomTheme

export default theme
