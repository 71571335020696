import { makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from 'components/App/theme/customThemeConfig'

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '480px',
    maxWidth: '480px',
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    zIndex: theme.custom.zIndexes.joblist
  }
}))

export default useStyles
