import React from 'react'
import OpenAppFrequency from './OpenAppFrequency'
import RecentGoodVideoCount from './RecentGoodVideoCount'
import RecentGoodVideoFrequency from './RecentGoodVideoFrequency'
import FollowerGrowthRate from './FollowerGrowthRate'
import EngagementRateReceive from './EngagementRateReceive'
import EngagementRateGive from './EngagementRateGive'
import ProfileCompletionRate from './ProfileCompletionRate'
import PotentialPayingUser from './PotentialPayingUser'
import DailyActiveViewer from './DailyActiveViewer'
import RetentionRateNewCreator from './RetentionRateNewCreator'
import RetentionRateNewOa from './RetentionRateNewOa'
import RetentionRateNewUser from './RetentionRateNewUser'
import { Dashboard } from 'types/chartEnum'

const { Overview } = Dashboard
const MODAL_TYPES_COMPONENTS = {
  OVERVIEW: {
    SMALL_FILTER: {
      [Overview.RecentOpenAppFrequency]: (
        <OpenAppFrequency type={Overview.RecentOpenAppFrequency} />
      ),
      [Overview.RecentGoodVideoCount]: (
        <RecentGoodVideoCount type={Overview.RecentGoodVideoCount} />
      ),
      [Overview.RecentGoodVideoFrequency]: (
        <RecentGoodVideoFrequency type={Overview.RecentGoodVideoFrequency} />
      ),
      [Overview.FollowerGrowthRate]: (
        <FollowerGrowthRate type={Overview.FollowerGrowthRate} />
      ),
      [Overview.EngagementRateReceive]: (
        <EngagementRateReceive type={Overview.EngagementRateReceive} />
      ),
      [Overview.EngagementRateGive]: (
        <EngagementRateGive type={Overview.EngagementRateGive} />
      ),
      [Overview.ProfileCompletionRate]: (
        <ProfileCompletionRate type={Overview.ProfileCompletionRate} />
      ),
      [Overview.PotentialPayingUser]: (
        <PotentialPayingUser type={Overview.PotentialPayingUser} />
      ),
      [Overview.DailyActiveViewer]: (
        <DailyActiveViewer type={Overview.DailyActiveViewer} />
      ),
      [Overview.RetentionRateNewCreator]: (
        <RetentionRateNewCreator type={Overview.RetentionRateNewCreator} />
      ),
      [Overview.RetentionRateNewOa]: (
        <RetentionRateNewOa type={Overview.RetentionRateNewOa} />
      ),
      [Overview.RetentionRateNewUser]: (
        <RetentionRateNewUser type={Overview.RetentionRateNewUser} />
      )
    }
  }
}

export default MODAL_TYPES_COMPONENTS
