import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react'
import { from, zip, Subscription } from 'rxjs'
import _ from 'lodash'

import useCSVData from './useCSVData'

import { addBatchJobService, BatchJob } from './Service'

import { AuthContext } from 'contexts/Auth'

interface BatchExport {
  batchJobs: BatchJob[];
  cancelBatchJob: Function;
}

export const initialContextValue = {
  batchJobs: [],
  cancelBatchJob: () => {}
}

export const BatchExportContext = createContext<BatchExport>(initialContextValue)
BatchExportContext.displayName = 'BatchExport'

export const BatchExportContextProvider: React.FC = props => {
  const { auth } = useContext(AuthContext)

  const [batchJobs, setBatchJobs] = useState<BatchJob[]>([])
  const {
    makeTableData,
    convertTableDataToCsvData,
    downloadCsvData
  } = useCSVData()
  const addBatchJobServiceRef = useRef<Subscription | null>(null)
  const apiFetcherRef = useRef<Subscription | null>(null)
  const currentBatchJobsRef = useRef<BatchJob[]>([])
  
  const handleCancelBatchJob = useCallback(
    key => {
      setBatchJobs(
        _.remove(batchJobs, function (batchJob) {
          return batchJob.key !== key
        })
      )
    },
    [batchJobs]
  )

  useEffect(() => {
    if (!auth.isLoggedIn) {
      setBatchJobs([])
      if (addBatchJobServiceRef.current) {
        addBatchJobServiceRef.current.unsubscribe()
        addBatchJobServiceRef.current = null
      }
      if (apiFetcherRef.current) {
        apiFetcherRef.current.unsubscribe()
      }
    } else if (addBatchJobServiceRef.current === null) {
      addBatchJobServiceRef.current = addBatchJobService
        .listener()
        .subscribe(event => {
          const action = _.get(event, 'action', null)
          if (action === 'add_batch_job') {
            const newBatchJobs = event.new_batch_jobs
            setBatchJobs(currentBatchJobsRef.current.concat(newBatchJobs))
          }
        })
      apiFetcherRef.current = null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  useEffect(() => {
    return () => {
      if (addBatchJobServiceRef.current) {
        addBatchJobServiceRef.current.unsubscribe()
      }
      if (apiFetcherRef.current) {
        apiFetcherRef.current.unsubscribe()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    currentBatchJobsRef.current = batchJobs

    const makeAPI = (api: Function, options = {}, formatter = (data: any) => data) => {
      return {
        api,
        options,
        formatter
      }
    }

    const fetchingBatchJobs = batchJobs.filter(batchJob => batchJob.isFetching)
    const downloadBatchJobs = batchJobs.filter(
      batchJob => batchJob.result !== null && !batchJob.isDownloaded
    )
    const nextBatchJob = _.get(
      batchJobs.filter(
        batchJob => !batchJob.isFetching && batchJob.result === null
      ),
      '[0]',
      null
    )

    if (fetchingBatchJobs.length < 1 && nextBatchJob !== null) {
      const apis = _.get(nextBatchJob, 'apis', {})
      const keys = Object.keys(apis)
      if (keys.length > 0) {
        const apiObjArr = keys.map(key => {
          const { api, apiOptions, parser } = apis[key]
          return {
            ...makeAPI(api, apiOptions, parser),
            key
          }
        })
        apiFetcherRef.current = zip(
          ...apiObjArr.map(apiObj => {
            const { api, options } = apiObj
            return from(api(options))
          }),
        ).subscribe(response => {
          const results: { [key: string]: any } = {}
          let hasError = false
          try {
            for (let i in response) {
            const index = parseInt(i)
            const { key, formatter } = apiObjArr[index]
            const filterRange = nextBatchJob.filterRange ?? ((data: any) => data)
            const result = formatter(filterRange(response[index]))
            const code = _.get(result, 'code', null)
            const data = _.get(result, 'data', null)
            const totalCount = _.get(result, 'totalCount', 0)
            const totalItems = _.get(result, 'totalItems', 0)
            hasError = !!result.error

            if (code === 'success' && data !== null) {
              results[key] = {
                ...result,
                result: data,
                totalCount,
                totalItems
              }
            }
          }
          } catch (error) {
            console.log('Batch Export Job Error', error)
            hasError = true
          }

          setBatchJobs(
            currentBatchJobsRef.current.reduce<BatchJob[]>((arr, item) => {
              if (item.key === nextBatchJob.key) {
                arr.push({
                  ...item,
                  isFetching: false,
                  result: results,
                  isFailed: hasError
                })
              } else {
                arr.push(item)
              }
              return arr
            }, [])
          )
          apiFetcherRef.current = null
        }, error => {
          // API fetch error
          setBatchJobs(
            currentBatchJobsRef.current.reduce<BatchJob[]>((arr, item) => {
              if (item.key === nextBatchJob.key) {
                arr.push({
                  ...item,
                  isFetching: false,
                  isFailed: true,
                  result: null
                })
              } else {
                arr.push(item)
              }
              return arr
            }, [])
          )
          apiFetcherRef.current = null
        })
      }
      setBatchJobs(
        batchJobs.reduce<BatchJob[]>((arr, item) => {
          if (nextBatchJob.key === item.key) {
            arr.push({
              ...item,
              isFetching: true
            })
          } else {
            arr.push(item)
          }
          return arr
        }, [])
      )
    } else if (downloadBatchJobs.length > 0) {
      for (let batchJob of batchJobs) {
        if (batchJob.result !== null && !batchJob.isDownloaded) {
          ;(async () => {
            const tableData = await makeTableData(
              batchJob,
              batchJob.makeTableDataFunc
            )
            const csvData = await convertTableDataToCsvData(tableData)
            downloadCsvData(batchJob.batchFileName, csvData)
          })()
        }
      }
      setBatchJobs(
        batchJobs.reduce<BatchJob[]>((arr, item) => {
          if (item.result !== null && !item.isDownloaded) {
            arr.push({
              ...item,
              isDownloaded: true
            })
          } else {
            arr.push(item)
          }
          return arr
        }, [])
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchJobs, makeTableData, convertTableDataToCsvData, downloadCsvData])

  return (
    <BatchExportContext.Provider
      value={{ batchJobs, cancelBatchJob: handleCancelBatchJob }}
    >
      {props.children}
    </BatchExportContext.Provider>
  )
}
